import React, { useState } from "react";
import "./storelogin.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URl } from "../Utils/Network";
import { TextField } from "@mui/material";
import Swal from "sweetalert2";
const StoreLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const [error, setError] = useState(null);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    axios
      .post(BASE_URl + "user/login", { email: email, password: password })
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("user", JSON.stringify(res.data));
          navigate("/store");
        }
      })
      .catch((Err) => {
        console.log(Err.response.status);
        if (Err.response.status === 401) {
          Swal.fire({
            title: "Attention",
            text: "Password Not Matched",
            icon: "error",
            confirmButtonText: "Close",
          });
        } else if (Err.response.status === 500) {
          Swal.fire({
            title: "Attention",
            text: "User Not Found",
            icon: "error",
            confirmButtonText: "Close",
          });
        }
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div style={{ padding: 10 }}>
      <div className="Login_header"></div>

      <div className="elements__Container-sc-19iu2ei-0 kdSkXk">
        <div className="Back-sc-1apvhi2-0 fONznE" onClick={handleGoBack} />
        <div className="Box-sc-1xta66d-0 ecwMQF">
          <div className="Title-sc-yvn9m1-0 dlypsM">Log in with your email</div>
        </div>
        <form action="#" noValidate="">
          <div className="Box-sc-1xta66d-0 fxbqmS">
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              // autoComplete="username"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="Box-sc-1xta66d-0 fxbqmS">
            <div className="InputField__PasswordFieldWrapper-sc-1pt26c4-1 cZSGDg">
              <TextField
                variant="outlined"
                required
                fullWidth
                id="password"
                label="Password"
                placeholder="Password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="Box-sc-1xta66d-0 iXuwcw">
            <button
              id="popup_login_btn"
              className="Button-sc-jyepdq-0 drgYrE"
              disabled={email === "" || password === ""}
              onClick={(e) => {
                handleLogin(e);
              }}
            >
              Log in
            </button>
          </div>
          <div className="Box-sc-1xta66d-0 cCdTdR">
            <a
              id="popup_forgot_password_link"
              className="ActionLink-sc-kz11ai-0 jGa-dyU forgot-password-lnk"
              href="/accounts/password/reset/"
            >
              Forgot your password?
            </a>
          </div>
        </form>
        <div className="elements__Footer-sc-9rprne-0 dEDULn">
          By signing up, I agree to the{" "}
          <a
            id="terms-and-conditions-link"
            target="_blank"
            href="/terms"
            className="elements__Link-sc-9rprne-1 fWRPuQ"
          >
            Terms and Conditions
          </a>{" "}
          and{" "}
          <a
            id="privacy-policy-link"
            target="_blank"
            href="/privacy"
            className="elements__Link-sc-9rprne-1 fWRPuQ"
          >
            Privacy Policy
          </a>
        </div>
        {error && <div className="error-message">{error}</div>}
      </div>
    </div>
  );
};

export default StoreLogin;
