import React, { useState, useEffect } from "react";
import "./oder.css";

import { BASE_URl, imgBASE_URl } from "../../../Utils/Network";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const OderPanel = () => {
  const navigate = useNavigate();
  const existingCartItems = JSON.parse(localStorage.getItem("cart")) || [];
  const [subtotal, setSubtotal] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const calculateSubtotal = (cartItems) => {
    return cartItems.reduce(
      (total, cartItem) => total + cartItem?.price || 0,
      0
    );
  };

  const handlediscount = () => {
    if (formData.cash_amount !== "" && formData.discount !== "") {
      const addition =
        parseInt(formData.cash_amount) + parseInt(formData.discount);
      const disc = addition - subtotal;
      console.log(disc);
      console.log(addition);
      return disc;
    }
  };

  useEffect(() => {
    const newSubtotal = calculateSubtotal(existingCartItems);
    setSubtotal(newSubtotal);
  }, [cartItems]);

  console.log(subtotal);

  const [formData, setFormData] = useState({
    first_name: "",
    phone_number: "",
    payment_info: "",
    cash_amount: "",
    discount: "",
  });

  console.log(formData.cash_amount);

  // Function to handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlepurchase = () => {
    let data = {
      products: existingCartItems,
      name: formData.first_name,
      phone_number: formData.phone_number,
      payment_tansId: formData.payment_info,
      cash: formData.cash_amount,
      money_return: formData.cash_amount - subtotal,
      discount: formData.discount,
      totalPrice: subtotal, // Total price of all products
    };
    axios
      .post(BASE_URl + "purchase/purchase-report", data)
      .then((res) => {
        navigate("/store");
        localStorage.removeItem("cart");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div id="checkout_container">
      <div />
      <div>
        <input type="hidden" id="order_id" defaultValue={1} />

        <div className="row">
          <div
            className="col-md-4 checkout_form_column"
            data-bind="with: order "
          >
            <h4 className="page-header ">
              <span data-bind="text:$root.checkout_settings().billing_address_label">
                Deatils
              </span>
            </h4>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label
                    htmlFor="first_name"
                    className="control-label required"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    className="form-control"
                    value={formData.first_name}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label
                    htmlFor="phone_number"
                    className="control-label required"
                  >
                    Phone Number
                  </label>
                  <input
                    type="text"
                    id="phone_number"
                    name="phone_number"
                    className="form-control"
                    value={formData.phone_number}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 checkout_form_column">
            <div data-bind="with: order ">
              <h4 className="page-header ">Select payment method</h4>

              <label id="payment_method_label" />

              <div className="col-md-12">
                <div className="form-group">
                  <label
                    htmlFor="payment_info"
                    className="control-label required"
                  >
                    Bkash/Nagad Number Or TransId
                  </label>
                  <input
                    type="text"
                    id="payment_info"
                    name="payment_info"
                    className="form-control"
                    value={formData.payment_info}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label
                    htmlFor="cash_amount"
                    className="control-label required"
                  >
                    Cash Amount
                  </label>
                  <input
                    type="text"
                    id="cash_amount"
                    name="cash_amount"
                    className="form-control"
                    value={formData.cash_amount}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 checkout_form_column">
            <div data-bind="with: order ">
              <h4 className="page-header ">Apply discount coupon</h4>
              <div className="options_wrapper">
                <div className="input-group">
                  <input
                    id="discount"
                    name="discount"
                    className="form-control"
                    value={formData.discount}
                    onChange={handleInputChange}
                  />
                </div>
                <p> </p>

                <br />
              </div>
              <div>
                <h4 className="page-header ">Your order</h4>
                <div className="options_wrapper">
                  <table className="table cart-table table-striped table-hover">
                    <thead>
                      <tr>
                        <th className="col-md-1" />
                        <th>Product</th>
                        <th>Quantity</th>
                        <th className="text-right">Total Price (BDT )</th>
                      </tr>
                    </thead>
                    <tbody>
                      {existingCartItems?.map((item, index) => {
                        return (
                          <tr>
                            <td className="col-md-1" style={{ width: 50 }}>
                              <img
                                style={{ width: 50, height: 50 }}
                                src={imgBASE_URl + `${item.image[0].filename}`}
                              />
                            </td>
                            <td className="">
                              <span>{item?.name}</span>
                            </td>
                            <td className="text-center">
                              <span data-bind="text: 'x' + quantity">
                                x{item.quantity}
                              </span>
                            </td>
                            <td className="text-right">
                              <span data-bind="text: total_price_display">
                                {item.price}
                              </span>
                            </td>
                          </tr>
                        );
                      })}

                      <tr>
                        <td colSpan={3} className="text-right ">
                          Subtotal Price
                        </td>
                        <td className="text-right">
                          <span data-bind="text: order().checkout_hash.subtotal_price_display">
                            BDT {calculateSubtotal(existingCartItems)}
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={3} className="text-right">
                          Tax
                        </td>
                        <td className="text-right">
                          <span data-bind="text: order().checkout_hash.total_tax_price_display">
                            BDT 0.00
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={3} className="text-right">
                          <span data-bind="text: order().delivery_service_name">
                            Free Shipping
                          </span>
                        </td>
                        <td className="text-right">
                          <span data-bind="text: order().checkout_hash.shipping_price_display">
                            BDT 0.00
                          </span>
                        </td>
                      </tr>

                      <tr className="total-price">
                        <td colSpan={3} className="text-right">
                          <b>Total Price</b>
                        </td>
                        <td className="text-right">
                          <b>
                            <span data-bind="text: order().checkout_hash.total_price_display">
                              BDT {calculateSubtotal(existingCartItems)}
                            </span>
                          </b>
                        </td>
                      </tr>

                      {formData?.discount === "" || undefined || null ? (
                        ""
                      ) : (
                        <tr>
                          <td colSpan={3} className="text-right">
                            Discount
                          </td>
                          <td className="text-right">
                            <span data-bind="text: order().checkout_hash.total_tax_price_display">
                              BDT {formData.discount}
                            </span>
                          </td>
                        </tr>
                      )}

                      {formData?.cash_amount === "" || undefined || null ? (
                        ""
                      ) : (
                        <tr>
                          <td colSpan={3} className="text-right">
                            Money Return
                          </td>
                          {formData.discount !== "" || null ? (
                            <td className="text-right">
                              <span data-bind="text: order().checkout_hash.total_tax_price_display">
                                BDT
                                {handlediscount()}
                              </span>
                            </td>
                          ) : (
                            <td className="text-right">
                              <span data-bind="text: order().checkout_hash.total_tax_price_display">
                                BDT {formData.cash_amount - subtotal}
                              </span>
                            </td>
                          )}
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <button
                onClick={() => {
                  handlepurchase();
                }}
                className="btn btn-primary btn-block order-place-button"
              >
                Place My Order
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OderPanel;
