import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import "./overview.css";
import { IconButton, TextField } from "@mui/material";
import { BASE_URl, imgBASE_URl } from "../../Utils/Network";
import DeleteIcon from "@mui/icons-material/Delete";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import Badge from "@mui/material/Badge";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import axios from "axios";
import CheckOutpage from "./order/checkoutpage";
import Drawer from "@mui/material/Drawer";
const Overview = () => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
    },
  }));

  const [results, setResults] = useState([]);
  const [query, setQuery] = useState("");
  const search = async (query) => {
    if (query.trim() === "") {
      // Don't perform search if query is empty
      setResults(null);
      return;
    }

    try {
      const response = await axios.get(
        BASE_URl + `product/search?pattern=${query}`
      );
      setResults(response.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  useEffect(() => {
    if (query == null || undefined) {
      setResults([]);
    } else {
      search(query);
    }
  }, [query, setResults]);

  const [cart, setCart] = useState([]);

  const handleCart = (newItem) => {
    // Retrieve current cart items from localStorage
    let existingCartItems = JSON.parse(localStorage.getItem("cart")) || [];

    // If existingCartItems is null or undefined, default it to an empty array
    if (!Array.isArray(existingCartItems)) {
      existingCartItems = [];
    }

    // Add the default quantity field to the new item
    const itemWithQuantity = {
      ...newItem,
      quantity: 1, // Set the default quantity to 1 or any other default value
    };

    // Add the new item with quantity to the existing items
    const updatedCart = [...existingCartItems, itemWithQuantity];

    // Store the updated cart back into localStorage
    localStorage.setItem("cart", JSON.stringify(updatedCart));

    // Optionally, update the cart state if needed
    setCart(updatedCart);
  };

  const getCartLength = () => {
    // Retrieve current cart items from localStorage
    const existingCartItems = JSON.parse(localStorage.getItem("cart")) || [];

    // If existingCartItems is null or undefined, default it to an empty array
    if (!Array.isArray(existingCartItems)) {
      existingCartItems = [];
    }

    // Return the length of the cart array
    return existingCartItems.length;
  };
  useEffect(() => {
    getCartLength();
  }, [cart]);

  const [todaysell, setToday] = useState();

  useEffect(() => {
    axios
      .get(BASE_URl + "purchase/today-sales")
      .then((res) => {
        setToday(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Box>
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={3}>
            <Item>
              <Box>
                <strong>Total Sell</strong>
              </Box>
              <Box>
                <strong>BDT {todaysell?.totalTodaySales}</strong>
              </Box>
            </Item>
          </Grid>
          <Grid item xs={3}>
            <Item>
              <Box>
                <strong>Due Order</strong>
              </Box>
              <Box>5</Box>
            </Item>
          </Grid>
          <Grid item xs={3}>
            <Item>
              <Box>Today Delivered </Box>
              <Box>5</Box>
            </Item>
          </Grid>
          <Grid item xs={3}>
            <Item>
              <Box>Today Expences</Box>
              <Box>5</Box>
            </Item>
          </Grid>
        </Grid>
      </Box>

      <TextField
        style={{ width: 800, marginTop: 10 }}
        placeholder="Search Your Product"
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />

      <IconButton
        aria-label="cart"
        style={{ height: 30, width: 30, marginTop: 18 }}
        onClick={toggleDrawer(true)}
      >
        <StyledBadge badgeContent={getCartLength()} color="secondary">
          <ShoppingCartIcon />
        </StyledBadge>
      </IconButton>

      <Drawer open={open} onClose={toggleDrawer(false)}>
        <CheckOutpage setOpen={setOpen} />
      </Drawer>

      {(results && results !== null) || undefined ? (
        <>
          <h2>Search Result</h2>
          <div className="table-wrapper">
            <table className="fl-table">
              <thead>
                <tr>
                  <th>SL No</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Stock</th>

                  <th>Price</th>
                  <th>Add to Cart</th>
                </tr>
              </thead>
              <tbody>
                {results?.map((item, index) => {
                  return (
                    <tr>
                      <td> {index + 1}</td>
                      <td>
                        {" "}
                        <img
                          style={{ height: 40, width: 40 }}
                          src={`${imgBASE_URl + item.image[0]?.filename}`}
                        />
                      </td>
                      <td>{item.name}</td>
                      <td>{item.stock}</td>

                      <td>{item.price}</td>
                      <td>
                        <IconButton
                          onClick={() => {
                            handleCart(item);
                          }}
                        >
                          <AddShoppingCartIcon />
                        </IconButton>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tbody></tbody>
            </table>
          </div>
        </>
      ) : (
        ""
      )}
    </Box>
  );
};

export default Overview;
