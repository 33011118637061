import React, { useEffect, useState } from "react";
import "./checkpage.css";
import { Link } from "react-router-dom";
import { imgBASE_URl } from "../../../Utils/Network";
const CheckOutpage = ({ setOpen, url }) => {
  const handleLinkClick = () => {
    setOpen(false); // Close the drawer
  };

  const [quantity, setQuantity] = useState(1);

  const existingCartItems = JSON.parse(localStorage.getItem("cart")) || [];

  const calculateSubtotal = (cartItems) => {
    return cartItems.reduce(
      (total, cartItem) => total + cartItem?.price * cartItem?.quantity || 0,
      0
    );
  };

  const [newSubtotal, setSubtotal] = useState();
  const [newexistproduct, setExistproduct] = useState();
  const handlecartdata = (indexToRemove) => {
    if (indexToRemove >= 0 && indexToRemove < existingCartItems.length) {
      // Remove the item from the cart array
      existingCartItems.splice(indexToRemove, 1);

      // Update the cart data in localStorage with the modified array
      let tt = localStorage.setItem("cart", JSON.stringify(existingCartItems));

      // Recalculate subtotal
      const newSubtotal = calculateSubtotal(existingCartItems);
      setSubtotal(newSubtotal);
      setExistproduct(tt);
      console.log("Item removed from cart:", existingCartItems);
      console.log("New subtotal:", newSubtotal);

      // Return the new cart list
      return existingCartItems;
    } else {
      console.error("Invalid index to remove");
      return null;
    }
  };

  const [cartUpdated, setCartUpdated] = useState(false);
  const updateCartProduct = (productId, quantity, price, newField) => {
    // Retrieve cart data from localStorage
    let cartData = JSON.parse(localStorage.getItem("cart")) || [];

    // Find the index of the product in the cart array
    const index = cartData.findIndex((product) => product._id === productId);

    // If the product is found in the cart
    if (index !== -1) {
      // Update quantity
      cartData[index].quantity = quantity;

      // Create a new field
      cartData[index].newField = newField;
      cartData[index].price = price * quantity;

      // Save the updated cart data back to localStorage
      localStorage.setItem("cart", JSON.stringify(cartData));
      setCartUpdated(true);
      console.log("Cart updated:", cartData);
    } else {
      console.error("Product not found in cart");
    }
  };

  // Example usage: Remove item at index 2
  useEffect(() => {
    let updatedCartList = "";
    if (updatedCartList !== null) {
      console.log("Updated cart list:", updatedCartList);
    }
  }, [newSubtotal, newexistproduct]);

  const [curl, setcUrl] = useState();
  useEffect(() => {
    if (url != null || undefined) {
      setcUrl("/orders/checkout");
    } else {
      setcUrl("/Store/orders/checkout");
    }
  }, [url]);

  return (
    <div className="sidebar-cart-holder show-sidebar-cart">
      <div
        className="sidebar-cart"
        id="sidebar-cart"
        data-bind="with: $root.cartVM.cart"
      >
        <div className="cart-header" data-bind="click: $root.cartVM.loadCart">
          <div className="count-close vt-middle-align">
            <div className="item-count">shopping cart</div>
            <div
              onClick={() => setOpen(false)}
              className="sidecart-close-button"
              data-bind="click:$root.cartVM.sidecartClose"
            >
              <svg viewBox="0 0 100 100" height={20} width={20}>
                <rect
                  x="19.49"
                  y="46.963"
                  transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 121.571 49.0636)"
                  width="62.267"
                  height="5.495"
                  data-reactid=".16dzx1408qq.3.0.1.3.1.4:$express_2192.3.1.0.0"
                />
                <rect
                  x="18.575"
                  y="47.876"
                  transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 49.062 121.5686)"
                  width="62.268"
                  height="5.495"
                  data-reactid=".16dzx1408qq.3.0.1.3.1.4:$express_2192.3.1.0.1"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="cart-content">
          {existingCartItems === null || undefined ? (
            <div
              className="no-result text-center"
              data-bind="visible: order_items.length == 0"
              style={{ display: "none" }}
            >
              <p>Your shopping cart is empty</p>
              <a className="tbtn btn-theme" href="/">
                return to shop
              </a>
            </div>
          ) : (
            <div
              className="cart-item-holder"
              data-bind="visible: order_items.length > 0"
            >
              {existingCartItems?.map((item, index) => {
                return (
                  <>
                    <div className="cart-item">
                      <div className="photo">
                        {/* <a
                      data-bind="attr:{href:url}"
                      href="/product/uwell-caliburn-x-replament-empty-pods"
                    > */}
                        <img
                          className="img-responsive"
                          data-bind="attr:{src:featured_image_urls !=null ? featured_image_urls.original : $root.cartVM.imagePlaceholder}"
                          style={{ height: 50, width: 100 }}
                          alt="Cart Image"
                          src={imgBASE_URl + `${item.image[0].filename}`}
                        />
                        {/* </a> */}
                      </div>
                      <div className="content">
                        {/* <a
                          data-bind="attr:{href:url},text:name"
                          className="truncate"
                          href="/product/uwell-caliburn-x-replament-empty-pods"
                        > */}
                        {item.name}
                        {/* </a> */}
                        <p data-bind="visivle: variant_options !='',text: variant_options" />
                        <p
                          className="price"
                          data-bind="text: $root.cartVM.currency + unit_price"
                        >
                          BDT {item.price}
                        </p>
                        <div className="action">
                          <div className="quantity">
                            <div
                              className="decrease-button"
                              onClick={() => {
                                updateCartProduct(
                                  item?._id,
                                  item.quantity - 1,
                                  item.price
                                );
                              }}
                            >
                              <svg viewBox="0 0 16 2" height={10} width={10}>
                                <path
                                  d="M1,1 L15,1"
                                  stroke="currentColor"
                                  fill="none"
                                  fillRule="evenodd"
                                  strokeLinecap="square"
                                />
                              </svg>
                            </div>
                            <div
                              className="input-number"
                              data-bind="text: quantity,attr:{id: 'scqnt' + ($index() + 1)}"
                              id="scqnt1"
                            >
                              {item.quantity}
                            </div>
                            <div
                              className="increase-button"
                              onClick={() => {
                                updateCartProduct(
                                  item?._id,
                                  item.quantity + 1,
                                  item.price
                                );
                              }}
                            >
                              <svg viewBox="0 0 16 16" height={10} width={10}>
                                <g
                                  stroke="currentColor"
                                  fill="none"
                                  fillRule="evenodd"
                                  strokeLinecap="square"
                                >
                                  <path d="M8,1 L8,15" />
                                  <path d="M1,8 L15,8" />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="remove"
                        onClick={() => {
                          handlecartdata(index);
                        }}
                      >
                        <div className="icon">
                          <svg viewBox="0 0 100 100" height={16} width={16}>
                            <rect
                              x="19.49"
                              y="46.963"
                              transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 121.571 49.0636)"
                              width="62.267"
                              height="5.495"
                              data-reactid=".16dzx1408qq.3.0.1.3.1.4:$express_2192.3.1.0.0"
                            />
                            <rect
                              x="18.575"
                              y="47.876"
                              transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 49.062 121.5686)"
                              width="62.268"
                              height="5.495"
                              data-reactid=".16dzx1408qq.3.0.1.3.1.4:$express_2192.3.1.0.1"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div
                      className="cart-footer"
                      data-bind="visible: order_items.length > 0"
                    >
                      <div className="subtotal">
                        <div className="text text-uppercase">subtotal</div>
                        <div
                          className="amount"
                          data-bind="text: $root.cartVM.currency +' '+ total_price"
                        >
                          BDT {calculateSubtotal(existingCartItems)}
                        </div>
                      </div>
                      <div className="footer-button">
                        <Link
                          onClick={handleLinkClick}
                          to={curl}
                          className="tbtn btn-solid"
                        >
                          Proceed to Checkout
                        </Link>
                        {/* <a
                       component={Link}
                       to="/about"
                       onClick={() => {
                         setOpen(false);
                       }}
                       className="tbtn btn-solid"
                     >
                       proceed to checkout
                     </a>
                     <a href="/cart" className="tbtn btn-outline">
                       view cart
                     </a> */}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="sidebar-cart-overlay" />
    </div>
  );
};

export default CheckOutpage;
