import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Button, IconButton, TextField } from "@mui/material";
import { BASE_URl, imgBASE_URl } from "../../Utils/Network";
import DeleteIcon from "@mui/icons-material/Delete";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import axios from "axios";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
};

const SellsPanel = () => {
  const today = new Date();
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedIndex, setSelectedIndex] = useState("");

  const [value, setValue] = React.useState(dayjs(new Date()));
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSelectedItem("");
    setSelectedIndex("");
    setOpen(false);
  };
  const [results, setResults] = useState([]);
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  // Format today's date as 'YYYY-MM-DD'
  const formattedToday = today.toISOString().split("T")[0];

  // State variable for the selected date

  // Dynamically generate the label text with today's date
  const label = `Today: ${formattedToday}`;
  useEffect(() => {
    const date = value?.$D;
    const month = value?.$M + 1; // Month (0-11), add 1 to match human-readable month numbers
    const year = value?.$y; // Year
    let data = year + "-" + month + "-" + date;

    axios
      .get(BASE_URl + `purchase/report?${data}`)
      .then((res) => {
        setResults(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [value]);

  const [todaysell, setToday] = useState();

  useEffect(() => {
    axios
      .get(BASE_URl + "purchase/today-sales")
      .then((res) => {
        setToday(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleGeneratePDF = () => {
    // Create a new jsPDF instance
    const pdf = new jsPDF();

    // Get the HTML element containing sales data
    const salesReportElement = document.getElementById("sales-report");

    // Convert HTML element to canvas
    html2canvas(salesReportElement).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Add image (canvas) to PDF
      pdf.addImage(
        imgData,
        "PNG",
        0,
        0,
        pdf.internal.pageSize.getWidth(),

        pdf.internal.pageSize.getHeight()
      );

      // Save PDF
      pdf.save("sales-report.pdf");
    });
  };

  const [uprefund, setUprefund] = useState();

  const handlerefund = (item) => {
    const date = value?.$D;
    const month = value?.$M + 1; // Month (0-11), add 1 to match human-readable month numbers
    const year = value?.$y; // Year
    let fdate = year + "-" + month + "-" + date;
    let data = {
      date: fdate,
      productId: item,
      purchaseId: selectedItem?._id,
    };
    axios
      .post(BASE_URl + "refund/refund", data)
      .then((Res) => {
        setUprefund(Res.data);
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  const handleClick = (item, index) => {
    if (selectedItem === item && selectedIndex === index) {
      setSelectedItem("");
      setSelectedIndex("");
    } else {
      setSelectedItem(item);
      setSelectedIndex(index);
      handleOpen();
    }
  };
  // console.log(selectedItem);
  const [productsid, setProductsid] = useState([]);
  const [productsd, setProductsd] = useState([]);
  useEffect(() => {
    if (selectedItem && selectedIndex !== null) {
      const ids = selectedItem.products.map((product) => product._id);
      setProductsid(ids);
    } else {
      setProductsid([]);
    }
  }, [selectedItem, selectedIndex]);

  const fetchProducts = async (productsid) => {
    console.log("called");
    if (productsid?.length > 0) {
      try {
        const response = await fetch(BASE_URl + "product/getProductsByIds", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(productsid),
        });

        const data = await response.json();
        setProductsd(data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    }
  };

  useEffect(() => {
    if (productsid?.length > 0) {
      fetchProducts(productsid);
    }
  }, [productsid]);

  useEffect(() => {
    if (uprefund && uprefund.products) {
      // If uprefund contains updated purchase data
      fetchProducts(uprefund.products?.products?._id);
    } else if (uprefund?.message === "Purchase deleted successfully") {
      // If the purchase was deleted successfully, reload the page
      window.location.reload();
    }
  }, [uprefund]);

  const [re, setRe] = useState([]);

  useEffect(() => {
    const date = value?.$D;
    const month = value?.$M + 1; // Month (0-11), add 1 to match human-readable month numbers
    const year = value?.$y; // Year
    let fdate = year + "-" + month + "-" + date;

    console.log(fdate);

    axios
      .get(BASE_URl + `refund/refundbydate?${fdate}`)
      .then((res) => {
        if (res.data != null || undefined) {
          setRe(res.data);
        } else {
          setRe([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [value, setRe]);

  const [refundData, setRefunddata] = useState();
  const fetchrefundbyid = async (item) => {
    let productIds = item;
    console.log(productIds);
    if (productIds?.length > 0) {
      try {
        const response = await fetch(BASE_URl + "refund/getrefundbyByIds", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(productIds),
        });

        const data = await response.json();
        setRefunddata(data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    }
  };

  useEffect(() => {
    if (re.length > 0) {
      const ids = re.map((product) => product.purchaseProduct);
      fetchrefundbyid(ids);
      // re.map((item, index) => {
      //   fetchrefundbyid(item.purchaseProduct);
      // });
    } else {
      fetchrefundbyid([]);
    }
  }, [re]);

  const getOnlineOrder = (results) => {
    // Filter the results to get only the orders where the online field is true
    const onlineOrders = results.filter((order) => order?.online === true);
    console.log(onlineOrders);
    return onlineOrders;
  };

  useEffect(() => {
    getOnlineOrder(results);
  }, [results]);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={3}>
            <Item>
              <Box>Total Sell</Box>
              <Box>BDT {todaysell?.totalTodaySales}</Box>
            </Item>
          </Grid>
          <Grid item xs={3}>
            <Item>
              <Box>Due Order</Box>
              <Box>{getOnlineOrder(results).length}</Box>
            </Item>
          </Grid>
          <Grid item xs={3}>
            <Item>
              <Box>Today Delivered </Box>
              <Box>5</Box>
            </Item>
          </Grid>
          <Grid item xs={3}>
            <Item>
              <Box>Today Expences</Box>
              <Box>5</Box>
            </Item>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <button onClick={handleGeneratePDF}>Generate PDF</button>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              label={label}
              value={value}
              onChange={(newValue) => setValue(newValue)}
              inputFormat="yyyy-MM-dd"
            />
          </DemoContainer>
        </LocalizationProvider>
      </Box>

      {(results && results !== null) || undefined ? (
        <div id="sales-report">
          <h2>Sell Report {formattedToday}</h2>
          <div className="table-wrapper">
            <table className="fl-table">
              <thead>
                <tr>
                  <th>SL No</th>
                  <th>Name</th>
                  <th>Price</th>

                  <th>Cash</th>
                  <th>Sell From Online</th>
                  <th>Refund</th>
                </tr>
              </thead>
              <tbody>
                {results?.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td> {index + 1}</td>

                        <td>{item?.name}</td>
                        <td>{item?.totalPrice}</td>

                        <td>{item?.cash}</td>
                        <td>{item?.online === true ? "Online" : ""}</td>
                        <td>
                          <Button
                            onClick={() => {
                              handleClick(item, index);
                            }}
                          >
                            Details
                          </Button>
                        </td>
                      </tr>
                      {/* <tr>
                        {(selectedItem != null && selectedIndex === index) ||
                        undefined ? (
                          <div> {selectedItem.name} </div>
                        ) : (
                          ""
                        )}
                      </tr> */}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        ""
      )}

      <Box>
        {(refundData && refundData !== null) || undefined ? (
          <>
            <h2>Daily Refund</h2>
            <div className="table-wrapper">
              <table className="fl-table">
                <thead>
                  <tr>
                    <th>SL No</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Stock</th>

                    <th>Price</th>
                    <th>Add to Cart</th>
                  </tr>
                </thead>
                <tbody>
                  {refundData?.map((item, index) => {
                    return (
                      <tr>
                        <td> {index + 1}</td>
                        <td>
                          {/* {" "}
                        <img
                          style={{ height: 40, width: 40 }}
                          src={`${imgBASE_URl + item.image[0]?.filename}`}
                        /> */}
                        </td>
                        <td>{item?.name}</td>
                        <td>{item?.stock}</td>

                        <td>{item?.price}</td>
                        <td>
                          {/* <IconButton
                          onClick={() => {
                            handleCart(item);
                          }}
                        >
                          <AddShoppingCartIcon />
                        </IconButton> */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tbody></tbody>
              </table>
            </div>
          </>
        ) : (
          ""
        )}
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Order Details
          </Typography>
          <Box>
            {(productsd && productsd !== null) || undefined ? (
              <div id="sales-report">
                <div className="table-wrapper">
                  <table className="fl-table">
                    <thead>
                      <tr>
                        <th>SL No</th>
                        <th>Name</th>
                        <th>Price</th>

                        <th>Cash</th>
                        <th>Refund</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productsd?.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td> {index + 1}</td>

                              <td>{item?.name}</td>
                              <td>{item?.totalPrice}</td>

                              <td>{item?.cash}</td>
                              <td>
                                <Button
                                  onClick={() => {
                                    handlerefund(item, index);
                                  }}
                                >
                                  Refund
                                </Button>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SellsPanel;
