import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { BASE_URl } from "../../Utils/Network";
import axios from "axios";
const MonthlySellsReport = () => {
  const today = new Date();
  console.log(today);
  const [value, setValue] = React.useState(dayjs(new Date()));

  console.log(value);

  // Format today's date as 'YYYY-MM-DD'
  const formattedToday = today.toISOString().split("T")[0];

  // State variable for the selected date

  // Dynamically generate the label text with today's date
  const label = `Today: ${formattedToday}`;

  const handleGeneratePDF = () => {
    // Create a new jsPDF instance
    const pdf = new jsPDF();

    // Get the HTML element containing sales data
    const salesReportElement = document.getElementById("sales-report");

    // Convert HTML element to canvas
    html2canvas(salesReportElement).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Add image (canvas) to PDF
      pdf.addImage(
        imgData,
        "PNG",
        0,
        0,
        pdf.internal.pageSize.getWidth(),

        pdf.internal.pageSize.getHeight()
      );

      // Save PDF
      pdf.save("sales-report.pdf");
    });
  };
  const [monthly, setMonthly] = useState();

  useEffect(() => {
    const date = "1";
    const month = value?.$M + 1; // Month (0-11), add 1 to match human-readable month numbers
    const year = value?.$y; // Year
    let fdate = month + "/" + year;

    axios
      .get(BASE_URl + "purchase/monthly/" + month + "/" + year)
      .then((res) => {
        setMonthly(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [value]);

  console.log(monthly?.dailySales);

  return (
    <Box>
      <Box>
        <button onClick={handleGeneratePDF}>Generate PDF</button>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              label={'"month" and "year"'}
              views={["month", "year"]}
              value={value}
              onChange={(newValue) => setValue(newValue)}
              inputFormat="yyyy-MM-dd"
            />
          </DemoContainer>
        </LocalizationProvider>
      </Box>

      {(monthly?.dailySales && monthly?.dailySales !== null) || undefined ? (
        <div id="sales-report">
          <h2>Monthly Sells Report</h2>
          <div className="table-wrapper">
            <table className="fl-table">
              <thead>
                <tr>
                  <th>SL No</th>
                  <th>Date</th>
                  <th>Total Sells</th>

                  <th>Total Product Sells</th>
                </tr>
              </thead>
              <tbody>
                {monthly?.dailySales.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td> {index + 1}</td>

                        <td>{item?.date}</td>
                        <td>{item?.dailyTotal}</td>

                        <td>{item?.count}</td>
                      </tr>
                      {/* <tr>
                        {(selectedItem != null && selectedIndex === index) ||
                        undefined ? (
                          <div> {selectedItem.name} </div>
                        ) : (
                          ""
                        )}
                      </tr> */}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        ""
      )}
    </Box>
  );
};

export default MonthlySellsReport;
