import React, { useEffect, useState } from "react";
import "./appbar.css";
import axios from "axios";
import { BASE_URl } from "../../Utils/Network";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import CheckOutpage from "../../Dashboard/Store/order/checkoutpage";
const Navbar = ({ cart }) => {
  const [nav, setnav] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(BASE_URl + "category/data")
      .then((res) => {
        setnav(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const getCartLength = () => {
    // Retrieve current cart items from localStorage
    const existingCartItems = JSON.parse(localStorage.getItem("cart")) || [];

    // If existingCartItems is null or undefined, default it to an empty array
    if (!Array.isArray(existingCartItems)) {
      existingCartItems = [];
    }

    // Return the length of the cart array
    return existingCartItems.length;
  };
  useEffect(() => {
    getCartLength();
  }, [cart]);

  const customerOrderpanelurl = "/orders/checkout";

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <CheckOutpage setOpen={setOpen} url={customerOrderpanelurl} />
    </Box>
  );

  return (
    <div className="header">
      <div className="content-wrapper">
        <div className="mobile-action">
          <div className="sidenav-toggle">
            <span />
            <span />
            <span />
          </div>
        </div>
        <div className="logo">
          <a href="/">
            <img className="img-responsive" src="" alt="Logo" />
          </a>
        </div>
        <div className="menu hidden-sm hidden-xs">
          <ul className="menu-items">
            {nav?.map((item, index) => (
              <li className="has-dropdown" key={index}>
                <a href={`/collection/category/${item.category._id}`}>
                  {item.category.name}
                </a>
                <ul className="dropdown-content child-content">
                  {item?.subcategories?.map((subcategory, subIndex) => (
                    <li key={subIndex} className="has-dropdown">
                      <a
                        href={`/collection/subcategory/${subcategory.subcategory._id}`}
                      >
                        {subcategory.subcategory.name}
                      </a>
                      <ul className="dropdown-content grand-child-content">
                        {subcategory.dropdowns?.map((dropdown, dropIndex) => (
                          <li key={dropIndex} className="has-dropdown">
                            <a href={`/collection/dropdown/${dropdown._id}`}>
                              {dropdown.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>

        {/* <div className="menu hidden-sm hidden-xs">
          <ul className="menu-items">
            <li className="has-dropdown">
              <a href="/collection/Devices">DEVICES</a>
              <ul className="dropdown-content child-content">
                <li>
                  <a href="/collection/Box-Mods">BOX MODS</a>
                </li>
                <li>
                  <a href="/collection/Starter-Kits">STARTER KITS</a>
                </li>
                <li>
                  <a href="/collection/Pod-Systems">POD SYSTEMS</a>
                </li>
              </ul>
            </li>
            <li className="has-dropdown">
              <a href="/collection/Atomizers">ATOMIZERS</a>
              <ul className="dropdown-content child-content">
                <li>
                  <a href="/collection/Rebuildables">REBUILDABLES</a>
                </li>
                <li>
                  <a href="/collection/Sub-Ohm-Tanks">SUB-OHM TANKS</a>
                </li>
              </ul>
            </li>
            <li className="has-dropdown">
              <a href="/collection/E-liquids">E-LIQUIDS</a>
              <ul className="dropdown-content child-content">
                <li>
                  <a href="/collection/Nicsalt">NICSALT</a>
                </li>
                <li className="has-dropdown">
                  <a href="/collection/Free-Base">FREE BASE</a>
                  <ul className="dropdown-content grand-child-content">
                    <li>
                      <a href="/collection/dessert">DESSERT</a>
                    </li>
                    <li>
                      <a href="/collection/tobacco">TOBACCO</a>
                    </li>
                    <li>
                      <a href="/collection/fruity">FRUITY</a>
                    </li>
                    <li>
                      <a href="/collection/iced">ICED</a>
                    </li>
                    <li>
                      <a href="/collection/beverage">BEVERAGE</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="has-dropdown">
              <a href="/collection/Disposables">DISPOSABLES</a>
              <ul className="dropdown-content child-content">
                <li>
                  <a href="/collection/Revivala-d1-disposable-reusable-vape">
                    REVIVALA
                  </a>
                </li>
                <li>
                  <a href="/collection/voltbar-kit">VOLTBAR KIT</a>
                </li>
                <li>
                  <a href="/collection/voltbar-cartridge">VOLTBAR CARTRIDGE</a>
                </li>
                <li>
                  <a href="/collection/Elfbar-disposable-vapes">ELFBAR</a>
                </li>
                <li>
                  <a href="/collection/Krus-disposable-vapes">KRUS</a>
                </li>
              </ul>
            </li>
            <li className="has-dropdown">
              <a href="/collection/Accessories">ACCESSORIES</a>
              <ul className="dropdown-content child-content">
                <li>
                  <a href="/collection/Replacement-Coils-Pod-RBA">
                    REPLACEMENT COILS, POD &amp; RBA
                  </a>
                </li>
                <li>
                  <a href="/collection/Vape-Accessories">VAPE ACCESSORIES</a>
                </li>
                <li>
                  <a href="/collection/Rebuildable-Materials">
                    REBUILDABLE MATERIALS
                  </a>
                </li>
                <li>
                  <a href="/collection/Batteries-Chargers">
                    BATTERIES &amp; CHARGERS
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div> */}

        <div className="header-icon vt-middle-align">
          <div className="search-icon hidden-sm">
            <svg viewBox="0 0 32 32" width={22} height={22}>
              <g id="search">
                <path
                  fill="#fff"
                  d="M29.71,28.29l-6.5-6.5-.07,0a12,12,0,1,0-1.39,1.39s0,.05,0,.07l6.5,6.5a1,1,0,0,0,1.42,0A1,1,0,0,0,29.71,28.29ZM14,24A10,10,0,1,1,24,14,10,10,0,0,1,14,24Z"
                />
              </g>
            </svg>
          </div>
          <div
            className="user-icon hidden-xs"
            onClick={() => {
              navigate("/accounts/registration");
            }}
          >
            <svg fill="#fff" height={22} viewBox="0 0 24 24" width={22}>
              <g id="User">
                <path
                  clipRule="evenodd"
                  d="M0.25 20C0.25 16.2721 3.27208 13.25 7 13.25H17C20.7279 13.25 23.75 16.2721 23.75 20V22C23.75 22.9665 22.9665 23.75 22 23.75H2C1.0335 23.75 0.25 22.9665 0.25 22V20ZM7 14.75C4.10051 14.75 1.75 17.1005 1.75 20V22C1.75 22.1381 1.86193 22.25 2 22.25H22C22.1381 22.25 22.25 22.1381 22.25 22V20C22.25 17.1005 19.8995 14.75 17 14.75H7Z"
                  fill="#fff"
                  fillRule="evenodd"
                  id="Rectangle 39 (Stroke)"
                />
                <path
                  clipRule="evenodd"
                  d="M11.5 1.75C8.87665 1.75 6.75 3.87665 6.75 6.5C6.75 9.12335 8.87665 11.25 11.5 11.25C14.1234 11.25 16.25 9.12335 16.25 6.5C16.25 3.87665 14.1234 1.75 11.5 1.75ZM5.25 6.5C5.25 3.04822 8.04822 0.25 11.5 0.25C14.9518 0.25 17.75 3.04822 17.75 6.5C17.75 9.95178 14.9518 12.75 11.5 12.75C8.04822 12.75 5.25 9.95178 5.25 6.5Z"
                  fill="#fff"
                  fillRule="evenodd"
                  id="Ellipse 21 (Stroke)"
                />
              </g>
            </svg>
          </div>
          <div className="wishlist-icon hidden-xs">
            <a href="/wishlist">
              <svg width="26px" height="26px" viewBox="0 0 32 32">
                <path
                  d="M21.886 5.115c3.521 0 6.376 2.855 6.376 6.376 0 1.809-0.754 3.439-1.964 4.6l-10.297 10.349-10.484-10.536c-1.1-1.146-1.778-2.699-1.778-4.413 0-3.522 2.855-6.376 6.376-6.376 2.652 0 4.925 1.62 5.886 3.924 0.961-2.304 3.234-3.924 5.886-3.924zM21.886 4.049c-2.345 0-4.499 1.089-5.886 2.884-1.386-1.795-3.54-2.884-5.886-2.884-4.104 0-7.442 3.339-7.442 7.442 0 1.928 0.737 3.758 2.075 5.152l11.253 11.309 11.053-11.108c1.46-1.402 2.275-3.308 2.275-5.352 0-4.104-3.339-7.442-7.442-7.442v0z"
                  fill="#fff"
                />
              </svg>
            </a>
          </div>
          <div className="cart-icon" onClick={toggleDrawer(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="feather feather-shopping-bag"
              fill="#fff"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              viewBox="0 0 24 24"
              height="20px"
              width="20px"
            >
              <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z" />
              <line x1={3} x2={21} y1={6} y2={6} />
              <path d="M16 10a4 4 0 0 1-8 0" />
            </svg>
            <span className="count cart-count">{getCartLength()}</span>
          </div>
        </div>
      </div>

      <div>
        <Drawer open={open} anchor={"right"} onClose={toggleDrawer(false)}>
          {DrawerList}
        </Drawer>
      </div>
    </div>
  );
};

export default Navbar;
