import React from "react";

import "./header.css";
const Header = () => {
  return (
    <div className="banner-pinmap-wrapp rows-space-70">
      <div className="banner">
        <div className="item-banner style21">
          <div className="inner">
            <div className="banner-content container">
              <h4 className="herald-subtitle"></h4>
              <h3 className="title">
                Elevate Your
                <br />
                <span>experience with us!</span>
              </h3>

              <a href="#" className="button btn-shop-now">
                Shop now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
