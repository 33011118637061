import React from "react";

import DeatilsPage from "./Detailspage/detailspage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "./HomePage/homepage";

import StoreLogin from "../Dashboard/storelogin";
import StoreHome from "../Dashboard/Store/storehome";
import Register from "../Dashboard/Register/register";
import CheckOutpage from "../Dashboard/Store/order/checkoutpage";
import OrderPanel from "../Dashboard/Store/order/orderpanel";
import CustomerOrderPanel from "../Component/Order/orderpanel";
import CategoryProductlist from "../Component/ProductList/productlist";
import SubcategoryProductlist from "../Component/SubcategoryProductlis/productlist";
import Dropdownlist from "../Component/Dropdownlist/productlist";
const Main = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/detailspage" element={<DeatilsPage />} />

          <Route path="/accounts/login" element={<StoreLogin />} />
          <Route path="/accounts/registration" element={<Register />} />
          <Route path="/details/:id" element={<DeatilsPage />} />
          <Route path="/orders/checkout" element={<CustomerOrderPanel />} />
          <Route
            path="/collection/category/:id"
            element={<CategoryProductlist />}
          />
          <Route
            path="/collection/subcategory/:id"
            element={<SubcategoryProductlist />}
          />
          <Route path="/collection/dropdown/:id" element={<Dropdownlist />} />
          {/* Store Maintain User Login */}
          <Route path="/store" element={<StoreHome />} />
          <Route path="/store/accounts/login" element={<StoreLogin />} />
          <Route path="/store/accounts/registration" element={<Register />} />
          <Route path="/store/orders/checkout" element={<OrderPanel />} />
        </Routes>
      </Router>
    </>
  );
};

export default Main;
