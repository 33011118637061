import React, { useState } from "react";
import {
  Button,
  TextField,
  Container,
  Typography,
  Grid,
  Paper,
  Avatar,
} from "@mui/material";
import "./register.css";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { BASE_URl } from "../../Utils/Network";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const paperStyle = {
  marginTop: "8px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "10px",
};

const avatarStyle = {
  backgroundColor: "#E00000",
};

const formStyle = {
  width: "100%",
  marginTop: "16px",
};

const buttonStyle = {
  marginTop: "16px",
  backgroundColor: "#E00000",
};
const Register = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  const handleRegister = (event) => {
    event.preventDefault();
    if (!username) {
      toast.error("Please enter a username");
      return;
    }

    if (!email) {
      toast.error("Please enter an email address");
      return;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      toast.error("Please enter a valid email address");
      return;
    }

    if (!password) {
      toast.error("Please enter a password");
      return;
    } else if (password.length < 8) {
      toast.error("Password must be at least 8 characters long");
      return;
    }

    axios
      .post(BASE_URl + "user/reg", {
        email: email,
        username: username,
        password: password,
      })
      .then((res) => {
        if (res.status === 201) {
          console.log("res", res.status);
          toast.success("Congratulations, Welcome to Dalazu");
        }
        setTimeout(() => {
          navigate("/Store/accounts/login");
        }, 2000);
      })

      .catch((err) => {
        console.log(err.response.status);
        if (err.response.status === 404) {
          toast.error(
            "User is Already Registered. Please check your credentials."
          );
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      });
  };
  return (
    <div>
      <ToastContainer />
      <div className="Login_header"></div>
      <div className="elements__Popup-sc-uwd9b8-2 yfzmZ">
        <div className="elements__Container-sc-19iu2ei-0 kdSkXk">
          <div className="Back-sc-1apvhi2-0 fONznE" onClick={handleGoBack} />
          <div className="Box-sc-1xta66d-0 ecwMQF">
            <div className="Title-sc-yvn9m1-0 dlypsM">Create an account</div>
          </div>

          <Container component="main" style={{ width: 400 }}>
            <Paper elevation={3} style={paperStyle}>
              <Avatar style={avatarStyle}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign up
              </Typography>
              <form style={formStyle} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="username"
                      label="Username"
                      name="username"
                      // autoComplete="username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={buttonStyle}
                  onClick={(e) => {
                    handleRegister(e);
                  }}
                >
                  Register
                </Button>
              </form>
            </Paper>
          </Container>
          <div className="elements__Footer-sc-9rprne-0 dEDULn">
            By signing up I agree to the{" "}
            <a
              id="terms-and-conditions-link"
              target="_blank"
              href="/terms"
              className="elements__Link-sc-9rprne-1 fWRPuQ"
            >
              Terms and Conditions
            </a>{" "}
            and{" "}
            <a
              id="privacy-policy-link"
              target="_blank"
              href="/privacy"
              className="elements__Link-sc-9rprne-1 fWRPuQ"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
