import React from "react";

import "./stproduct.css";

const StyleProduct = () => {
  return (
    <div className="home-collection-box">
      <div className="content-wrapper">
        <div className="home-collection-box-content">
          <div className="collection-box">
            <a className="glass-effect">
              <img
                className="img-responsive"
                src="https://dtt1c9id3txwq.cloudfront.net/themes/17494/assets/images/Dicodes-Dani-SBS-21700_82320dac291dc9a7f9f31670bc90fd44772331e9.jpg?1691747448"
                alt="Dani SBS 21700"
              />
            </a>
            <div className="text collection1">
              <h2
                className="wow  bounceInLeft text-capitalize animated"
                style={{ visibility: "visible", animationName: "bounceInLeft" }}
              >
                Dani SBS 21700
              </h2>
              <a
                href="/"
                className="wow  bounceInLeft tbtn btn-outline animated"
                data-wow-delay=".5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "bounceInLeft",
                }}
              >
                shop now
              </a>
            </div>
          </div>
          <div className="collection-box">
            <a className="glass-effect">
              <img
                className="img-responsive"
                src="https://dtt1c9id3txwq.cloudfront.net/themes/17494/assets/images/Dicodes-Dani-Mini-v2_56621481a4526a5fafb46678e898b96e0ce12652.jpg?1691747449"
                alt="Danibox Micro"
              />
            </a>
            <div className="text collection2">
              <h2
                className="wow  bounceInRight text-capitalize animated"
                style={{
                  visibility: "visible",
                  animationName: "bounceInRight",
                }}
              >
                Danibox Micro
              </h2>
              <a
                href="/"
                className="wow  bounceInRight tbtn btn-outline animated"
                data-wow-delay="1s"
                style={{
                  visibility: "visible",
                  animationDelay: "1s",
                  animationName: "bounceInRight",
                }}
              >
                shop now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StyleProduct;
