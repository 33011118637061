import React, { useEffect, useState } from "react";
import "./devices.css";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import axios from "axios";
import { BASE_URl, imgBASE_URl } from "../../Utils/Network";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const Saltnic = () => {
  const [device, setDevices] = useState();
  const matches = useMediaQuery("(min-width:600px)");
  useEffect(() => {
    axios
      .get(BASE_URl + "product/products/nicsalt")
      .then((res) => {
        setDevices(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      {matches ? (
        <div
          className="tab-content content-wrapper"
          style={{ padding: 10, animation: "fadeIn 1s ease-in forwards" }}
        >
          <div id="hgc1" className="tab-pane fade in active">
            <div className="products-container">
              <Box sx={{ width: "100%" }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  {device?.map((item, index) => {
                    return (
                      <Grid item xs={2.4}>
                        <Item>
                          <div className="product-box">
                            <div
                              data-bind="attr:{href:url}"
                              className="photo"
                              href="/product/uwell-caliburn-gz2-cyber-pod-system"
                            >
                              {/* ko if: featured_image_urls != null && second_image_urls != null */}
                              <img
                                className="front-img"
                                width={1000}
                                height={1000}
                                data-bind="attr:{src: featured_image_urls.original},alt:name"
                                src={imgBASE_URl + item.image[0].filename}
                              />
                              <img
                                className="back-img"
                                width={1000}
                                height={1000}
                                data-bind="attr:{src: second_image_urls.original},alt:name"
                                src="https://dtt1c9id3txwq.cloudfront.net/assets/images/001/496/552/original/Caliburn-GZ2-%28Cyber-Edition-2%29.jpg?1711373350"
                              />

                              <a
                                className="photo-link"
                                data-bind="attr:{href:url}"
                                href="/product/uwell-caliburn-gz2-cyber-pod-system"
                              />
                              <div className="tag">
                                <span
                                  className="new"
                                  data-bind="visible:is_new"
                                >
                                  new
                                </span>
                                <span
                                  className="sale"
                                  data-bind="visible: on_sale == true && sale_percentage != null,text: '-' + sale_percentage + '%'"
                                  style={{ display: "none" }}
                                >
                                  -null%
                                </span>
                              </div>
                              <div className="icon-group">
                                {/*ko if:$root.isWishlistPage == false  */}
                                <div
                                  className="wishlist"
                                  data-bind="attr:{id:'pid'+ id},click:$root.wishlistVM.addToWishList"
                                  id="pid423937"
                                >
                                  <span className="icon">
                                    <svg viewBox="0 0 512 512">
                                      <g>
                                        <g>
                                          <path
                                            d="M474.644,74.27C449.391,45.616,414.358,29.836,376,29.836c-53.948,0-88.103,32.22-107.255,59.25
                                c-4.969,7.014-9.196,14.047-12.745,20.665c-3.549-6.618-7.775-13.651-12.745-20.665c-19.152-27.03-53.307-59.25-107.255-59.25
                                c-38.358,0-73.391,15.781-98.645,44.435C13.267,101.605,0,138.213,0,177.351c0,42.603,16.633,82.228,52.345,124.7
                                c31.917,37.96,77.834,77.088,131.005,122.397c19.813,16.884,40.302,34.344,62.115,53.429l0.655,0.574
                                c2.828,2.476,6.354,3.713,9.88,3.713s7.052-1.238,9.88-3.713l0.655-0.574c21.813-19.085,42.302-36.544,62.118-53.431
                                c53.168-45.306,99.085-84.434,131.002-122.395C495.367,259.578,512,219.954,512,177.351
                                C512,138.213,498.733,101.605,474.644,74.27z M309.193,401.614c-17.08,14.554-34.658,29.533-53.193,45.646
                                c-18.534-16.111-36.113-31.091-53.196-45.648C98.745,312.939,30,254.358,30,177.351c0-31.83,10.605-61.394,29.862-83.245
                                C79.34,72.007,106.379,59.836,136,59.836c41.129,0,67.716,25.338,82.776,46.594c13.509,19.064,20.558,38.282,22.962,45.659
                                c2.011,6.175,7.768,10.354,14.262,10.354c6.494,0,12.251-4.179,14.262-10.354c2.404-7.377,9.453-26.595,22.962-45.66
                                c15.06-21.255,41.647-46.593,82.776-46.593c29.621,0,56.66,12.171,76.137,34.27C471.395,115.957,482,145.521,482,177.351
                                C482,254.358,413.255,312.939,309.193,401.614z"
                                          />
                                        </g>
                                      </g>
                                    </svg>
                                  </span>
                                </div>
                                {/* /ko */}
                                {/*ko if:$root.isWishlistPage == true  */}
                                {/* /ko */}
                              </div>
                            </div>
                            <div className="action-buttons">
                              <div className="addtocart">
                                <Link to={`/details/${item._id}`}>
                                  <span className="text">View Details</span>
                                  <span className="icon">
                                    <i className="fa fa-long-arrow-right" />
                                  </span>
                                </Link>
                              </div>
                            </div>
                            <div className="content">
                              <div className="name">
                                <a
                                  data-bind="attr:{href:url},text:name"
                                  className="truncate"
                                  href="/product/uwell-caliburn-gz2-cyber-pod-system"
                                >
                                  {item.name}
                                </a>
                              </div>
                              <div className="price">
                                {/* ko if: call_for_price == true */}
                                {/*/ko*/}
                                {/* ko if: call_for_price == false || call_for_price == null */}
                                <s
                                  className="old-price"
                                  data-bind="visible: compare_at_price !=null,text: $root.currency + ' ' + compare_at_price"
                                  style={{ display: "none" }}
                                >
                                  BDT null
                                </s>
                                <span
                                  className="regular-price"
                                  data-bind='text: $root.currency + " " + price'
                                >
                                  BDT {item.price}
                                </span>
                                {/*/ko*/}
                              </div>
                            </div>
                          </div>
                        </Item>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="tab-content content-wrapper"
          style={{ padding: 10, animation: "fadeIn 1s ease-in forwards" }}
        >
          <div id="hgc1" className="tab-pane fade in active">
            <div className="products-container">
              <Box sx={{ width: "100%" }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  {device?.map((item, index) => {
                    return (
                      <Grid item xs={6}>
                        <Item>
                          <div className="product-box">
                            <div
                              data-bind="attr:{href:url}"
                              className="photo"
                              href="/product/uwell-caliburn-gz2-cyber-pod-system"
                            >
                              {/* ko if: featured_image_urls != null && second_image_urls != null */}
                              <img
                                className="front-img"
                                width={1000}
                                height={1000}
                                data-bind="attr:{src: featured_image_urls.original},alt:name"
                                src={imgBASE_URl + item.image[0].filename}
                              />
                              <img
                                className="back-img"
                                width={1000}
                                height={1000}
                                data-bind="attr:{src: second_image_urls.original},alt:name"
                                src="https://dtt1c9id3txwq.cloudfront.net/assets/images/001/496/552/original/Caliburn-GZ2-%28Cyber-Edition-2%29.jpg?1711373350"
                              />

                              <a
                                className="photo-link"
                                data-bind="attr:{href:url}"
                                href="/product/uwell-caliburn-gz2-cyber-pod-system"
                              />
                              <div className="tag">
                                <span
                                  className="new"
                                  data-bind="visible:is_new"
                                >
                                  new
                                </span>
                                <span
                                  className="sale"
                                  data-bind="visible: on_sale == true && sale_percentage != null,text: '-' + sale_percentage + '%'"
                                  style={{ display: "none" }}
                                >
                                  -null%
                                </span>
                              </div>
                              <div className="icon-group">
                                {/*ko if:$root.isWishlistPage == false  */}
                                <div
                                  className="wishlist"
                                  data-bind="attr:{id:'pid'+ id},click:$root.wishlistVM.addToWishList"
                                  id="pid423937"
                                >
                                  <span className="icon">
                                    <svg viewBox="0 0 512 512">
                                      <g>
                                        <g>
                                          <path
                                            d="M474.644,74.27C449.391,45.616,414.358,29.836,376,29.836c-53.948,0-88.103,32.22-107.255,59.25
                                c-4.969,7.014-9.196,14.047-12.745,20.665c-3.549-6.618-7.775-13.651-12.745-20.665c-19.152-27.03-53.307-59.25-107.255-59.25
                                c-38.358,0-73.391,15.781-98.645,44.435C13.267,101.605,0,138.213,0,177.351c0,42.603,16.633,82.228,52.345,124.7
                                c31.917,37.96,77.834,77.088,131.005,122.397c19.813,16.884,40.302,34.344,62.115,53.429l0.655,0.574
                                c2.828,2.476,6.354,3.713,9.88,3.713s7.052-1.238,9.88-3.713l0.655-0.574c21.813-19.085,42.302-36.544,62.118-53.431
                                c53.168-45.306,99.085-84.434,131.002-122.395C495.367,259.578,512,219.954,512,177.351
                                C512,138.213,498.733,101.605,474.644,74.27z M309.193,401.614c-17.08,14.554-34.658,29.533-53.193,45.646
                                c-18.534-16.111-36.113-31.091-53.196-45.648C98.745,312.939,30,254.358,30,177.351c0-31.83,10.605-61.394,29.862-83.245
                                C79.34,72.007,106.379,59.836,136,59.836c41.129,0,67.716,25.338,82.776,46.594c13.509,19.064,20.558,38.282,22.962,45.659
                                c2.011,6.175,7.768,10.354,14.262,10.354c6.494,0,12.251-4.179,14.262-10.354c2.404-7.377,9.453-26.595,22.962-45.66
                                c15.06-21.255,41.647-46.593,82.776-46.593c29.621,0,56.66,12.171,76.137,34.27C471.395,115.957,482,145.521,482,177.351
                                C482,254.358,413.255,312.939,309.193,401.614z"
                                          />
                                        </g>
                                      </g>
                                    </svg>
                                  </span>
                                </div>
                                {/* /ko */}
                                {/*ko if:$root.isWishlistPage == true  */}
                                {/* /ko */}
                              </div>
                            </div>
                            <div className="action-buttons">
                              <div className="addtocart">
                                <Link to={`/details/${item._id}`}>
                                  <span className="text">View Details</span>
                                  <span className="icon">
                                    <i className="fa fa-long-arrow-right" />
                                  </span>
                                </Link>
                              </div>
                            </div>
                            <div className="content">
                              <div className="name">
                                <a
                                  data-bind="attr:{href:url},text:name"
                                  className="truncate"
                                  href="/product/uwell-caliburn-gz2-cyber-pod-system"
                                >
                                  {item.name}
                                </a>
                              </div>
                              <div className="price">
                                {/* ko if: call_for_price == true */}
                                {/*/ko*/}
                                {/* ko if: call_for_price == false || call_for_price == null */}
                                <s
                                  className="old-price"
                                  data-bind="visible: compare_at_price !=null,text: $root.currency + ' ' + compare_at_price"
                                  style={{ display: "none" }}
                                >
                                  BDT null
                                </s>
                                <span
                                  className="regular-price"
                                  data-bind='text: $root.currency + " " + price'
                                >
                                  BDT {item.price}
                                </span>
                                {/*/ko*/}
                              </div>
                            </div>
                          </div>
                        </Item>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Saltnic;
