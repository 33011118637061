import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import "./tabbedproduct.css";
import Devices from "./devices";
import Saltnic from "./saltnic";
import Freebase from "./freebase";
import { Box } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

const TabbedProduct = () => {
  const [page, setPage] = useState(1);

  return (
    <div className="home-group-collection">
      <div className="home-collection-header common-header">
        <h2 className="text text-center text-capitalize">New Arrivals</h2>
      </div>
      <div className="home-group-collection-content">
        <ul className="nav nav-pills">
          <li
            onClick={() => {
              setPage(1);
            }}
          >
            <a href="#hgc1">DEVICES</a>
          </li>
          <li
            onClick={() => {
              setPage(2);
            }}
          >
            <a href="#hgc2">FREE BASE</a>
          </li>
          <li
            onClick={() => {
              setPage(3);
            }}
          >
            <a href="#hgc3">NICSALT</a>
          </li>
        </ul>
      </div>
      <div style={{ animation: "fadeIn 3s ease-in forwards", height: "100vh" }}>
        {page === 1 ? (
          <Box className="">
            <Devices />
          </Box>
        ) : page === 2 ? (
          <Box className="">
            <Freebase />
          </Box>
        ) : page === 3 ? (
          <Box className="">
            <Saltnic />
          </Box>
        ) : null}
      </div>
    </div>
  );
};

export default TabbedProduct;
