import React from "react";

import "./adview2.css";

const Adview2 = () => {
  return (
    <div className="banner-wrapp rows-space-65">
      <div className="container">
        <div className="banner">
          <div className="item-banner style17">
            <div className="inner">
              <div className="banner-content">
                <h3 className="title">Vape New Collection </h3>
                <div className="description">
                  You have no vape &amp; Are you <br />
                  ready to grow? come &amp; shop with us!
                </div>
                <div className="banner-price">
                  Price from:
                  <span className="number-price">BDT 5000</span>
                </div>
                <a href="#" className="button btn-shop-now">
                  Shop now
                </a>
                <a href="#" className="button btn-view-collection">
                  View more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Adview2;
