import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Button, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PhotoCameraBackIcon from "@mui/icons-material/PhotoCameraBack";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { BASE_URl, imgBASE_URl } from "../../Utils/Network";
import Swal from "sweetalert2";
const ProductPanel = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
    color: "#ea2323",
  });
  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    maxHeight: "90vh", // Set max height to 90% of viewport height
    overflowY: "auto", // Enable vertical scrolling if content exceeds the height
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [category, setCategory] = useState();
  const [subcategory, setSubcategory] = useState([]);
  const [dropdwon, setDropdown] = useState([]);
  const [product, setProduct] = useState([]);
  useEffect(() => {
    axios
      .get(BASE_URl + "category")
      .then((res) => {
        setCategory(res.data);
      })
      .catch((Err) => {
        console.log(Err);
      });
  }, []);

  //  cat handle
  const [cat, setcat] = React.useState("");
  const handleCat = (event) => {
    setcat(event.target.value);
  };

  // subcat handle

  useEffect(() => {
    axios
      .get(BASE_URl + "subcategory/getsubbyCat/" + cat)
      .then((res) => {
        setSubcategory(res.data);
      })
      .catch((Err) => {
        console.log(Err);
      });
  }, [cat]);
  const [subcat, setSubcat] = React.useState("");
  const handlesubcat = (event) => {
    setSubcat(event.target.value);
  };
  // child handle
  useEffect(() => {
    axios
      .get(BASE_URl + "dropdown/getdropdwonbysub/" + subcat)
      .then((res) => {
        setDropdown(res.data);
      })
      .catch((Err) => {
        console.log(Err);
      });
  }, [subcat]);
  const [child, setChild] = React.useState("");
  const handlechild = (event) => {
    setChild(event.target.value);
  };

  const [formData, setFormData] = useState({
    name: "",
    stock: "",
    cat: "",
    subcat: "",
    child: "",
    description: "",
    shortDesc: "",
    price: "",
    images: "",
    subcategory: "",
    dropdown: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePost = () => {
    const uploadFormData = new FormData();
    selectedFiles.forEach((file, index) => {
      uploadFormData.append("images", file);
    });

    axios
      .post(BASE_URl + "product/upload", uploadFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((uploadRes) => {
        const postData = {
          name: formData.name,
          stock: formData.stock,
          category: cat,
          description: formData.description,
          shortdesc: formData.shortDesc,
          price: formData.price,

          image: uploadRes.data.uploadedFiles,
        };
        if (subcat) {
          postData.subcategory = subcat;
        }
        if (child) {
          postData.dropdown = child;
        }
        console.log(postData);
        axios
          .post(BASE_URl + "product/create", postData)

          .then((createRes) => {
            if (createRes.status === 201) {
              handleClose();
              Swal.fire({
                title: "Congartulations",
                text: "Your Post Has Been Listed",
                icon: "success",
              });
              // navigate("/");
            } else {
              Swal.fire({
                title: "Sorry",
                text: "Check Your Post Again",
                icon: "Error",
              });
            }
          })
          .catch((createErr) => {
            console.log("Error creating product:", createErr);
          });
      })
      .catch((uploadErr) => {
        console.log("Error uploading image:", uploadErr);
      });
  };

  useEffect(() => {
    axios
      .get(BASE_URl + "product")
      .then((res) => {
        setProduct(res.data);
      })
      .catch((Err) => {
        console.log(Err);
      });
  }, []);

  console.log(product);

  return (
    <Box style={{ height: "100vh" }}>
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={3}>
            <Button variant="contained" onClick={handleOpen}>
              Create Product
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ width: "100%" }} style={{ marginTop: 20 }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={3}>
            <Item>
              <Box>Total Product</Box>
              <Box>{product?.length}</Box>
            </Item>
          </Grid>
          <Grid item xs={3}>
            <Item>
              <Box>Damaged Device</Box>
              <Box>5</Box>
            </Item>
          </Grid>
          <Grid item xs={3}>
            <Item>
              <Box>Damaged Liquid </Box>
              <Box>1</Box>
            </Item>
          </Grid>
          <Grid item xs={3}>
            <Item>
              <Box>Damaged Accessories</Box>
              <Box>5</Box>
            </Item>
          </Grid>
        </Grid>
      </Box>

      <>
        <h2>All Product </h2>
        <div className="table-wrapper">
          <table className="fl-table">
            <thead>
              <tr>
                <th>SL No</th>
                <th>Image</th>
                <th>Name</th>
                <th>Stock</th>
                <th>Entry Date</th>

                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {product?.map((item, index) => {
                return (
                  <tr>
                    <td> 1</td>
                    <td>
                      {" "}
                      <img
                        style={{ height: 40, width: 40 }}
                        src={`${imgBASE_URl + item.image[0]?.filename}`}
                      />
                    </td>
                    <td>{item.name}</td>
                    <td>{item.stock}</td>
                    <td>Content 1</td>

                    <td>{item.price}</td>
                  </tr>
                );
              })}
            </tbody>
            <tbody></tbody>
          </table>
        </div>
      </>
      {/* modal--------------------------------------------------- */}
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Create Product
            </Typography>
            <Box display="flex" style={{ marginTop: 10 }}>
              <Typography
                textAlign="center"
                style={{ marginTop: 10, marginLeft: 0 }}
              >
                Title : *{" "}
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                style={{
                  height: "10px !important",
                  marginLeft: 60,
                  width: 600,
                }}
                name="name"
                value={formData.name}
                onChange={handleChange}
              ></TextField>
            </Box>
            <Box display="flex" style={{ marginTop: 10 }}>
              <Typography
                textAlign="center"
                style={{ marginTop: 10, marginLeft: 0 }}
              >
                Stock : *{" "}
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                style={{
                  height: "10px !important",
                  marginLeft: 50,
                  width: 200,
                }}
                name="stock"
                value={formData.stock}
                onChange={handleChange}
              ></TextField>
              <FormControl style={{ width: 300, marginLeft: 10 }}>
                <InputLabel id="demo-simple-select-label">
                  Select Category
                </InputLabel>
                <Select
                  style={{ height: 40 }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={cat}
                  label="Select Category"
                  onChange={handleCat}
                >
                  {category?.map((item, index) => {
                    return <MenuItem value={item._id}>{item.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>

            <Box style={{ marginTop: 10 }}>
              <FormControl style={{ width: 250, marginLeft: 110 }}>
                <InputLabel id="demo-simple-select-label">
                  Select Subcategory
                </InputLabel>
                <Select
                  style={{ height: 40 }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={subcat}
                  label="Select Subcategory"
                  onChange={handlesubcat}
                >
                  {subcategory?.map((item, index) => {
                    return <MenuItem value={item._id}>{item.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
              <FormControl style={{ width: 250, marginLeft: 102 }}>
                <InputLabel id="demo-simple-select-label">
                  Select DropDown
                </InputLabel>
                <Select
                  style={{ height: 40 }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={child}
                  label="Select DropDown"
                  onChange={handlechild}
                >
                  {dropdwon?.map((item, index) => {
                    return <MenuItem value={item._id}>{item.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box display="flex" style={{ marginTop: 10 }}>
              <Typography
                textAlign="center"
                style={{ marginTop: 10, marginLeft: 0 }}
              >
                Description : *{" "}
              </Typography>
              <TextField
                multiline
                rows={10}
                style={{ height: 200, width: 500, marginLeft: 10 }}
                name="description"
                value={formData.description}
                onChange={handleChange}
                //   value={formData.description}
                // onChange={(e) =>
                //   handleInputChange("description", e.target.value)
                // }
                //   error={errors.description}
                //   helperText={
                //     errors.description ? "Description is required." : ""
                //   }
              />
            </Box>

            <Box display="flex" style={{ marginTop: 100 }}>
              <Typography
                textAlign="center"
                style={{ marginTop: 10, marginLeft: 0 }}
              >
                Short Desc :
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                style={{
                  height: "10px !important",
                  marginLeft: 20,
                  width: 600,
                }}
                name="shortDesc"
                value={formData.shortDesc}
                onChange={handleChange}
              ></TextField>
            </Box>

            <Box display="flex" style={{ marginTop: 10 }}>
              <Typography
                textAlign="center"
                style={{ marginTop: 10, marginLeft: 0 }}
              >
                Price :
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                style={{
                  height: "10px !important",
                  marginLeft: 63,
                  width: 600,
                }}
                name="price"
                value={formData.price}
                onChange={handleChange}
              ></TextField>
            </Box>

            <Box
              className="img-button"
              display="flex"
              justifyContent="center"
              style={{ marginTop: 10 }}
            >
              <Button
                className="img"
                style={{
                  color: "#ea2323",
                  borderColor: "#ea2323",
                  marginBottom: 10,
                }}
                variant="outlined"
                component="label"
                role={undefined}
                tabIndex={-1}
                startIcon={<PhotoCameraBackIcon />}
                type="file"
                multiple
                name="images"
                onChange={handleFileChange}
              >
                Upload Image
                <VisuallyHiddenInput type="file" />
              </Button>
            </Box>

            <Button
              onClick={() => {
                handlePost();
              }}
            >
              {" "}
              Submit
            </Button>
          </Box>
        </Modal>
      </div>
    </Box>
  );
};

export default ProductPanel;
