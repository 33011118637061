import React from "react";
import "../LoginConfirm/loginConf.css";
import { useNavigate } from "react-router-dom";
const LogiCong = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    // Use the navigate function to navigate to the login page
    navigate("/Store/accounts/login");
  };

  const handleRegister = () => {
    // Use the navigate function to navigate to the login page
    navigate("/Store/accounts/registration");
  };
  return (
    <div style={{ padding: 20 }}>
      {" "}
      <div className="Login_header"></div>
      <div className="elements__Popup-sc-uwd9b8-2 yfzmZ">
        <div className="elements__Container-sc-19iu2ei-0 kdSkXk">
          <div className="elements__ContextWrapper-sc-18dn2ug-0 jErhhK">
            {/* <div
              className="DefaultContext__Wrapper-sc-962s-0 dXZCdF"
              style={{}}
            > */}
            <div className="DefaultContext__Previous-sc-962s-4 kzJYXn" />
            <div className="DefaultContext__IntentWrapper-sc-962s-1 gbtMUC">
              <div
                direction={-1}
                className="DefaultContext__AnimationWrapper-sc-962s-2 fNUFrk"
              >
                <div className="DefaultContext__ContextWrapper-sc-962s-3 hHvRgl">
                  <img
                    src="https://static.dubizzle.com/static_assets/favoritead.svg"
                    alt="Favorite ad"
                    width={124}
                    height={139}
                  />
                  <div className="ContextMessage__Message-sc-pmwvew-0 fOIbpR">
                    Log in to favorite an ad
                  </div>
                </div>
                <div className="DefaultContext__ContextWrapper-sc-962s-3 hHvRgl">
                  <img
                    src="https://static.dubizzle.com/static_assets/paa.svg"
                    alt="Place an ad"
                    width={124}
                    height={143}
                  />
                  <div className="ContextMessage__Message-sc-pmwvew-0 fOIbpR">
                    Log in to post an ad
                  </div>
                </div>
                <div className="DefaultContext__ContextWrapper-sc-962s-3 hHvRgl">
                  <img
                    src="https://static.dubizzle.com/static_assets/call.svg"
                    alt="Call"
                    width={124}
                    height={139}
                  />
                  <div className="ContextMessage__Message-sc-pmwvew-0 fOIbpR">
                    Log in to call the seller
                  </div>
                </div>
              </div>
            </div>
            <div className="DefaultContext__Previous-sc-962s-4 DefaultContext__Next-sc-962s-5 kzJYXn huAYae" />
            {/* </div> */}
          </div>

          <button
            id="popup_login_link"
            className="Button-sc-jyepdq-0 elements__LoginButton-sc-18dn2ug-1 elements__EmailLoginButton-sc-18dn2ug-5 jkSutz hKfCCX drSYJT"
            onClick={() => {
              handleLoginClick();
            }}
          >
            Continue with Email & Password
          </button>
          <a
            id="popup_create_account_link"
            className="ActionLink-sc-kz11ai-0 jGa-dyU"
            onClick={() => {
              handleRegister();
            }}
          >
            Don’t have an account? Create one
          </a>
          <div className="elements__Footer-sc-9rprne-0 dEDULn">
            By signing up I agree to the{" "}
            <a
              id="terms-and-conditions-link"
              target="_blank"
              href="/terms"
              className="elements__Link-sc-9rprne-1 fWRPuQ"
            >
              Terms and Conditions
            </a>{" "}
            and{" "}
            <a
              id="privacy-policy-link"
              target="_blank"
              href="/privacy"
              className="elements__Link-sc-9rprne-1 fWRPuQ"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogiCong;
