import React, { useEffect, useState } from "react";

import "./productlist.css";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URl, imgBASE_URl } from "../../Utils/Network";
const Productlist = () => {
  const id = useParams();
  console.log(id);
  const [results, setResults] = useState([]);
  const [cat, setCat] = useState([]);
  useEffect(() => {
    axios
      .get(BASE_URl + `category/${id.id}`)
      .then((res) => {
        setCat(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(BASE_URl + `product/cat/${id.id}`)
      .then((res) => {
        setResults(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log(cat);
  return (
    <div className="collection" id="collection-content">
      <div id="quickview_content">
        <div
          id="quickviewModal"
          className="modal fade"
          role="dialog"
          data-bind="with: $root.quickView"
        />
      </div>
      <div
        className="content-wrapper page-loader"
        data-bind="visible: $root.filterVM.loading() == true"
        style={{ display: "none" }}
      >
        <div className="placeholder" style={{ height: 30 }} />
        <div className="row">
          <div className="col-lg-3 col-md-4">
            <div className="placeholder" style={{ height: 300 }} />
            <div className="placeholder" style={{ height: 200 }} />
            <div className="placeholder" style={{ height: 200 }} />
          </div>
          <div className="col-lg-9 col-md-8">
            <div className="placeholder" style={{ height: 200 }} />
            <div className="placeholder" style={{ height: 50 }} />
            <div className="placeholder" style={{ height: 50 }} />
            <div className="row">
              <div className="col-md-3">
                <div className="placeholder" style={{ height: 150 }} />
              </div>
              <div className="col-md-3">
                <div className="placeholder" style={{ height: 150 }} />
              </div>
              <div className="col-md-3">
                <div className="placeholder" style={{ height: 150 }} />
              </div>
              <div className="col-md-3">
                <div className="placeholder" style={{ height: 150 }} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="placeholder" style={{ height: 150 }} />
              </div>
              <div className="col-md-3">
                <div className="placeholder" style={{ height: 150 }} />
              </div>
              <div className="col-md-3">
                <div className="placeholder" style={{ height: 150 }} />
              </div>
              <div className="col-md-3">
                <div className="placeholder" style={{ height: 150 }} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="placeholder" style={{ height: 150 }} />
              </div>
              <div className="col-md-3">
                <div className="placeholder" style={{ height: 150 }} />
              </div>
              <div className="col-md-3">
                <div className="placeholder" style={{ height: 150 }} />
              </div>
              <div className="col-md-3">
                <div className="placeholder" style={{ height: 150 }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="collection-banner"
        data-bind="visible: $root.filterVM.loading() == false"
        style={{}}
      >
        <div className="banner-text">
          <div
            className="colleection-name"
            data-bind="text: $root.collection_name"
          >
            {cat?.name}
          </div>
          <div className="breadcurmb">
            <a href="/" className="parent">
              Home
            </a>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                width={16}
                height={16}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </span>
            <a href="/collection/Devices" className="child">
              DEVICES
            </a>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                width={16}
                height={16}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </span>
            <a href="/collection/Box-Mods" className="child">
              {cat?.name}
            </a>
          </div>
        </div>
      </div>
      <div
        className="content-wrapper"
        data-bind="visible: $root.filterVM.loading() == false"
        style={{}}
      >
        <div className="collection-content">
          <div className="collection-filter"></div>
          <div className="collection-info">
            <div className="sort-mFilter">
              <div
                className="mFilter"
                data-bind="visible:$root.filterVM.filterOptions().length > 0,click: $root.openFilter"
                style={{ display: "none" }}
              >
                <div className="mFilter-open">
                  <svg viewBox="0 0 32 32" width="20px" height="20px">
                    <path d="M12,30.41v-15L1.89,3H30.11L20,15.36v5.81a3,3,0,0,1-.88,2.12ZM6.11,5,14,14.64v11l3.71-3.71a1.05,1.05,0,0,0,.29-.71V14.64L25.89,5Z" />
                  </svg>
                </div>
                <div className="mFilter-label">Filter</div>
              </div>
              <div className="sort">
                <div className="sort-label">
                  <div
                    className="text bold text-uppercase hidden-xs"
                    style={{ marginBottom: 5 }}
                  >
                    sort by:
                  </div>
                  <select
                    className="form-control"
                    data-bind="options:$root.filterVM.sortingOptions,optionsText:'text',optionsValue:'value', value:$root.filterVM.sortBy"
                  >
                    <option value="created_at DESC">Default</option>
                    <option value="products.price ASC">
                      Price low to high
                    </option>
                    <option value="products.price DESC">
                      Price high to low
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div className="collection-products">
              {results &&
                results?.map((item, index) => {
                  console.log(item);
                  return (
                    <div className="product-box">
                      <div data-bind="attr:{href:url}" className="photo">
                        <img
                          className="front-img"
                          width={1000}
                          height={1000}
                          data-bind="attr:{src: featured_image_urls.original},alt:name"
                          src={imgBASE_URl + item?.image[0]?.filename}
                        />

                        <a
                          className="photo-link"
                          data-bind="attr:{href:url}"
                          href="/product/voopoo-drag-m100s-box-mod"
                        />
                        <div className="tag">
                          <span className="new" data-bind="visible:is_new">
                            new
                          </span>
                          <span
                            className="sale"
                            data-bind="visible: on_sale == true && sale_percentage != null,text: '-' + sale_percentage + '%'"
                            style={{ display: "none" }}
                          >
                            -null%
                          </span>
                        </div>
                        <div className="icon-group">
                          {/*ko if:$root.isWishlistPage == false  */}
                          <div
                            className="wishlist"
                            data-bind="attr:{id:'pid'+ id},click:$root.wishlistVM.addToWishList"
                            id="pid413913"
                          >
                            <span className="icon">
                              <svg viewBox="0 0 512 512">
                                <g>
                                  <g>
                                    <path
                                      d="M474.644,74.27C449.391,45.616,414.358,29.836,376,29.836c-53.948,0-88.103,32.22-107.255,59.25
                                c-4.969,7.014-9.196,14.047-12.745,20.665c-3.549-6.618-7.775-13.651-12.745-20.665c-19.152-27.03-53.307-59.25-107.255-59.25
                                c-38.358,0-73.391,15.781-98.645,44.435C13.267,101.605,0,138.213,0,177.351c0,42.603,16.633,82.228,52.345,124.7
                                c31.917,37.96,77.834,77.088,131.005,122.397c19.813,16.884,40.302,34.344,62.115,53.429l0.655,0.574
                                c2.828,2.476,6.354,3.713,9.88,3.713s7.052-1.238,9.88-3.713l0.655-0.574c21.813-19.085,42.302-36.544,62.118-53.431
                                c53.168-45.306,99.085-84.434,131.002-122.395C495.367,259.578,512,219.954,512,177.351
                                C512,138.213,498.733,101.605,474.644,74.27z M309.193,401.614c-17.08,14.554-34.658,29.533-53.193,45.646
                                c-18.534-16.111-36.113-31.091-53.196-45.648C98.745,312.939,30,254.358,30,177.351c0-31.83,10.605-61.394,29.862-83.245
                                C79.34,72.007,106.379,59.836,136,59.836c41.129,0,67.716,25.338,82.776,46.594c13.509,19.064,20.558,38.282,22.962,45.659
                                c2.011,6.175,7.768,10.354,14.262,10.354c6.494,0,12.251-4.179,14.262-10.354c2.404-7.377,9.453-26.595,22.962-45.66
                                c15.06-21.255,41.647-46.593,82.776-46.593c29.621,0,56.66,12.171,76.137,34.27C471.395,115.957,482,145.521,482,177.351
                                C482,254.358,413.255,312.939,309.193,401.614z"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </span>
                          </div>
                          {/* /ko */}
                          {/*ko if:$root.isWishlistPage == true  */}
                          {/* /ko */}
                        </div>
                      </div>
                      <div className="action-buttons">
                        <div className="addtocart">
                          <Link to={`/details/${item._id}`}>
                            <span className="text">View Details</span>
                            <span className="icon">
                              <i className="fa fa-long-arrow-right" />
                            </span>
                          </Link>
                        </div>
                      </div>
                      <div className="content">
                        <div className="name">
                          <a
                            data-bind="attr:{href:url},text:name"
                            className="truncate"
                            href="/product/voopoo-drag-m100s-box-mod"
                          >
                            VOOPOO DRAG M100S BOX MOD
                          </a>
                        </div>
                        <div className="price">
                          {/* ko if: call_for_price == true */}
                          {/*/ko*/}
                          {/* ko if: call_for_price == false || call_for_price == null */}
                          <s
                            className="old-price"
                            data-bind="visible: compare_at_price !=null,text: $root.currency + ' ' + compare_at_price"
                            style={{ display: "none" }}
                          >
                            BDT null
                          </s>
                          <span
                            className="regular-price"
                            data-bind='text: $root.currency + " " + price'
                          >
                            BDT 5,000.00
                          </span>
                          {/*/ko*/}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          {/*Start Pagination*/}
          <div className="page-link">
            {/* ko with:filterVM.pager()*/}
            <div className="clearfix" />
            <div
              className="product-pagination"
              data-bind="visible: hasPage"
              style={{ display: "none" }}
            >
              <ul className="pagination">
                <li
                  data-bind="css:{disabled: !PreviousPageActive()}"
                  className="disabled"
                >
                  <a data-bind="click: GoToPrevious">
                    <svg height={8} width={7} viewBox="0 0 490 490">
                      <g>
                        <path
                          style={{ fill: "#009688" }}
                          d="M47.087,245.454L274.223,18.339c4.237-4.093,4.355-10.845,0.262-15.083   c-4.093-4.237-10.845-4.355-15.083-0.262c-0.089,0.086-0.176,0.173-0.262,0.262L24.474,237.923c-4.164,4.165-4.164,10.917,0,15.083   L259.14,487.672c4.237,4.093,10.99,3.975,15.083-0.262c3.993-4.134,3.993-10.687,0-14.821L47.087,245.454z"
                        />
                        <path
                          style={{ fill: "#009688" }}
                          d="M466.223,3.235c-4.165-4.164-10.917-4.164-15.083,0L216.474,237.902   c-4.164,4.165-4.164,10.917,0,15.083L451.14,487.651c4.237,4.093,10.99,3.976,15.083-0.261c3.993-4.134,3.993-10.688,0-14.821   L239.087,245.454L466.223,18.339c4.171-4.16,4.179-10.914,0.019-15.085C466.236,3.248,466.229,3.241,466.223,3.235z"
                        />
                      </g>
                      <path d="M266.671,490.787c-2.831,0.005-5.548-1.115-7.552-3.115L24.452,253.006c-4.164-4.165-4.164-10.917,0-15.083L259.119,3.256  c4.093-4.237,10.845-4.355,15.083-0.262c4.237,4.093,4.355,10.845,0.262,15.083c-0.086,0.089-0.173,0.176-0.262,0.262  L47.087,245.454l227.136,227.115c4.171,4.16,4.179,10.914,0.019,15.085C272.236,489.664,269.511,490.792,266.671,490.787z" />
                      <path d="M458.671,490.787c-2.831,0.005-5.548-1.115-7.552-3.115L216.452,253.006c-4.164-4.165-4.164-10.917,0-15.083L451.119,3.256  c4.093-4.237,10.845-4.354,15.083-0.262c4.237,4.093,4.354,10.845,0.262,15.083c-0.086,0.089-0.173,0.176-0.262,0.262  L239.087,245.454l227.136,227.115c4.171,4.16,4.179,10.914,0.019,15.085C464.236,489.664,461.511,490.792,458.671,490.787z" />
                    </svg>
                    <span className="prev">&nbsp;Previous</span>
                  </a>
                </li>
                {/* ko foreach: GetPages() */}
                <li
                  data-bind="css:{active: $parent.CurrentPage() === $data}"
                  className="active"
                >
                  <a data-bind="click: $parent.GoToPage, text: $data">1</a>
                </li>
                {/* /ko */}
                <li
                  data-bind="css:{disabled: !NextPageActive()}"
                  className="disabled"
                >
                  <a data-bind="click: GoToNext">
                    <span className="next">Next&nbsp;</span>
                    <svg height={8} width={7} viewBox="0 0 490 490">
                      <g>
                        <path
                          style={{ fill: "#009688" }}
                          d="M231.696,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82   l227.115,227.136L216.592,472.461c-4.237,4.093-4.355,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262   c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L231.696,3.128z"
                        />
                        <path
                          style={{ fill: "#009688" }}
                          d="M274.363,237.795L39.696,3.128c-4.237-4.093-10.99-3.975-15.083,0.262   c-3.992,4.134-3.992,10.687,0,14.821l227.115,227.136L24.592,472.461c-4.237,4.093-4.354,10.845-0.262,15.083   c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667   C278.511,248.718,278.521,241.966,274.363,237.795z"
                        />
                      </g>
                      <path d="M224.144,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115  L216.592,18.232c-4.093-4.237-3.975-10.99,0.262-15.083c4.134-3.992,10.687-3.992,14.82,0l234.667,234.667  c4.164,4.165,4.164,10.917,0,15.083L231.675,487.565C229.676,489.56,226.968,490.68,224.144,490.68z" />
                      <path d="M32.144,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115  L24.592,18.232c-4.171-4.171-4.171-10.933,0-15.104s10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083  L39.696,487.544C37.695,489.551,34.978,490.68,32.144,490.68z" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
            <div className="clearfix" />
            {/* /ko */}
          </div>
          {/*End Pagination*/}
        </div>
      </div>
    </div>
  );
};

export default Productlist;
