import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    // <div className="footer">
    //   <div className="footer-content">
    //     <div className="content-wrapper">
    //       <div className="desktop-footer row hidden-xs">
    //         <div className="footer-box col-lg-4 col-md-8 col-sm-6">
    //           <h4 className="footer-header">contact us</h4>
    //           <div className="outlets">
    //             <div className="icon">
    //               <svg
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 fill="none"
    //                 viewBox="0 0 24 24"
    //                 strokeWidth="1.5"
    //                 stroke="currentColor"
    //                 width={22}
    //                 height={22}
    //               >
    //                 <path
    //                   strokeLinecap="round"
    //                   strokeLinejoin="round"
    //                   d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
    //                 />
    //                 <path
    //                   strokeLinecap="round"
    //                   strokeLinejoin="round"
    //                   d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
    //                 />
    //               </svg>
    //             </div>
    //             <div className="details">
    //               <div className="outlet-name">GULSHAN BRANCH:</div>
    //               <div className="outlet-address">
    //                 House-30, Road-7, Block-C, Niketon, Dhaka
    //               </div>
    //             </div>
    //           </div>
    //           <div className="outlets">
    //             <div className="icon">
    //               <svg
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 fill="none"
    //                 viewBox="0 0 24 24"
    //                 strokeWidth="1.5"
    //                 stroke="currentColor"
    //                 width={22}
    //                 height={22}
    //               >
    //                 <path
    //                   strokeLinecap="round"
    //                   strokeLinejoin="round"
    //                   d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
    //                 />
    //                 <path
    //                   strokeLinecap="round"
    //                   strokeLinejoin="round"
    //                   d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
    //                 />
    //               </svg>
    //             </div>
    //             <div className="details">
    //               <div className="outlet-name">DHANMONDI BRANCH:</div>
    //               <div className="outlet-address">
    //                 Shop No. 247-258 Shimanto Square (Rifles), Road 2,
    //                 Dhanmondi, Dhaka
    //               </div>
    //             </div>
    //           </div>
    //           <div className="phone">
    //             <div className="title">phone</div>
    //             <div className="text">01788888947, 01788888948</div>
    //           </div>
    //           <div className="email">
    //             <div className="title">email</div>
    //             <div className="text">inquiry@thevapecafe.com.bd</div>
    //           </div>
    //         </div>
    //         <div className="footer-box col-lg-2 col-md-4 col-sm-6">
    //           <h4 className="footer-header">quick link</h4>
    //           <ul className="footer-link">
    //             <li>
    //               <a href="/pages/Terms-and-conditions" target="_blank">
    //                 Terms &amp; Conditions
    //               </a>
    //             </li>
    //             <li>
    //               <a href="/pages/shipping-returns-privacy" target="_blank">
    //                 Shipping, Returns &amp; Privacy
    //               </a>
    //             </li>
    //             <li>
    //               <a href="/pages/Warranty-Information" target="_blank">
    //                 Warranty Information
    //               </a>
    //             </li>
    //             <li>
    //               <a href="/store/track_orders/new" target="_blank">
    //                 Order tracking
    //               </a>
    //             </li>
    //             <li>
    //               <a href="/pages/online-emi-policy" target="_blank">
    //                 Online EMI Policy
    //               </a>
    //             </li>
    //             <li>
    //               <a href="/pages/About-Us" target="_blank">
    //                 About Us
    //               </a>
    //             </li>
    //           </ul>
    //         </div>
    //         <div className="clearfix hidden-lg" />
    //         <div className="footer-box col-lg-3 col-md-6 col-sm-6">
    //           <h4 className="footer-header">facebook store</h4>
    //           <div
    //             className="fb-page fb_iframe_widget"
    //             data-href="https://www.facebook.com/thevapecafebd"
    //             data-small-header="false"
    //             data-adapt-container-width="true"
    //             data-hide-cover="false"
    //             data-show-facepile="true"
    //             data-show-posts="false"
    //             fb-xfbml-state="rendered"
    //             fb-iframe-plugin-query="adapt_container_width=true&app_id=&container_width=251&hide_cover=false&href=https%3A%2F%2Fwww.facebook.com%2Fthevapecafebd&locale=en_US&sdk=joey&show_facepile=true&show_posts=false&small_header=false"
    //           >
    //             <span
    //               style={{ verticalAlign: "bottom", width: 251, height: 130 }}
    //             >
    //               <iframe
    //                 name="f69353274036cacfb"
    //                 width="1000px"
    //                 height="1000px"
    //                 data-testid="fb:page Facebook Social Plugin"
    //                 title="fb:page Facebook Social Plugin"
    //                 frameBorder={0}
    //                 allowTransparency="true"
    //                 allowFullScreen="true"
    //                 scrolling="no"
    //                 allow="encrypted-media"
    //                 src="https://www.facebook.com/v17.0/plugins/page.php?adapt_container_width=true&app_id=&channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Dfa154d486c5dc0af4%26domain%3Dwww.thevapecafe.com.bd%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fwww.thevapecafe.com.bd%252Ffc08530f2da9d94d5%26relation%3Dparent.parent&container_width=251&hide_cover=false&href=https%3A%2F%2Fwww.facebook.com%2Fthevapecafebd&locale=en_US&sdk=joey&show_facepile=true&show_posts=false&small_header=false"
    //                 style={{
    //                   border: "none",
    //                   visibility: "visible",
    //                   width: 251,
    //                   height: 130,
    //                 }}
    //                 className=""
    //               />
    //             </span>
    //           </div>
    //         </div>
    //         <div className="footer-box col-lg-3 col-md-6 col-sm-6">
    //           <h4 className="footer-header">newsletter</h4>
    //           <div className="newsletter">
    //             <h5>Sign up for the latest offers and promotions</h5>
    //             <div className="input-group">
    //               <input
    //                 type="email"
    //                 className="form-control user_email"
    //                 placeholder="Please Enter an email"
    //               />
    //               <div className="input-group-btn">
    //                 <button
    //                   className="btn btn-default nl-btn-right"
    //                   type="submit"
    //                 >
    //                   <svg
    //                     viewBox="0 0 511.999 511.999"
    //                     width={22}
    //                     height={22}
    //                     fill="#fff"
    //                   >
    //                     <path d="M493.469,0.481c-0.14,0.034-0.281,0.062-0.421,0.1L26.362,127.859c-11.729,3.199-14.956,18.333-5.591,26.049L166.019,273.57l-26.743,80.228c-3.898,11.696,7.26,22.877,18.974,18.974l80.228-26.743L358.14,491.277c7.748,9.403,22.855,6.115,26.049-5.591L511.466,18.999c0.04-0.147,0.07-0.296,0.105-0.444C514.204,7.56,504.277-2.128,493.469,0.481z M62.21,149.178L431.982,48.331L183.593,249.179L62.21,149.178z M234.599,315.698l-57.376,19.126l19.126-57.376L396.206,115.84L234.599,315.698z M362.868,449.836L262.866,328.453l200.85-248.39L362.868,449.836z" />
    //                     <path d="M46.805,465.313c-5.861-5.856-15.359-5.851-21.214,0.01L4.388,486.546c-5.855,5.86-5.851,15.358,0.01,21.213c5.594,5.589,15.625,5.584,21.213-0.01l21.204-21.223C52.67,480.666,52.666,471.168,46.805,465.313z" />
    //                     <path d="M110.413,401.645c-5.861-5.855-15.357-5.851-21.213,0.01l-21.203,21.223c-5.322,5.327-5.317,15.891,0.01,21.213c5.059,5.054,16.156,5.051,21.213-0.01l21.203-21.223C116.278,416.998,116.274,407.5,110.413,401.645z" />
    //                   </svg>
    //                 </button>
    //               </div>
    //             </div>
    //           </div>
    //           <div className="license">
    //             <p>
    //               <b>Trade License:</b> TRAD/DNCC/079674/2022
    //             </p>
    //             <p>
    //               <b>e-TIN:</b> 272675143345
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="mobile-content visible-xs">
    //         <div className="footer-acrodion">
    //           <div
    //             className="mc-header"
    //             data-toggle="collapse"
    //             data-target="#mbft1"
    //           >
    //             <span>newsletter</span>
    //             <i className="fa fa-plus" />
    //           </div>
    //           <div id="mbft1" className="mc-content collapse">
    //             <div className="newsletter">
    //               <h5>Sign up for the latest offers and promotions</h5>
    //               <div className="input-group">
    //                 <input
    //                   type="email"
    //                   className="form-control user_email"
    //                   placeholder="Please Enter an email"
    //                 />
    //                 <div className="input-group-btn">
    //                   <button
    //                     className="btn btn-default nl-btn-right"
    //                     type="submit"
    //                   >
    //                     submit
    //                   </button>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="footer-acrodion">
    //           <div
    //             className="mc-header"
    //             data-toggle="collapse"
    //             data-target="#mbft2"
    //           >
    //             <span>quick link</span>
    //             <i className="fa fa-plus" />
    //           </div>
    //           <div id="mbft2" className="mc-content collapse">
    //             <ul className="footer-link">
    //               <li>
    //                 <a href="/pages/Terms-and-conditions" target="_blank">
    //                   Terms &amp; Conditions
    //                 </a>
    //               </li>
    //               <li>
    //                 <a href="/pages/shipping-returns-privacy" target="_blank">
    //                   Shipping, Returns &amp; Privacy
    //                 </a>
    //               </li>
    //               <li>
    //                 <a href="/pages/Warranty-Information" target="_blank">
    //                   Warranty Information
    //                 </a>
    //               </li>
    //               <li>
    //                 <a href="/store/track_orders/new" target="_blank">
    //                   Order tracking
    //                 </a>
    //               </li>
    //               <li>
    //                 <a href="/pages/online-emi-policy" target="_blank">
    //                   Online EMI Policy
    //                 </a>
    //               </li>
    //               <li>
    //                 <a href="/pages/About-Us" target="_blank">
    //                   About Us
    //                 </a>
    //               </li>
    //             </ul>
    //           </div>
    //         </div>
    //         <div className="footer-acrodion">
    //           <div
    //             className="mc-header"
    //             data-toggle="collapse"
    //             data-target="#mbft3"
    //           >
    //             <span>contact us</span>
    //             <i className="fa fa-plus" />
    //           </div>
    //           <div id="mbft3" className="mc-content collapse">
    //             <div className="outlets">
    //               <div className="icon">
    //                 <svg
    //                   xmlns="http://www.w3.org/2000/svg"
    //                   fill="none"
    //                   viewBox="0 0 24 24"
    //                   strokeWidth="1.5"
    //                   stroke="currentColor"
    //                   width={22}
    //                   height={22}
    //                 >
    //                   <path
    //                     strokeLinecap="round"
    //                     strokeLinejoin="round"
    //                     d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
    //                   />
    //                   <path
    //                     strokeLinecap="round"
    //                     strokeLinejoin="round"
    //                     d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
    //                   />
    //                 </svg>
    //               </div>
    //               <div className="details">
    //                 <div className="outlet-name">GULSHAN BRANCH:</div>
    //                 <div className="outlet-address">
    //                   House-30, Road-7, Block-C, Niketon, Dhaka
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="outlets">
    //               <div className="icon">
    //                 <svg
    //                   xmlns="http://www.w3.org/2000/svg"
    //                   fill="none"
    //                   viewBox="0 0 24 24"
    //                   strokeWidth="1.5"
    //                   stroke="currentColor"
    //                   width={22}
    //                   height={22}
    //                 >
    //                   <path
    //                     strokeLinecap="round"
    //                     strokeLinejoin="round"
    //                     d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
    //                   />
    //                   <path
    //                     strokeLinecap="round"
    //                     strokeLinejoin="round"
    //                     d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
    //                   />
    //                 </svg>
    //               </div>
    //               <div className="details">
    //                 <div className="outlet-name">DHANMONDI BRANCH:</div>
    //                 <div className="outlet-address">
    //                   Shop No. 247-258 Shimanto Square (Rifles), Road 2,
    //                   Dhanmondi, Dhaka
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="phone">
    //               <div className="title">phone</div>
    //               <div className="text">01788888947, 01788888948</div>
    //             </div>
    //             <div className="email">
    //               <div className="title">email</div>
    //               <div className="text">inquiry@thevapecafe.com.bd</div>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="footer-acrodion">
    //           <div
    //             className="mc-header"
    //             data-toggle="collapse"
    //             data-target="#mbft4"
    //           >
    //             <span>facebook store</span>
    //             <i className="fa fa-plus" />
    //           </div>
    //           <div id="mbft4" className="mc-content collapse">
    //             <div
    //               className="fb-page fb_iframe_widget"
    //               data-href="https://www.facebook.com/thevapecafebd"
    //               data-small-header="false"
    //               data-adapt-container-width="true"
    //               data-hide-cover="false"
    //               data-show-facepile="true"
    //               data-show-posts="false"
    //               fb-xfbml-state="rendered"
    //               fb-iframe-plugin-query="adapt_container_width=true&app_id=&container_width=0&hide_cover=false&href=https%3A%2F%2Fwww.facebook.com%2Fthevapecafebd&locale=en_US&sdk=joey&show_facepile=true&show_posts=false&small_header=false"
    //             >
    //               <span
    //                 style={{ verticalAlign: "bottom", width: 340, height: 0 }}
    //               >
    //                 <iframe
    //                   name="f892fa5d5a6ec728d"
    //                   width="1000px"
    //                   height="1000px"
    //                   data-testid="fb:page Facebook Social Plugin"
    //                   title="fb:page Facebook Social Plugin"
    //                   frameBorder={0}
    //                   allowTransparency="true"
    //                   allowFullScreen="true"
    //                   scrolling="no"
    //                   allow="encrypted-media"
    //                   src="https://www.facebook.com/v17.0/plugins/page.php?adapt_container_width=true&app_id=&channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df96076bee38c19046%26domain%3Dwww.thevapecafe.com.bd%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fwww.thevapecafe.com.bd%252Ffc08530f2da9d94d5%26relation%3Dparent.parent&container_width=0&hide_cover=false&href=https%3A%2F%2Fwww.facebook.com%2Fthevapecafebd&locale=en_US&sdk=joey&show_facepile=true&show_posts=false&small_header=false"
    //                   className=""
    //                   style={{
    //                     border: "none",
    //                     visibility: "visible",
    //                     width: 340,
    //                     height: 0,
    //                   }}
    //                 />
    //               </span>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <footer className="footer style7">
      <div className="container">
        <div className="container-wapper">
          <div className="row">
            <div className="box-footer col-xs-12 col-sm-6 col-md-4 col-lg-4">
              <div className="herald-custommenu default">
                <h2 className="widgettitle">Quick Menu</h2>
                <div className="row">
                  <div className="col-xs-6 col-sm-6 col-md-6">
                    <ul className="menu">
                      <li className="menu-item">
                        <a href="#">New arrivals</a>
                      </li>
                      <li className="menu-item">
                        <a href="#">Life style</a>
                      </li>
                      <li className="menu-item">
                        <a href="#">Creamy</a>
                      </li>
                      <li className="menu-item">
                        <a href="#">Fruity</a>
                      </li>
                      <li className="menu-item">
                        <a href="#">Beverage</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-6">
                    <ul className="menu">
                      <li className="menu-item">
                        <a href="#">FAQs</a>
                      </li>
                      <li className="menu-item">
                        <a href="#">Track Order</a>
                      </li>
                      <li className="menu-item">
                        <a href="#">Delivery</a>
                      </li>
                      <li className="menu-item">
                        <a href="#">Contact Us</a>
                      </li>
                      <li className="menu-item">
                        <a href="#">Return</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-footer col-xs-12 col-sm-6 col-md-4 col-lg-4">
              <div className="herald-custommenu default">
                <h2 className="widgettitle">Contact</h2>
                <div className="single-img">
                  45 Grand Central Terminal New York,NY 1017 United State USA
                </div>
                <ul className="menu">
                  <li className="menu-item">
                    Phone:{" "}
                    <a href="tel:(+123) 456 789 - (+123) 666 888">
                      <strong>(+123) 456 789</strong>
                    </a>
                  </li>
                  <li className="menu-item">
                    Fax:{" "}
                    <a href="tel:(+123) 456 789 - (+123) 666 888">
                      <strong>(+123) 666 888</strong>
                    </a>
                  </li>
                  <li className="menu-item">
                    Email:{" "}
                    <a href="mailto:Contact@yourcompany.com">
                      <strong>Contact@yourcompany.com</strong>
                    </a>
                  </li>
                  <li className="menu-item">
                    Follow us:
                    <div className="herald-socials">
                      <ul className="socials">
                        <li>
                          <a href="#" className="social-item" target="_blank">
                            <i className="icon fa fa-facebook-square" />
                          </a>
                        </li>
                        <li>
                          <a href="#" className="social-item" target="_blank">
                            <i className="icon fa fa-twitter-square" />
                          </a>
                        </li>
                        <li>
                          <a href="#" className="social-item" target="_blank">
                            <i className="icon fa fa-google-plus-square" />
                          </a>
                        </li>
                        <li>
                          <a href="#" className="social-item" target="_blank">
                            <i className="icon fa fa-linkedin-square" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="box-footer col-xs-12 col-sm-12 col-md-6 col-lg-4">
              <div className="herald-newsletter style1">
                <div className="newsletter-head">
                  <h3 className="title">Newsletter</h3>
                </div>
                <div className="newsletter-form-wrap">
                  <div className="list">
                    Join up to get the latest on sales, new arrivals and more...
                  </div>
                  <input
                    type="email"
                    className="input-text email email-newsletter"
                    placeholder="Your email letter"
                  />
                  <button className="button btn-submit submit-newsletter">
                    SUBSCRIBE
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 border-custom">
              <span />
            </div>
          </div>
          <div className="footer-end">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="herald-payment">
                  <img
                    src="https://dreamingtheme.kiendaotac.com/html/herald/assets/images/payments.png"
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="coppyright">
                  Copyright © 2024
                  <a href="#">Vape Lounge</a>. All rights reserved
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
