import React from "react";
import "./adview.css";
const Adview = () => {
  return (
    <section
      className="discount-area bg-image"
      data-background="https://gramentheme.com/html/odor/assets/images/bg/discount-bg2.jpg"
      style={{
        backgroundImage:
          'url("https://gramentheme.com/html/odor/assets/images/bg/discount-bg2.jpg")',
      }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="image mb-5 mb-lg-0">
              <img
                src="https://gramentheme.com/html/odor/assets/images/discount/discount-image2.png"
                alt="image"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="discount__item ps-0 pb-5 pb-lg-0 ps-lg-5">
              <div className="section-header">
                <div
                  className="section-title-icon wow fadeInUp"
                  data-wow-delay=".1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInUp",
                  }}
                >
                  <h2 style={{ color: "white" }}>find your best favourite</h2>
                </div>
                <p
                  className="mt-30 mb-55 wow fadeInUp"
                  data-wow-delay=".2s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.2s",
                    animationName: "fadeInUp",
                    color: "white",
                  }}
                >
                  Sell globally in minutes with localized currencies languages,
                  and
                  <br />
                  experie in every market. only a variety of vaping products
                </p>
                {/* <a
                  className="btn-one wow fadeInUp"
                  data-wow-delay=".3s"
                  data-toggle="pill"
                  href=""
                  style={{
                    visibility: "visible",
                    animationDelay: "0.3s",
                    animationName: "fadeInUp",
                  }}
                >
                  <span>Shop Now</span>
                </a> */}
                <a
                  className="off-btn wow fadeInUp"
                  data-wow-delay=".4s"
                  href="#0"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.4s",
                    animationName: "fadeInUp",
                  }}
                >
                  <img
                    className="mr-10"
                    src="https://gramentheme.com/html/odor/assets/images/icon/fire.svg"
                    alt="icon"
                  />{" "}
                  GET <span className="primary-color">25% OFF</span> NOW
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Adview;
