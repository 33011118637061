import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Button, TextField, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import "./ctpanel.css";
import axios from "axios";
import { BASE_URl } from "../../Utils/Network";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CategoryPanel = () => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [category, setCategory] = useState();
  const [subcategory, setSubcategory] = useState();
  const [dropdown, setDropdown] = useState();
  const [categoryName, setCategoryName] = useState("");
  const [dropdwonName, setDropdwonName] = useState("");

  const handleChange = (event) => {
    setCategoryName(event.target.value);
  };

  const [subcategoryName, setSubategoryName] = useState("");

  const handleChangeSub = (event) => {
    setSubategoryName(event.target.value);
  };

  const handledropdownName = (event) => {
    setDropdwonName(event.target.value);
  };
  const handleSubmit = () => {
    // Make a POST request using Axios
    let data = {
      name: categoryName,
    };
    axios
      .post(BASE_URl + "category", data)
      .then((response) => {
        // Handle success
        if (response.status === 201) {
          handleClose();
        }
        // Optionally, do something with the response
      })
      .catch((error) => {
        // Handle error
        console.error("Error making post request", error);
      });
  };

  const handleSubmitsub = () => {
    // Make a POST request using Axios
    let data = {
      name: subcategoryName,
      category: catId,
    };
    axios
      .post(BASE_URl + "subcategory", data)
      .then((response) => {
        // Handle success
        if (response.status === 201) {
          handleClose1();
        }
        // Optionally, do something with the response
      })
      .catch((error) => {
        // Handle error
        console.error("Error making post request", error);
      });
  };

  useEffect(() => {
    axios
      .get(BASE_URl + "category")
      .then((res) => {
        setCategory(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(BASE_URl + "subcategory")
      .then((res) => {
        setSubcategory(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(BASE_URl + "dropdown")
      .then((res) => {
        setDropdown(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [catId, setCatId] = React.useState("");
  const [subcatId, setsubCatId] = React.useState("");

  const handlesChange = (event) => {
    setCatId(event.target.value);
  };

  const handlesChangesubs = (event) => {
    setsubCatId(event.target.value);
  };

  const [subbycat, setSubbycat] = useState();
  const getsubbycat = (event) => {
    setCatId(event.target.value);
    axios
      .get(BASE_URl + "subcategory/getsubbyCat/" + event.target.value)
      .then((res) => {
        setSubbycat(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmitdropdown = () => {
    // Make a POST request using Axios
    let data = {
      name: dropdwonName,
      category: catId,
      subcategory: subcatId,
    };
    axios
      .post(BASE_URl + "dropdown", data)
      .then((response) => {
        // Handle success
        if (response.status === 201) {
          handleClose2();
        }
        // Optionally, do something with the response
      })
      .catch((error) => {
        // Handle error
        console.error("Error making post request", error);
      });
  };

  return (
    <Box>
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={3}>
            <Item>
              <Box>Total Category</Box>
              <Box>{category?.length}</Box>
            </Item>
          </Grid>
          <Grid item xs={3}>
            <Item>
              <Box>Total Subcategory</Box>
              <Box>{subcategory?.length}</Box>
            </Item>
          </Grid>
          <Grid item xs={3}>
            <Item>
              <Box>Total Dropdown </Box>
              <Box>{dropdown?.length}</Box>
            </Item>
          </Grid>
          {/* <Grid item xs={3}>
            <Item>
              <Box>Today Expences</Box>
              <Box>5</Box>
            </Item>
          </Grid> */}
        </Grid>
      </Box>
      <Box sx={{ width: "100%" }} style={{ marginTop: 20 }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={3}>
            <Button variant="contained" onClick={handleOpen}>
              Create Category{" "}
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button variant="contained" onClick={handleOpen1}>
              Create SubCategory{" "}
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button variant="contained" onClick={handleOpen2}>
              Create Dropdown{" "}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Create Category
            </Typography>
            <TextField
              style={{ width: "100%" }}
              id="outlined-basic"
              label="Category Name"
              variant="outlined"
              value={categoryName}
              onChange={handleChange}
            />
            <Box>
              <Button
                onClick={() => {
                  handleSubmit();
                }}
              >
                Create
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
      {/* ----- subcategory ----- */}
      <div>
        <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Create Subcategory
            </Typography>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Category
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={catId}
                  label="Select Category"
                  onChange={handlesChange}
                >
                  {category?.map((item, index) => {
                    return <MenuItem value={item._id}>{item.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>

            <TextField
              style={{ width: "100%", marginTop: 10 }}
              id="outlined-basic"
              label="Subcategory Name"
              variant="outlined"
              value={subcategoryName}
              onChange={handleChangeSub}
            />
            <Box>
              <Button
                onClick={() => {
                  handleSubmitsub();
                }}
              >
                Create
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
      {/* -----  dropdowncreate ----- */}

      <div>
        <Modal
          open={open2}
          onClose={handleClose2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Create DropDown
            </Typography>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Category
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={catId}
                  label="Select Category"
                  onChange={getsubbycat}
                >
                  {category?.map((item, index) => {
                    return <MenuItem value={item._id}>{item.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ minWidth: 120 }} style={{ marginTop: 10 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Subcategory
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={subcatId}
                  label="Select Subcategory"
                  onChange={handlesChangesubs}
                >
                  {subbycat?.map((item, index) => {
                    return <MenuItem value={item._id}>{item.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>

            <TextField
              style={{ width: "100%", marginTop: 10 }}
              id="outlined-basic"
              label="DropDown Name"
              variant="outlined"
              value={dropdwonName}
              onChange={handledropdownName}
            />
            <Box>
              <Button
                onClick={() => {
                  handleSubmitdropdown();
                }}
              >
                Create
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
      {/* Table */}

      <>
        <h2>All Category </h2>
        <div className="table-wrapper">
          <table className="fl-table">
            <thead>
              <tr>
                <th>SL No</th>
                <th>Category</th>
                <th>Created</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {category?.map((item, index) => {
                return (
                  <tr>
                    <td> {index + 1}</td>
                    <td>{item.name}</td>
                    <td>Content 1</td>
                  </tr>
                );
              })}
            </tbody>
            <tbody></tbody>
          </table>
        </div>
      </>

      <>
        <h2>All SubCategory </h2>
        <div className="table-wrapper">
          <table className="fl-table">
            <thead>
              <tr>
                <th>SL No</th>
                <th>Category</th>
                <th>Created</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {subcategory?.map((item, index) => {
                return (
                  <tr>
                    <td> {index + 1}</td>
                    <td>{item.name}</td>
                    <td>Content 1</td>
                  </tr>
                );
              })}
            </tbody>
            <tbody></tbody>
          </table>
        </div>
      </>

      <>
        <h2>All Dropdown </h2>
        <div className="table-wrapper">
          <table className="fl-table">
            <thead>
              <tr>
                <th>SL No</th>
                <th>Category</th>
                <th>Created</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {dropdown?.map((item, index) => {
                return (
                  <tr>
                    <td> {index + 1}</td>
                    <td>{item.name}</td>
                    <td>Content 1</td>
                  </tr>
                );
              })}
            </tbody>
            <tbody></tbody>
          </table>
        </div>
      </>
    </Box>
  );
};

export default CategoryPanel;
