import React, { useEffect, useState } from "react";

import "./deatilspage.css";
import Navbar from "../AppBar/appbar";
import axios from "axios";
import { BASE_URl, imgBASE_URl } from "../../Utils/Network";
import { useParams } from "react-router-dom";
const DeatilsPage = () => {
  let { id } = useParams();

  const [detaills, setDetails] = useState();

  useEffect(() => {
    axios
      .get(BASE_URl + `product/productbyId/${id}`)
      .then((res) => {
        setDetails(res.data);
      })
      .catch((Err) => {
        console.log(Err);
      });
  }, []);

  const [cart, setCart] = useState([]);

  const handleCart = (newItem) => {
    // Retrieve current cart items from localStorage
    let existingCartItems = JSON.parse(localStorage.getItem("cart")) || [];

    // If existingCartItems is null or undefined, default it to an empty array
    if (!Array.isArray(existingCartItems)) {
      existingCartItems = [];
    }

    // Add the default quantity field to the new item
    const itemWithQuantity = {
      ...newItem,
      quantity: 1, // Set the default quantity to 1 or any other default value
    };

    // Add the new item with quantity to the existing items
    const updatedCart = [...existingCartItems, itemWithQuantity];

    // Store the updated cart back into localStorage
    localStorage.setItem("cart", JSON.stringify(updatedCart));

    // Optionally, update the cart state if needed
    setCart(updatedCart);
  };

  return (
    <>
      <Navbar getCartLength={cart} />
      {(detaills && detaills != null) || undefined ? (
        <div className="product-details-page" id="product-details-page">
          {/*Adding Partials Quick View File*/}
          {/*Quick View Modal */}
          <div id="quickview_content">
            <div
              id="quickviewModal"
              className="modal fade"
              role="dialog"
              data-bind="with: $root.quickView"
            />
          </div>
          <div
            className="content-wrapper page-loader"
            data-bind="visible: $root.loading() == true"
            style={{ display: "none" }}
          >
            <div className="row">
              <div className="col-sm-6 col-xs-12">
                <div className="placeholder" style={{ height: 600 }} />
              </div>
              <div className="col-sm-6 col-xs-12">
                <div className="placeholder" style={{ height: 25 }} />
                <div className="placeholder" style={{ height: 20 }} />
                <div className="placeholder" style={{ height: 10 }} />
                <hr />
                <div className="placeholder" style={{ height: 10 }} />
                <div className="placeholder" style={{ height: 10 }} />
                <div className="row">
                  <div className="col-sm-2">
                    <span
                      className="placeholder varient"
                      style={{ height: 20, width: 30 }}
                    />
                  </div>
                  <div className="col-sm-2">
                    <span
                      className="placeholder varient"
                      style={{ height: 20, width: 30 }}
                    />
                  </div>
                  <div className="col-sm-2">
                    <span
                      className="placeholder varient"
                      style={{ height: 20, width: 30 }}
                    />
                  </div>
                  <div className="col-sm-2">
                    <span
                      className="placeholder varient"
                      style={{ height: 20, width: 30 }}
                    />
                  </div>
                </div>
                <div className="placeholder" style={{ height: 50 }} />
                <div className="placeholder" style={{ height: 50 }} />
                <div className="placeholder" style={{ height: 10 }} />
                <hr />
                <div className="placeholder" style={{ height: 10 }} />
                <div className="placeholder" style={{ height: 20 }} />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-2">
                <div className="placeholder" style={{ height: 30 }} />
              </div>
              <div className="col-sm-2">
                <div className="placeholder" style={{ height: 30 }} />
              </div>
              <div className="col-sm-2">
                <div className="placeholder" style={{ height: 30 }} />
              </div>
            </div>
            <div
              className="placeholder"
              style={{ height: 100, marginTop: 0 }}
            />
            <div className="placeholder" style={{ height: 50 }} />
            <div className="row">
              <div className="col-sm-3">
                <div className="placeholder" style={{ height: 200 }} />
              </div>
              <div className="col-sm-3">
                <div className="placeholder" style={{ height: 200 }} />
              </div>
              <div className="col-sm-3">
                <div className="placeholder" style={{ height: 200 }} />
              </div>
              <div className="col-sm-3">
                <div className="placeholder" style={{ height: 200 }} />
              </div>
            </div>
          </div>
          <div
            className="product-details"
            data-bind="visible: $root.loading() == false"
            style={{}}
          >
            <div className="content-wrapper">
              <ul className="breadcrumb" data-bind="with:product">
                <li>
                  <a href="/">Home</a>
                </li>
                <li className="active">
                  <span data-bind="text:name">{detaills[0]?.name}</span>
                </li>
              </ul>
              <div className="row">
                <div data-bind="with: $root.parentProduct">
                  {/*ko if: images_urls.length > 0 */}
                  <div className="col-lg-5 col-lg-push-1 col-md-5 col-md-push-2 col-sm-12 col-xs-12 product-image-slider">
                    <div
                      className="pd-slider slick-initialized slick-slider"
                      data-bind="foreach:images_urls, slickSlider: {data: images_urls, options: $root.pdCarousel}"
                    >
                      <div className="slick-list draggable">
                        <div
                          className="slick-track"
                          style={{ opacity: 1, width: 397 }}
                        >
                          <div
                            className="slick-slide slick-current slick-active"
                            data-slick-index={0}
                            aria-hidden="false"
                            style={{
                              width: 377,
                              position: "relative",
                              left: 0,
                              top: 0,
                              zIndex: 999,
                              opacity: 1,
                            }}
                          >
                            <div>
                              <div
                                className="product-image"
                                style={{
                                  width: "100%",
                                  display: "inline-block",
                                }}
                              >
                                <a
                                  data-bind="attr: {href: $data.original}"
                                  data-fancybox="images"
                                  href={
                                    imgBASE_URl +
                                    `${detaills[0]?.image[0]?.filename}`
                                  }
                                  tabIndex={0}
                                >
                                  <img
                                    className="img-responsive pzoom"
                                    data-bind="attr:{ src: $data.original,'data-zoom-image': $data.original }"
                                    width={800}
                                    height={1000}
                                    alt="Slider Image"
                                    src={
                                      imgBASE_URl +
                                      `${detaills[0]?.image[0]?.filename}`
                                    }
                                    data-zoom-image={
                                      imgBASE_URl +
                                      `${detaills[0]?.image[0]?.filename}`
                                    }
                                  />
                                  <div className="expand-icon">
                                    <div className="text">Click to enlarge</div>
                                    <div className="icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        aria-hidden="true"
                                        viewBox="0 0 448 512"
                                        width={14}
                                        height={14}
                                      >
                                        <path d="M416 176V86.63L246.6 256L416 425.4V336c0-8.844 7.156-16 16-16s16 7.156 16 16v128c0 8.844-7.156 16-16 16h-128c-8.844 0-16-7.156-16-16s7.156-16 16-16h89.38L224 278.6L54.63 448H144C152.8 448 160 455.2 160 464S152.8 480 144 480h-128C7.156 480 0 472.8 0 464v-128C0 327.2 7.156 320 16 320S32 327.2 32 336v89.38L201.4 256L32 86.63V176C32 184.8 24.84 192 16 192S0 184.8 0 176v-128C0 39.16 7.156 32 16 32h128C152.8 32 160 39.16 160 48S152.8 64 144 64H54.63L224 233.4L393.4 64H304C295.2 64 288 56.84 288 48S295.2 32 304 32h128C440.8 32 448 39.16 448 48v128C448 184.8 440.8 192 432 192S416 184.8 416 176z" />
                                      </svg>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1 col-lg-pull-5 col-md-2 col-md-pull-5 col-sm-12 col-xs-12 thumb-control">
                    <div
                      className="thumb-slider slick-initialized slick-slider slick-vertical"
                      id="thumbs"
                      data-bind="foreach:images_urls, slickSlider: {data: images_urls, options: $root.thumbCarousel}"
                    >
                      <div
                        className="slick-list draggable"
                        style={{ height: 284 }}
                      >
                        <div
                          className="slick-track"
                          style={{
                            opacity: 1,
                            height: 71,
                            transform: "translate3d(0px, 0px, 0px)",
                          }}
                        >
                          <div
                            className="slick-slide slick-current slick-active"
                            data-slick-index={0}
                            aria-hidden="false"
                            style={{ width: 51 }}
                          >
                            <div>
                              <div
                                className="thumb"
                                style={{
                                  width: "100%",
                                  display: "inline-block",
                                }}
                              >
                                <img
                                  className="img-responsive"
                                  data-bind="attr:{src: $data.original}"
                                  width={800}
                                  height={1000}
                                  alt="Thumb Slider Image"
                                  src={
                                    imgBASE_URl +
                                    `${detaills[0]?.image[0]?.filename}`
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-5 col-sm-12 col-xs-12 pd-description">
                  <div
                    className="product-description"
                    data-bind="with: product"
                  >
                    <div className="name vt-middle-align">
                      <h4 data-bind="text: name">{detaills[0]?.name}</h4>
                    </div>

                    <div className="price">
                      <span className="current-price" data-bind="text: price">
                        BDT {detaills[0]?.price}
                      </span>
                      <s
                        className="old-price"
                        data-bind="visible: compare_at_price != null && compare_at_price != '',text: compare_at_price"
                        style={{ display: "none" }}
                      />
                    </div>

                    <div
                      className="product-short-text"
                      data-bind="with: $root.parentProduct"
                    >
                      <p data-bind="html: short_description">
                        {detaills[0]?.shortdesc}
                      </p>
                    </div>
                    <div className="stock-status">
                      <span>Availability: </span>
                      <span
                        data-bind="text: stock > 0 ? 'In Stock' : 'Out Of Stock',css: stock > 0 ? 'instock' : 'stockout'"
                        className="instock"
                      >
                        {detaills[0]?.stock > 0 ? "In Stock" : "Out of Stock"}
                      </span>
                    </div>

                    <div className="variants">
                      <div className="variant-option">
                        <label
                          className="variant-title"
                          data-bind="text: option"
                        >
                          Color
                        </label>
                        <ul data-bind="foreach: values">
                          <li>
                            <a
                              data-bind="text: value,click: $root.option1Click, css: selected() == true ? 'active': ''"
                              className="active"
                            >
                              Blue &amp; Black
                            </a>
                          </li>
                          <li>
                            <a data-bind="text: value,click: $root.option1Click, css: selected() == true ? 'active': ''">
                              Orange &amp; Black
                            </a>
                          </li>
                          <li>
                            <a data-bind="text: value,click: $root.option1Click, css: selected() == true ? 'active': ''">
                              Silver
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="quantity-addtocart">
                      <div className="quantity-box">
                        <button
                          type="button"
                          className="btn-quantity"
                          data-bind="click:$root.cartVM.decreaseQuantity"
                        >
                          <svg
                            width={18}
                            height={18}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={1}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <title>Minus</title>
                            <line x1={5} y1={12} x2={19} y2={12} />
                          </svg>
                        </button>
                        <input
                          type="text"
                          className="form-control quantity-input text-center"
                          id="pdqnt"
                        />
                        <button
                          type="button"
                          className="btn-quantity"
                          data-bind="disable:$root.cartVM.quantity() >= stock,click:$root.cartVM.increaseQuantity"
                        >
                          <svg
                            width={18}
                            height={18}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={1}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <title>Plus</title>
                            <line x1={12} y1={5} x2={12} y2={19} />
                            <line x1={5} y1={12} x2={19} y2={12} />
                          </svg>
                        </button>
                      </div>
                      <div className="addtocart">
                        <button
                          className="tbtn btn-solid block-btn shake-button"
                          onClick={() => {
                            handleCart(detaills[0]);
                          }}
                        >
                          add to cart
                        </button>
                      </div>
                    </div>
                    <div
                      className="wishlist vt-middle-align"
                      data-bind="click: $root.wishlistVM.addToWishList"
                    >
                      <div className="icon">
                        <svg viewBox="0 0 32 32" width={22} height={22}>
                          <path
                            d="M21.886 5.115c3.521 0 6.376 2.855 6.376 6.376 0 1.809-0.754 3.439-1.964 4.6l-10.297 10.349-10.484-10.536c-1.1-1.146-1.778-2.699-1.778-4.413 0-3.522 2.855-6.376 6.376-6.376 2.652 0 4.925 1.62 5.886 3.924 0.961-2.304 3.234-3.924 5.886-3.924zM21.886 4.049c-2.345 0-4.499 1.089-5.886 2.884-1.386-1.795-3.54-2.884-5.886-2.884-4.104 0-7.442 3.339-7.442 7.442 0 1.928 0.737 3.758 2.075 5.152l11.253 11.309 11.053-11.108c1.46-1.402 2.275-3.308 2.275-5.352 0-4.104-3.339-7.442-7.442-7.442v0z"
                            fill="#000000"
                          />
                        </svg>
                      </div>
                      <div className="text">Add to Wishlist</div>
                    </div>
                  </div>
                  <div
                    className="product-info-mobile visible-xs"
                    data-bind="with: $root.parentProduct"
                  >
                    <div className="panel-group">
                      <div className="panel panel-default">
                        <div className="panel-heading">
                          <h4 className="panel-title text-capitalize">
                            <a
                              className="toggle-icon"
                              data-toggle="collapse"
                              data-target="#pdAc1"
                            >
                              <span>description</span>
                              <i className="fa fa-plus" />
                            </a>
                          </h4>
                        </div>
                        <div id="pdAc1" className="panel-collapse collapse">
                          <div className="panel-body">
                            {/* ko if: description !="" || description !=null */}
                            <div data-bind="html: description">
                              <p>{detaills[0]?.description}</p>
                              {/* <p>
                                <strong>
                                  Uwell Caliburn GZ2 Pod System Features:
                                </strong>
                                <br />
                                • Dimensions: 68.9mm by 23.8mm by 23.6mm
                                <br />
                                • Battery Capacity: 850mAh
                                <br />
                                • Max Wattage: 17W
                                <br />
                                • Voltage Range: 0.8-1.0ohm
                                <br />
                                • Chassis Material: Aluminum-Alloy
                                <br />
                                • Charging: Type-C Port
                                <br />
                                • Operation: Draw-Activated
                                <br />
                                • Pod Series: Caliburn G2 Pods
                                <br />
                                • Pod Capacity: 2ml
                                <br />
                                • Fill-System: Top Fill System
                                <br />• Coil Support: Caliburn G Coils
                              </p>
                              <p>
                                <strong>Package Includes:</strong>
                                <br />
                                • 1 GZ2 Device
                                <br />
                                • 2 Caliburn G2 Pods
                                <br />
                                • 1 0.8ohm Caliburn Coils
                                <br />
                                • 1 1.2ohm Caliburn G2 Coils
                                <br />
                                • 1 USB-C Cable
                                <br />• 1 User Manual
                              </p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="panel-group">
                      <div className="panel panel-default">
                        <div className="panel-heading">
                          <h4 className="panel-title text-capitalize">
                            <a
                              className="toggle-icon"
                              data-toggle="collapse"
                              data-target="#pdAc2"
                            >
                              <span>shipping info</span>
                              <i className="fa fa-plus" />
                            </a>
                          </h4>
                        </div>
                        <div id="pdAc2" className="panel-collapse collapse">
                          <div className="panel-body">
                            <p data-bind="html: product_shipping_info" />
                            <p>
                              Inside Chittagong City: BDT100&nbsp;
                              <br />
                              ⏱️24hrs after the confirmation call
                            </p>
                            <p>
                              Outside Dhaka City: BDT150&nbsp;
                              <br />
                              ⏱️48-72hrs after the confirmation call&nbsp;
                              <u>
                                <strong>
                                  (100% payment required in advance)
                                </strong>
                              </u>
                            </p>
                            <p>
                              <br />
                              <strong>
                                FREE delivery on orders above BDT3000‼️
                              </strong>
                            </p>
                            <p />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="panel-group">
                      <div className="panel panel-default">
                        <div className="panel-heading">
                          <h4 className="panel-title text-capitalize">
                            <a
                              className="toggle-icon"
                              data-toggle="collapse"
                              data-target="#pdAc3"
                            >
                              <span>Review</span>
                              <i className="fa fa-plus" />
                            </a>
                          </h4>
                        </div>
                        <div id="pdAc3" className="panel-collapse collapse">
                          <div className="panel-body">
                            <div
                              className="customer-review"
                              id="customer-review"
                            >
                              <div data-bind="with:$root.reviewVM">
                                <div className="review-section">
                                  <h2>Customer Reviews</h2>
                                  <div
                                    className="review-stars"
                                    data-bind="with:product"
                                  >
                                    <span className="rating">
                                      <i
                                        className="fa fa-star-o"
                                        data-bind="css: $root.parentProduct().total_rating > 0.5 ? 'fa fa-star' : 'fa fa-star-o'"
                                      />
                                      <i
                                        className="fa fa-star-o"
                                        data-bind="css: $root.parentProduct().total_rating > 1.5 ? 'fa fa-star' : 'fa fa-star-o'"
                                      />
                                      <i
                                        className="fa fa-star-o"
                                        data-bind="css: $root.parentProduct().total_rating > 2.5 ? 'fa fa-star' : 'fa fa-star-o'"
                                      />
                                      <i
                                        className="fa fa-star-o"
                                        data-bind="css: $root.parentProduct().total_rating > 3.5 ? 'fa fa-star' : 'fa fa-star-o'"
                                      />
                                      <i
                                        className="fa fa-star-o"
                                        data-bind="css: $root.parentProduct().total_rating > 4.5 ? 'fa fa-star' : 'fa fa-star-o'"
                                      />
                                    </span>
                                    <span className="review-caption">
                                      <span className="toggle-reviews">
                                        Based on{" "}
                                        <span data-bind="text: $root.reviewVM().review_count">
                                          0
                                        </span>{" "}
                                        review
                                      </span>
                                    </span>
                                  </div>
                                  <div className="review-desktop">
                                    <div className="review-header">
                                      <h3 className="title">
                                        Let us know your opinion
                                      </h3>
                                    </div>
                                    <div id="new-review">
                                      <form
                                        className="review-form check-review-data"
                                        id="check-review-data-mobile"
                                        method="POST"
                                      >
                                        <div className="rating-star">
                                          <label htmlFor="rating">Rating</label>
                                          <div className="stars">
                                            <span>
                                              {/*ko foreach: stars */}
                                              <i
                                                className="fa fa-star"
                                                data-bind="css: selected() ? 'fa fa-star' : 'fa fa-star-o', click: $parent.starClick"
                                              />
                                              <i
                                                className="fa fa-star-o"
                                                data-bind="css: selected() ? 'fa fa-star' : 'fa fa-star-o', click: $parent.starClick"
                                              />
                                              <i
                                                className="fa fa-star-o"
                                                data-bind="css: selected() ? 'fa fa-star' : 'fa fa-star-o', click: $parent.starClick"
                                              />
                                              <i
                                                className="fa fa-star-o"
                                                data-bind="css: selected() ? 'fa fa-star' : 'fa fa-star-o', click: $parent.starClick"
                                              />
                                              <i
                                                className="fa fa-star-o"
                                                data-bind="css: selected() ? 'fa fa-star' : 'fa fa-star-o', click: $parent.starClick"
                                              />
                                              {/*/ko*/}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="review-title">
                                          <label htmlFor="title">Title</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="title"
                                            data-bind="value:subject"
                                            placeholder="Enter your title"
                                          />
                                        </div>
                                        <div className="review-comment">
                                          <label htmlFor="comment">
                                            Comment
                                          </label>
                                          <textarea
                                            name="comment"
                                            data-bind="value:comment"
                                            rows={5}
                                            defaultValue={""}
                                          />
                                        </div>
                                        <div className="review-name">
                                          <label htmlFor="name">Name</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="name"
                                            data-bind="value:name"
                                            placeholder="Enter your name"
                                          />
                                        </div>
                                        <div className="review-email">
                                          <label htmlFor="email">Email</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            name="email"
                                            data-bind="value:email"
                                            placeholder="Enter your email"
                                          />
                                        </div>
                                        <div className="review-submit">
                                          <button
                                            type="button"
                                            className="review-submit-button tbtn btn-solid"
                                            data-bind="click:submitReview"
                                          >
                                            Submit
                                          </button>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                                {/*Adding Partials Review List File*/}
                                <div
                                  className="review-list"
                                  data-bind="with: product"
                                >
                                  <div
                                    className="user-review-list row hidden-xs"
                                    data-bind="foreach: $parent.reviews, visible: total_review > 0"
                                    style={{ display: "none" }}
                                  />
                                  <div className="visible-xs">
                                    <div
                                      className="owl-carousel owl-theme user-review-list row"
                                      data-bind="foreach: $parent.reviews,owlCarousel:{data: $parent.reviews,options: $root.rvCarousel}, visible: total_review > 0"
                                      style={{ display: "none" }}
                                    />
                                  </div>
                                  <p data-bind="visible: total_review == 0">
                                    No review found for this product
                                  </p>
                                </div>
                                {/*Start Pagination*/}
                                <div className="page-link">
                                  {/* ko with: pager()*/}
                                  <div className="clearfix" />
                                  <div
                                    className="product-pagination"
                                    data-bind="visible: hasPage"
                                    style={{ display: "none" }}
                                  >
                                    <ul className="pagination">
                                      <li
                                        data-bind="css:{disabled: !PreviousPageActive()}"
                                        className="disabled"
                                      >
                                        <a data-bind="click: GoToPrevious">
                                          <svg
                                            height={8}
                                            width={7}
                                            viewBox="0 0 490 490"
                                          >
                                            <g>
                                              <path
                                                style={{ fill: "#009688" }}
                                                d="M47.087,245.454L274.223,18.339c4.237-4.093,4.355-10.845,0.262-15.083   c-4.093-4.237-10.845-4.355-15.083-0.262c-0.089,0.086-0.176,0.173-0.262,0.262L24.474,237.923c-4.164,4.165-4.164,10.917,0,15.083   L259.14,487.672c4.237,4.093,10.99,3.975,15.083-0.262c3.993-4.134,3.993-10.687,0-14.821L47.087,245.454z"
                                              />
                                              <path
                                                style={{ fill: "#009688" }}
                                                d="M466.223,3.235c-4.165-4.164-10.917-4.164-15.083,0L216.474,237.902   c-4.164,4.165-4.164,10.917,0,15.083L451.14,487.651c4.237,4.093,10.99,3.976,15.083-0.261c3.993-4.134,3.993-10.688,0-14.821   L239.087,245.454L466.223,18.339c4.171-4.16,4.179-10.914,0.019-15.085C466.236,3.248,466.229,3.241,466.223,3.235z"
                                              />
                                            </g>
                                            <path d="M266.671,490.787c-2.831,0.005-5.548-1.115-7.552-3.115L24.452,253.006c-4.164-4.165-4.164-10.917,0-15.083L259.119,3.256  c4.093-4.237,10.845-4.355,15.083-0.262c4.237,4.093,4.355,10.845,0.262,15.083c-0.086,0.089-0.173,0.176-0.262,0.262  L47.087,245.454l227.136,227.115c4.171,4.16,4.179,10.914,0.019,15.085C272.236,489.664,269.511,490.792,266.671,490.787z" />
                                            <path d="M458.671,490.787c-2.831,0.005-5.548-1.115-7.552-3.115L216.452,253.006c-4.164-4.165-4.164-10.917,0-15.083L451.119,3.256  c4.093-4.237,10.845-4.354,15.083-0.262c4.237,4.093,4.354,10.845,0.262,15.083c-0.086,0.089-0.173,0.176-0.262,0.262  L239.087,245.454l227.136,227.115c4.171,4.16,4.179,10.914,0.019,15.085C464.236,489.664,461.511,490.792,458.671,490.787z" />
                                          </svg>
                                          <span className="prev">
                                            &nbsp;Previous
                                          </span>
                                        </a>
                                      </li>
                                      {/* ko foreach: GetPages() */}
                                      {/* /ko */}
                                      <li
                                        data-bind="css:{disabled: !NextPageActive()}"
                                        className="disabled"
                                      >
                                        <a data-bind="click: GoToNext">
                                          <span className="next">
                                            Next&nbsp;
                                          </span>
                                          <svg
                                            height={8}
                                            width={7}
                                            viewBox="0 0 490 490"
                                          >
                                            <g>
                                              <path
                                                style={{ fill: "#009688" }}
                                                d="M231.696,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82   l227.115,227.136L216.592,472.461c-4.237,4.093-4.355,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262   c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L231.696,3.128z"
                                              />
                                              <path
                                                style={{ fill: "#009688" }}
                                                d="M274.363,237.795L39.696,3.128c-4.237-4.093-10.99-3.975-15.083,0.262   c-3.992,4.134-3.992,10.687,0,14.821l227.115,227.136L24.592,472.461c-4.237,4.093-4.354,10.845-0.262,15.083   c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667   C278.511,248.718,278.521,241.966,274.363,237.795z"
                                              />
                                            </g>
                                            <path d="M224.144,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115  L216.592,18.232c-4.093-4.237-3.975-10.99,0.262-15.083c4.134-3.992,10.687-3.992,14.82,0l234.667,234.667  c4.164,4.165,4.164,10.917,0,15.083L231.675,487.565C229.676,489.56,226.968,490.68,224.144,490.68z" />
                                            <path d="M32.144,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115  L24.592,18.232c-4.171-4.171-4.171-10.933,0-15.104s10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083  L39.696,487.544C37.695,489.551,34.978,490.68,32.144,490.68z" />
                                          </svg>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="clearfix" />
                                  {/* /ko */}
                                </div>
                                {/*End Pagination*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="product-info-desktop hidden-xs">
            <div className="content-wrapper">
              <div className="product-info-header">
                <ul className="nav nav-pills">
                  <li className="active">
                    <a data-toggle="pill" href="#PDtab1">
                      Description
                    </a>
                    <span />
                  </li>
                  <li className="">
                    <a data-toggle="pill" href="#PDtab2">
                      Shipping Info
                    </a>
                  </li>
                  <li className="">
                    <a data-toggle="pill" href="#PDtab3">
                      Review
                    </a>
                  </li>
                </ul>
              </div>
              <div
                className="product-info-content"
                data-bind="with: $root.parentProduct"
              >
                <div className="tab-content">
                  <div id="PDtab1" className="tab-pane fade active in">
                    {/* ko if: description !="" || description !=null */}
                    <div data-bind="html: description">
                      <p>{detaills[0].description}</p>
                      {/* <p>
                        <strong>Uwell Caliburn GZ2 Pod System Features:</strong>
                        <br />
                        • Dimensions: 68.9mm by 23.8mm by 23.6mm
                        <br />
                        • Battery Capacity: 850mAh
                        <br />
                        • Max Wattage: 17W
                        <br />
                        • Voltage Range: 0.8-1.0ohm
                        <br />
                        • Chassis Material: Aluminum-Alloy
                        <br />
                        • Charging: Type-C Port
                        <br />
                        • Operation: Draw-Activated
                        <br />
                        • Pod Series: Caliburn G2 Pods
                        <br />
                        • Pod Capacity: 2ml
                        <br />
                        • Fill-System: Top Fill System
                        <br />• Coil Support: Caliburn G Coils
                      </p>
                      <p>
                        <strong>Package Includes:</strong>
                        <br />
                        • 1 GZ2 Device
                        <br />
                        • 2 Caliburn G2 Pods
                        <br />
                        • 1 0.8ohm Caliburn Coils
                        <br />
                        • 1 1.2ohm Caliburn G2 Coils
                        <br />
                        • 1 USB-C Cable
                        <br />• 1 User Manual
                      </p> */}
                    </div>
                  </div>
                  <div id="PDtab2" className="tab-pane fade">
                    {/* ko if: product_shipping_info !="" || product_shipping_info !=null */}
                    <p data-bind="html: product_shipping_info" />
                    <p>
                      Inside Dhaka City: BDT100&nbsp;
                      <br />
                      ⏱️24hrs after the confirmation call
                    </p>
                    <p>
                      Outside Dhaka City: BDT150&nbsp;
                      <br />
                      ⏱️48-72hrs after the confirmation call&nbsp;
                      <u>
                        <strong>(100% payment required in advance)</strong>
                      </u>
                    </p>
                    <p>
                      <br />
                      <strong>FREE delivery on orders above BDT3000‼️</strong>
                    </p>
                    <p />
                    {/* /ko */}
                    {/* ko if: product_shipping_info == null || product_shipping_info =="" */}
                    {/* /ko */}
                  </div>
                  <div id="PDtab3" className="tab-pane fade">
                    <div className="customer-review" id="customer-review">
                      <div data-bind="with:$root.reviewVM">
                        <div className="review-section">
                          <h2>Customer Reviews</h2>
                          <div
                            className="review-stars"
                            data-bind="with:product"
                          >
                            <span className="rating">
                              <i
                                className="fa fa-star-o"
                                data-bind="css: $root.parentProduct().total_rating > 0.5 ? 'fa fa-star' : 'fa fa-star-o'"
                              />
                              <i
                                className="fa fa-star-o"
                                data-bind="css: $root.parentProduct().total_rating > 1.5 ? 'fa fa-star' : 'fa fa-star-o'"
                              />
                              <i
                                className="fa fa-star-o"
                                data-bind="css: $root.parentProduct().total_rating > 2.5 ? 'fa fa-star' : 'fa fa-star-o'"
                              />
                              <i
                                className="fa fa-star-o"
                                data-bind="css: $root.parentProduct().total_rating > 3.5 ? 'fa fa-star' : 'fa fa-star-o'"
                              />
                              <i
                                className="fa fa-star-o"
                                data-bind="css: $root.parentProduct().total_rating > 4.5 ? 'fa fa-star' : 'fa fa-star-o'"
                              />
                            </span>
                            <span className="review-caption">
                              <span className="toggle-reviews">
                                Based on{" "}
                                <span data-bind="text: $root.reviewVM().review_count">
                                  0
                                </span>{" "}
                                review
                              </span>
                            </span>
                          </div>
                          <div className="review-desktop">
                            <div className="review-header">
                              <h3 className="title">
                                Let us know your opinion
                              </h3>
                            </div>
                            <div id="new-review">
                              <form
                                className="review-form check-review-data"
                                id="check-review-data-desktop"
                                method="POST"
                              >
                                <div className="rating-star">
                                  <label htmlFor="rating">Rating</label>
                                  <div className="stars">
                                    <span>
                                      {/*ko foreach: stars */}
                                      <i
                                        className="fa fa-star"
                                        data-bind="css: selected() ? 'fa fa-star' : 'fa fa-star-o', click: $parent.starClick"
                                      />
                                      <i
                                        className="fa fa-star-o"
                                        data-bind="css: selected() ? 'fa fa-star' : 'fa fa-star-o', click: $parent.starClick"
                                      />
                                      <i
                                        className="fa fa-star-o"
                                        data-bind="css: selected() ? 'fa fa-star' : 'fa fa-star-o', click: $parent.starClick"
                                      />
                                      <i
                                        className="fa fa-star-o"
                                        data-bind="css: selected() ? 'fa fa-star' : 'fa fa-star-o', click: $parent.starClick"
                                      />
                                      <i
                                        className="fa fa-star-o"
                                        data-bind="css: selected() ? 'fa fa-star' : 'fa fa-star-o', click: $parent.starClick"
                                      />
                                      {/*/ko*/}
                                    </span>
                                  </div>
                                </div>
                                <div className="review-title">
                                  <label htmlFor="title">Title</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="title"
                                    data-bind="value:subject"
                                    placeholder="Enter your title"
                                  />
                                </div>
                                <div className="review-comment">
                                  <label htmlFor="comment">Comment</label>
                                  <textarea
                                    name="comment"
                                    data-bind="value:comment"
                                    rows={5}
                                    defaultValue={""}
                                  />
                                </div>
                                <div className="review-name">
                                  <label htmlFor="name">Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    data-bind="value:name"
                                    placeholder="Enter your name"
                                  />
                                </div>
                                <div className="review-email">
                                  <label htmlFor="email">Email</label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    data-bind="value:email"
                                    placeholder="Enter your email"
                                  />
                                </div>
                                <div className="review-submit">
                                  <button
                                    type="button"
                                    className="review-submit-button tbtn btn-solid"
                                    data-bind="click:submitReview"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        {/*Adding Partials Review List File*/}
                        <div className="review-list" data-bind="with: product">
                          <div
                            className="user-review-list row hidden-xs"
                            data-bind="foreach: $parent.reviews, visible: total_review > 0"
                            style={{ display: "none" }}
                          />
                          <div className="visible-xs">
                            <div
                              className="owl-carousel owl-theme user-review-list row"
                              data-bind="foreach: $parent.reviews,owlCarousel:{data: $parent.reviews,options: $root.rvCarousel}, visible: total_review > 0"
                              style={{ display: "none" }}
                            />
                          </div>
                          <p data-bind="visible: total_review == 0">
                            No review found for this product
                          </p>
                        </div>
                        {/*Start Pagination*/}
                        <div className="page-link">
                          {/* ko with: pager()*/}
                          <div className="clearfix" />
                          <div
                            className="product-pagination"
                            data-bind="visible: hasPage"
                            style={{ display: "none" }}
                          >
                            <ul className="pagination">
                              <li
                                data-bind="css:{disabled: !PreviousPageActive()}"
                                className="disabled"
                              >
                                <a data-bind="click: GoToPrevious">
                                  <svg
                                    height={8}
                                    width={7}
                                    viewBox="0 0 490 490"
                                  >
                                    <g>
                                      <path
                                        style={{ fill: "#009688" }}
                                        d="M47.087,245.454L274.223,18.339c4.237-4.093,4.355-10.845,0.262-15.083   c-4.093-4.237-10.845-4.355-15.083-0.262c-0.089,0.086-0.176,0.173-0.262,0.262L24.474,237.923c-4.164,4.165-4.164,10.917,0,15.083   L259.14,487.672c4.237,4.093,10.99,3.975,15.083-0.262c3.993-4.134,3.993-10.687,0-14.821L47.087,245.454z"
                                      />
                                      <path
                                        style={{ fill: "#009688" }}
                                        d="M466.223,3.235c-4.165-4.164-10.917-4.164-15.083,0L216.474,237.902   c-4.164,4.165-4.164,10.917,0,15.083L451.14,487.651c4.237,4.093,10.99,3.976,15.083-0.261c3.993-4.134,3.993-10.688,0-14.821   L239.087,245.454L466.223,18.339c4.171-4.16,4.179-10.914,0.019-15.085C466.236,3.248,466.229,3.241,466.223,3.235z"
                                      />
                                    </g>
                                    <path d="M266.671,490.787c-2.831,0.005-5.548-1.115-7.552-3.115L24.452,253.006c-4.164-4.165-4.164-10.917,0-15.083L259.119,3.256  c4.093-4.237,10.845-4.355,15.083-0.262c4.237,4.093,4.355,10.845,0.262,15.083c-0.086,0.089-0.173,0.176-0.262,0.262  L47.087,245.454l227.136,227.115c4.171,4.16,4.179,10.914,0.019,15.085C272.236,489.664,269.511,490.792,266.671,490.787z" />
                                    <path d="M458.671,490.787c-2.831,0.005-5.548-1.115-7.552-3.115L216.452,253.006c-4.164-4.165-4.164-10.917,0-15.083L451.119,3.256  c4.093-4.237,10.845-4.354,15.083-0.262c4.237,4.093,4.354,10.845,0.262,15.083c-0.086,0.089-0.173,0.176-0.262,0.262  L239.087,245.454l227.136,227.115c4.171,4.16,4.179,10.914,0.019,15.085C464.236,489.664,461.511,490.792,458.671,490.787z" />
                                  </svg>
                                  <span className="prev">&nbsp;Previous</span>
                                </a>
                              </li>
                              {/* ko foreach: GetPages() */}
                              {/* /ko */}
                              <li
                                data-bind="css:{disabled: !NextPageActive()}"
                                className="disabled"
                              >
                                <a data-bind="click: GoToNext">
                                  <span className="next">Next&nbsp;</span>
                                  <svg
                                    height={8}
                                    width={7}
                                    viewBox="0 0 490 490"
                                  >
                                    <g>
                                      <path
                                        style={{ fill: "#009688" }}
                                        d="M231.696,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82   l227.115,227.136L216.592,472.461c-4.237,4.093-4.355,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262   c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L231.696,3.128z"
                                      />
                                      <path
                                        style={{ fill: "#009688" }}
                                        d="M274.363,237.795L39.696,3.128c-4.237-4.093-10.99-3.975-15.083,0.262   c-3.992,4.134-3.992,10.687,0,14.821l227.115,227.136L24.592,472.461c-4.237,4.093-4.354,10.845-0.262,15.083   c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667   C278.511,248.718,278.521,241.966,274.363,237.795z"
                                      />
                                    </g>
                                    <path d="M224.144,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115  L216.592,18.232c-4.093-4.237-3.975-10.99,0.262-15.083c4.134-3.992,10.687-3.992,14.82,0l234.667,234.667  c4.164,4.165,4.164,10.917,0,15.083L231.675,487.565C229.676,489.56,226.968,490.68,224.144,490.68z" />
                                    <path d="M32.144,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115  L24.592,18.232c-4.171-4.171-4.171-10.933,0-15.104s10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083  L39.696,487.544C37.695,489.551,34.978,490.68,32.144,490.68z" />
                                  </svg>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="clearfix" />
                          {/* /ko */}
                        </div>
                        {/*End Pagination*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="related-products"
            data-bind="visible: $root.relatedProducts().length > 0"
            style={{}}
          >
            <div className="content-wrapper">
              <div className="related-products-header text-center">
                <h4 className="text-capitalize">related products</h4>
              </div>
              <div className="related-products-content">
                <div
                  className="related-product-slider slick-initialized slick-slider"
                  data-bind="foreach: $root.relatedProducts,slickSlider:{data: $root.relatedProducts,options: $root.rpCarousel}"
                >
                  <button
                    className="slick-prev slick-arrow slick-disabled"
                    aria-label="Previous"
                    type="button"
                    aria-disabled="true"
                    style={{}}
                  >
                    Previous
                  </button>
                  <div className="slick-list draggable">
                    <div
                      className="slick-track"
                      style={{
                        opacity: 1,
                        width: 2810,
                        transform: "translate3d(0px, 0px, 0px)",
                      }}
                    >
                      <div
                        className="slick-slide slick-current slick-active"
                        data-slick-index={0}
                        aria-hidden="false"
                        style={{ width: 261 }}
                      >
                        <div>
                          <div
                            className="product-box"
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <div
                              data-bind="attr:{href:url}"
                              className="photo"
                              href="/product/lost-vape-thelema-solo-100w-starter-kit"
                            >
                              <img
                                className="front-img"
                                width={1000}
                                height={1000}
                                data-bind="attr:{src: featured_image_urls.original},alt:name"
                                src="https://dtt1c9id3txwq.cloudfront.net/assets/images/001/496/530/original/Thelema-Solo-Kit.jpg?1711372443"
                              />

                              <a
                                className="photo-link"
                                data-bind="attr:{href:url}"
                                href="/product/lost-vape-thelema-solo-100w-starter-kit"
                                tabIndex={0}
                              />
                              <div className="tag">
                                <span
                                  className="new"
                                  data-bind="visible:is_new"
                                  style={{ display: "none" }}
                                >
                                  new
                                </span>
                                <span
                                  className="sale"
                                  data-bind="visible: on_sale == true && sale_percentage != null,text: '-' + sale_percentage + '%'"
                                  style={{ display: "none" }}
                                >
                                  -null%
                                </span>
                              </div>
                              <div className="icon-group">
                                {/*ko if:$root.isWishlistPage == false  */}
                                <div
                                  className="wishlist"
                                  data-bind="attr:{id:'pid'+ id},click:$root.wishlistVM.addToWishList"
                                  id="pid327328"
                                >
                                  <span className="icon">
                                    <svg viewBox="0 0 512 512">
                                      <g>
                                        <g>
                                          <path
                                            d="M474.644,74.27C449.391,45.616,414.358,29.836,376,29.836c-53.948,0-88.103,32.22-107.255,59.25
                                c-4.969,7.014-9.196,14.047-12.745,20.665c-3.549-6.618-7.775-13.651-12.745-20.665c-19.152-27.03-53.307-59.25-107.255-59.25
                                c-38.358,0-73.391,15.781-98.645,44.435C13.267,101.605,0,138.213,0,177.351c0,42.603,16.633,82.228,52.345,124.7
                                c31.917,37.96,77.834,77.088,131.005,122.397c19.813,16.884,40.302,34.344,62.115,53.429l0.655,0.574
                                c2.828,2.476,6.354,3.713,9.88,3.713s7.052-1.238,9.88-3.713l0.655-0.574c21.813-19.085,42.302-36.544,62.118-53.431
                                c53.168-45.306,99.085-84.434,131.002-122.395C495.367,259.578,512,219.954,512,177.351
                                C512,138.213,498.733,101.605,474.644,74.27z M309.193,401.614c-17.08,14.554-34.658,29.533-53.193,45.646
                                c-18.534-16.111-36.113-31.091-53.196-45.648C98.745,312.939,30,254.358,30,177.351c0-31.83,10.605-61.394,29.862-83.245
                                C79.34,72.007,106.379,59.836,136,59.836c41.129,0,67.716,25.338,82.776,46.594c13.509,19.064,20.558,38.282,22.962,45.659
                                c2.011,6.175,7.768,10.354,14.262,10.354c6.494,0,12.251-4.179,14.262-10.354c2.404-7.377,9.453-26.595,22.962-45.66
                                c15.06-21.255,41.647-46.593,82.776-46.593c29.621,0,56.66,12.171,76.137,34.27C471.395,115.957,482,145.521,482,177.351
                                C482,254.358,413.255,312.939,309.193,401.614z"
                                          />
                                        </g>
                                      </g>
                                    </svg>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="action-buttons">
                              <div
                                className="quickview"
                                data-bind="click:$root.quickViewClick"
                              >
                                <span className="text">Quick view</span>
                                <span className="icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 511.999 511.999"
                                  >
                                    <path
                                      d="M508.745,246.041c-4.574-6.257-113.557-153.206-252.748-153.206S7.818,239.784,3.249,246.035
                    c-4.332,5.936-4.332,13.987,0,19.923c4.569,6.257,113.557,153.206,252.748,153.206s248.174-146.95,252.748-153.201
                    C513.083,260.028,513.083,251.971,508.745,246.041z M255.997,385.406c-102.529,0-191.33-97.533-217.617-129.418
                    c26.253-31.913,114.868-129.395,217.617-129.395c102.524,0,191.319,97.516,217.617,129.418
                    C447.361,287.923,358.746,385.406,255.997,385.406z"
                                    />
                                    <path
                                      d="M255.997,154.725c-55.842,0-101.275,45.433-101.275,101.275s45.433,101.275,101.275,101.275
                    s101.275-45.433,101.275-101.275S311.839,154.725,255.997,154.725z M255.997,323.516c-37.23,0-67.516-30.287-67.516-67.516
                    s30.287-67.516,67.516-67.516s67.516,30.287,67.516,67.516S293.227,323.516,255.997,323.516z"
                                    ></path>
                                  </svg>
                                </span>
                              </div>
                              {/*ko if: has_variants == false && ((stock_control == true && stock > 0) || stock_control == false) */}
                              {/*/ko*/}
                              {/*ko if: has_variants && ((stock_control == true && stock > 0) || stock_control == false) */}
                              {/*/ko*/}
                              {/*ko if: (stock_control == true || stock_control == false ) && stock < 1 */}
                              <div
                                className="addtocart"
                                data-bind="click:$root.cartVM.addoneToCart"
                              >
                                <span className="text">Out of stock</span>
                                <span className="icon">
                                  <i className="fa fa-ban" />
                                </span>
                              </div>
                              {/*/ko*/}
                            </div>
                            <div className="content">
                              <div className="name">
                                <a
                                  data-bind="attr:{href:url},text:name"
                                  className="truncate"
                                  href="/product/lost-vape-thelema-solo-100w-starter-kit"
                                  tabIndex={0}
                                >
                                  LOST VAPE THELEMA SOLO 100W STARTER KIT
                                </a>
                              </div>
                              <div className="price">
                                {/* ko if: call_for_price == true */}
                                {/*/ko*/}
                                {/* ko if: call_for_price == false || call_for_price == null */}
                                <s
                                  className="old-price"
                                  data-bind="visible: compare_at_price !=null,text: $root.currency + ' ' + compare_at_price"
                                  style={{ display: "none" }}
                                >
                                  BDT null
                                </s>
                                <span
                                  className="regular-price"
                                  data-bind='text: $root.currency + " " + price'
                                >
                                  BDT 6,000.00
                                </span>
                                {/*/ko*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="slick-slide slick-active"
                        data-slick-index={1}
                        aria-hidden="false"
                        style={{ width: 261 }}
                      >
                        <div>
                          <div
                            className="product-box"
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <div
                              data-bind="attr:{href:url}"
                              className="photo"
                              href="/product/dovape-diceysaint-le-lite-40w-pod-system"
                            >
                              {/* ko if: featured_image_urls != null && second_image_urls != null */}
                              <img
                                className="front-img"
                                width={1000}
                                height={1000}
                                data-bind="attr:{src: featured_image_urls.original},alt:name"
                                src="https://dtt1c9id3txwq.cloudfront.net/assets/images/001/496/478/original/Diceysaint-Pod.jpg?1711368757"
                              />
                              <img
                                className="back-img"
                                width={1000}
                                height={1000}
                                data-bind="attr:{src: second_image_urls.original},alt:name"
                                src="https://dtt1c9id3txwq.cloudfront.net/assets/images/001/496/479/original/Diceysaint-Pod-2.jpg?1711368760"
                              />

                              <a
                                className="photo-link"
                                data-bind="attr:{href:url}"
                                href="/product/dovape-diceysaint-le-lite-40w-pod-system"
                                tabIndex={0}
                              />
                              <div className="tag">
                                <span
                                  className="new"
                                  data-bind="visible:is_new"
                                >
                                  new
                                </span>
                                <span
                                  className="sale"
                                  data-bind="visible: on_sale == true && sale_percentage != null,text: '-' + sale_percentage + '%'"
                                  style={{ display: "none" }}
                                >
                                  -null%
                                </span>
                              </div>
                              <div className="icon-group">
                                <div
                                  className="wishlist"
                                  data-bind="attr:{id:'pid'+ id},click:$root.wishlistVM.addToWishList"
                                  id="pid377860"
                                >
                                  <span className="icon">
                                    <svg viewBox="0 0 512 512">
                                      <g>
                                        <g>
                                          <path
                                            d="M474.644,74.27C449.391,45.616,414.358,29.836,376,29.836c-53.948,0-88.103,32.22-107.255,59.25
                                c-4.969,7.014-9.196,14.047-12.745,20.665c-3.549-6.618-7.775-13.651-12.745-20.665c-19.152-27.03-53.307-59.25-107.255-59.25
                                c-38.358,0-73.391,15.781-98.645,44.435C13.267,101.605,0,138.213,0,177.351c0,42.603,16.633,82.228,52.345,124.7
                                c31.917,37.96,77.834,77.088,131.005,122.397c19.813,16.884,40.302,34.344,62.115,53.429l0.655,0.574
                                c2.828,2.476,6.354,3.713,9.88,3.713s7.052-1.238,9.88-3.713l0.655-0.574c21.813-19.085,42.302-36.544,62.118-53.431
                                c53.168-45.306,99.085-84.434,131.002-122.395C495.367,259.578,512,219.954,512,177.351
                                C512,138.213,498.733,101.605,474.644,74.27z M309.193,401.614c-17.08,14.554-34.658,29.533-53.193,45.646
                                c-18.534-16.111-36.113-31.091-53.196-45.648C98.745,312.939,30,254.358,30,177.351c0-31.83,10.605-61.394,29.862-83.245
                                C79.34,72.007,106.379,59.836,136,59.836c41.129,0,67.716,25.338,82.776,46.594c13.509,19.064,20.558,38.282,22.962,45.659
                                c2.011,6.175,7.768,10.354,14.262,10.354c6.494,0,12.251-4.179,14.262-10.354c2.404-7.377,9.453-26.595,22.962-45.66
                                c15.06-21.255,41.647-46.593,82.776-46.593c29.621,0,56.66,12.171,76.137,34.27C471.395,115.957,482,145.521,482,177.351
                                C482,254.358,413.255,312.939,309.193,401.614z"
                                          />
                                        </g>
                                      </g>
                                    </svg>
                                  </span>
                                </div>
                                {/* /ko */}
                                {/*ko if:$root.isWishlistPage == true  */}
                                {/* /ko */}
                              </div>
                            </div>
                            <div className="action-buttons">
                              <div
                                className="quickview"
                                data-bind="click:$root.quickViewClick"
                              >
                                <span className="text">Quick view</span>
                                <span className="icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 511.999 511.999"
                                  >
                                    <path
                                      d="M508.745,246.041c-4.574-6.257-113.557-153.206-252.748-153.206S7.818,239.784,3.249,246.035
                    c-4.332,5.936-4.332,13.987,0,19.923c4.569,6.257,113.557,153.206,252.748,153.206s248.174-146.95,252.748-153.201
                    C513.083,260.028,513.083,251.971,508.745,246.041z M255.997,385.406c-102.529,0-191.33-97.533-217.617-129.418
                    c26.253-31.913,114.868-129.395,217.617-129.395c102.524,0,191.319,97.516,217.617,129.418
                    C447.361,287.923,358.746,385.406,255.997,385.406z"
                                    />
                                    <path
                                      d="M255.997,154.725c-55.842,0-101.275,45.433-101.275,101.275s45.433,101.275,101.275,101.275
                    s101.275-45.433,101.275-101.275S311.839,154.725,255.997,154.725z M255.997,323.516c-37.23,0-67.516-30.287-67.516-67.516
                    s30.287-67.516,67.516-67.516s67.516,30.287,67.516,67.516S293.227,323.516,255.997,323.516z"
                                    ></path>
                                  </svg>
                                </span>
                              </div>
                              {/*ko if: has_variants == false && ((stock_control == true && stock > 0) || stock_control == false) */}
                              {/*/ko*/}
                              {/*ko if: has_variants && ((stock_control == true && stock > 0) || stock_control == false) */}
                              <div className="addtocart">
                                <a
                                  data-bind="attr:{href:url}"
                                  href="/product/dovape-diceysaint-le-lite-40w-pod-system"
                                  tabIndex={0}
                                >
                                  <span className="text">View Details</span>
                                  <span className="icon">
                                    <i className="fa fa-long-arrow-right" />
                                  </span>
                                </a>
                              </div>
                              {/*/ko*/}
                              {/*ko if: (stock_control == true || stock_control == false ) && stock < 1 */}
                              {/*/ko*/}
                            </div>
                            <div className="content">
                              <div className="name">
                                <a
                                  data-bind="attr:{href:url},text:name"
                                  className="truncate"
                                  href="/product/dovape-diceysaint-le-lite-40w-pod-system"
                                  tabIndex={0}
                                >
                                  DOVAPE DICEYSAINT LE LITE 40W POD SYSTEM
                                </a>
                              </div>
                              <div className="price">
                                {/* ko if: call_for_price == true */}
                                {/*/ko*/}
                                {/* ko if: call_for_price == false || call_for_price == null */}
                                <s
                                  className="old-price"
                                  data-bind="visible: compare_at_price !=null,text: $root.currency + ' ' + compare_at_price"
                                  style={{ display: "none" }}
                                >
                                  BDT null
                                </s>
                                <span
                                  className="regular-price"
                                  data-bind='text: $root.currency + " " + price'
                                >
                                  BDT 2,500.00
                                </span>
                                {/*/ko*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="slick-slide slick-active"
                        data-slick-index={2}
                        aria-hidden="false"
                        style={{ width: 261 }}
                      >
                        <div>
                          <div
                            className="product-box"
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <div
                              data-bind="attr:{href:url}"
                              className="photo"
                              href="/product/uwell-caliburn-tenet-koko-pod-system"
                            >
                              {/* ko if: featured_image_urls != null && second_image_urls != null */}
                              {/* /ko */}
                              {/* ko if: featured_image_urls != null && second_image_urls == null */}
                              <img
                                className="front-img"
                                width={1000}
                                height={1000}
                                data-bind="attr:{src: featured_image_urls.original},alt:name"
                                src="https://dtt1c9id3txwq.cloudfront.net/assets/images/001/496/472/original/Caliburn-Tenet-Koko.jpg?1711368676"
                              />
                              {/* /ko */}
                              {/* ko if: featured_image_urls == null && second_image_urls != null */}
                              {/* /ko */}
                              {/* ko if: featured_image_urls == null && second_image_urls == null */}
                              {/* /ko */}
                              <a
                                className="photo-link"
                                data-bind="attr:{href:url}"
                                href="/product/uwell-caliburn-tenet-koko-pod-system"
                                tabIndex={0}
                              />
                              <div className="tag">
                                <span
                                  className="new"
                                  data-bind="visible:is_new"
                                  style={{ display: "none" }}
                                >
                                  new
                                </span>
                                <span
                                  className="sale"
                                  data-bind="visible: on_sale == true && sale_percentage != null,text: '-' + sale_percentage + '%'"
                                  style={{ display: "none" }}
                                >
                                  -null%
                                </span>
                              </div>
                              <div className="icon-group">
                                {/*ko if:$root.isWishlistPage == false  */}
                                <div
                                  className="wishlist"
                                  data-bind="attr:{id:'pid'+ id},click:$root.wishlistVM.addToWishList"
                                  id="pid391940"
                                >
                                  <span className="icon">
                                    <svg viewBox="0 0 512 512">
                                      <g>
                                        <g>
                                          <path
                                            d="M474.644,74.27C449.391,45.616,414.358,29.836,376,29.836c-53.948,0-88.103,32.22-107.255,59.25
                                c-4.969,7.014-9.196,14.047-12.745,20.665c-3.549-6.618-7.775-13.651-12.745-20.665c-19.152-27.03-53.307-59.25-107.255-59.25
                                c-38.358,0-73.391,15.781-98.645,44.435C13.267,101.605,0,138.213,0,177.351c0,42.603,16.633,82.228,52.345,124.7
                                c31.917,37.96,77.834,77.088,131.005,122.397c19.813,16.884,40.302,34.344,62.115,53.429l0.655,0.574
                                c2.828,2.476,6.354,3.713,9.88,3.713s7.052-1.238,9.88-3.713l0.655-0.574c21.813-19.085,42.302-36.544,62.118-53.431
                                c53.168-45.306,99.085-84.434,131.002-122.395C495.367,259.578,512,219.954,512,177.351
                                C512,138.213,498.733,101.605,474.644,74.27z M309.193,401.614c-17.08,14.554-34.658,29.533-53.193,45.646
                                c-18.534-16.111-36.113-31.091-53.196-45.648C98.745,312.939,30,254.358,30,177.351c0-31.83,10.605-61.394,29.862-83.245
                                C79.34,72.007,106.379,59.836,136,59.836c41.129,0,67.716,25.338,82.776,46.594c13.509,19.064,20.558,38.282,22.962,45.659
                                c2.011,6.175,7.768,10.354,14.262,10.354c6.494,0,12.251-4.179,14.262-10.354c2.404-7.377,9.453-26.595,22.962-45.66
                                c15.06-21.255,41.647-46.593,82.776-46.593c29.621,0,56.66,12.171,76.137,34.27C471.395,115.957,482,145.521,482,177.351
                                C482,254.358,413.255,312.939,309.193,401.614z"
                                          />
                                        </g>
                                      </g>
                                    </svg>
                                  </span>
                                </div>
                                {/* /ko */}
                                {/*ko if:$root.isWishlistPage == true  */}
                                {/* /ko */}
                              </div>
                            </div>
                            <div className="action-buttons">
                              <div
                                className="quickview"
                                data-bind="click:$root.quickViewClick"
                              >
                                <span className="text">Quick view</span>
                                <span className="icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 511.999 511.999"
                                  >
                                    <path
                                      d="M508.745,246.041c-4.574-6.257-113.557-153.206-252.748-153.206S7.818,239.784,3.249,246.035
                    c-4.332,5.936-4.332,13.987,0,19.923c4.569,6.257,113.557,153.206,252.748,153.206s248.174-146.95,252.748-153.201
                    C513.083,260.028,513.083,251.971,508.745,246.041z M255.997,385.406c-102.529,0-191.33-97.533-217.617-129.418
                    c26.253-31.913,114.868-129.395,217.617-129.395c102.524,0,191.319,97.516,217.617,129.418
                    C447.361,287.923,358.746,385.406,255.997,385.406z"
                                    />
                                    <path
                                      d="M255.997,154.725c-55.842,0-101.275,45.433-101.275,101.275s45.433,101.275,101.275,101.275
                    s101.275-45.433,101.275-101.275S311.839,154.725,255.997,154.725z M255.997,323.516c-37.23,0-67.516-30.287-67.516-67.516
                    s30.287-67.516,67.516-67.516s67.516,30.287,67.516,67.516S293.227,323.516,255.997,323.516z"
                                    ></path>
                                  </svg>
                                </span>
                              </div>
                              {/*ko if: has_variants == false && ((stock_control == true && stock > 0) || stock_control == false) */}
                              {/*/ko*/}
                              {/*ko if: has_variants && ((stock_control == true && stock > 0) || stock_control == false) */}
                              <div className="addtocart">
                                <a
                                  data-bind="attr:{href:url}"
                                  href="/product/uwell-caliburn-tenet-koko-pod-system"
                                  tabIndex={0}
                                >
                                  <span className="text">View Details</span>
                                  <span className="icon">
                                    <i className="fa fa-long-arrow-right" />
                                  </span>
                                </a>
                              </div>
                              {/*/ko*/}
                              {/*ko if: (stock_control == true || stock_control == false ) && stock < 1 */}
                              {/*/ko*/}
                            </div>
                            <div className="content">
                              <div className="name">
                                <a
                                  data-bind="attr:{href:url},text:name"
                                  className="truncate"
                                  href="/product/uwell-caliburn-tenet-koko-pod-system"
                                  tabIndex={0}
                                >
                                  UWELL CALIBURN TENET KOKO POD SYSTEM
                                </a>
                              </div>
                              <div className="price">
                                {/* ko if: call_for_price == true */}
                                {/*/ko*/}
                                {/* ko if: call_for_price == false || call_for_price == null */}
                                <s
                                  className="old-price"
                                  data-bind="visible: compare_at_price !=null,text: $root.currency + ' ' + compare_at_price"
                                  style={{ display: "none" }}
                                >
                                  BDT null
                                </s>
                                <span
                                  className="regular-price"
                                  data-bind='text: $root.currency + " " + price'
                                >
                                  BDT 3,400.00
                                </span>
                                {/*/ko*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="slick-slide slick-active"
                        data-slick-index={3}
                        aria-hidden="false"
                        style={{ width: 261 }}
                      >
                        <div>
                          <div
                            className="product-box"
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <div
                              data-bind="attr:{href:url}"
                              className="photo"
                              href="/product/uwell-caliburn-tenet-pod-system"
                            >
                              {/* ko if: featured_image_urls != null && second_image_urls != null */}
                              {/* /ko */}
                              {/* ko if: featured_image_urls != null && second_image_urls == null */}
                              <img
                                className="front-img"
                                width={1000}
                                height={1000}
                                data-bind="attr:{src: featured_image_urls.original},alt:name"
                                src="https://dtt1c9id3txwq.cloudfront.net/assets/images/001/496/471/original/Caliburn-Tenet.jpg?1711368662"
                              />
                              {/* /ko */}
                              {/* ko if: featured_image_urls == null && second_image_urls != null */}
                              {/* /ko */}
                              {/* ko if: featured_image_urls == null && second_image_urls == null */}
                              {/* /ko */}
                              <a
                                className="photo-link"
                                data-bind="attr:{href:url}"
                                href="/product/uwell-caliburn-tenet-pod-system"
                                tabIndex={0}
                              />
                              <div className="tag">
                                <span
                                  className="new"
                                  data-bind="visible:is_new"
                                  style={{ display: "none" }}
                                >
                                  new
                                </span>
                                <span
                                  className="sale"
                                  data-bind="visible: on_sale == true && sale_percentage != null,text: '-' + sale_percentage + '%'"
                                  style={{ display: "none" }}
                                >
                                  -null%
                                </span>
                              </div>
                              <div className="icon-group">
                                {/*ko if:$root.isWishlistPage == false  */}
                                <div
                                  className="wishlist"
                                  data-bind="attr:{id:'pid'+ id},click:$root.wishlistVM.addToWishList"
                                  id="pid391943"
                                >
                                  <span className="icon">
                                    <svg viewBox="0 0 512 512">
                                      <g>
                                        <g>
                                          <path
                                            d="M474.644,74.27C449.391,45.616,414.358,29.836,376,29.836c-53.948,0-88.103,32.22-107.255,59.25
                                c-4.969,7.014-9.196,14.047-12.745,20.665c-3.549-6.618-7.775-13.651-12.745-20.665c-19.152-27.03-53.307-59.25-107.255-59.25
                                c-38.358,0-73.391,15.781-98.645,44.435C13.267,101.605,0,138.213,0,177.351c0,42.603,16.633,82.228,52.345,124.7
                                c31.917,37.96,77.834,77.088,131.005,122.397c19.813,16.884,40.302,34.344,62.115,53.429l0.655,0.574
                                c2.828,2.476,6.354,3.713,9.88,3.713s7.052-1.238,9.88-3.713l0.655-0.574c21.813-19.085,42.302-36.544,62.118-53.431
                                c53.168-45.306,99.085-84.434,131.002-122.395C495.367,259.578,512,219.954,512,177.351
                                C512,138.213,498.733,101.605,474.644,74.27z M309.193,401.614c-17.08,14.554-34.658,29.533-53.193,45.646
                                c-18.534-16.111-36.113-31.091-53.196-45.648C98.745,312.939,30,254.358,30,177.351c0-31.83,10.605-61.394,29.862-83.245
                                C79.34,72.007,106.379,59.836,136,59.836c41.129,0,67.716,25.338,82.776,46.594c13.509,19.064,20.558,38.282,22.962,45.659
                                c2.011,6.175,7.768,10.354,14.262,10.354c6.494,0,12.251-4.179,14.262-10.354c2.404-7.377,9.453-26.595,22.962-45.66
                                c15.06-21.255,41.647-46.593,82.776-46.593c29.621,0,56.66,12.171,76.137,34.27C471.395,115.957,482,145.521,482,177.351
                                C482,254.358,413.255,312.939,309.193,401.614z"
                                          />
                                        </g>
                                      </g>
                                    </svg>
                                  </span>
                                </div>
                                {/* /ko */}
                                {/*ko if:$root.isWishlistPage == true  */}
                                {/* /ko */}
                              </div>
                            </div>
                            <div className="action-buttons">
                              <div
                                className="quickview"
                                data-bind="click:$root.quickViewClick"
                              >
                                <span className="text">Quick view</span>
                                <span className="icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 511.999 511.999"
                                  >
                                    <path
                                      d="M508.745,246.041c-4.574-6.257-113.557-153.206-252.748-153.206S7.818,239.784,3.249,246.035
                    c-4.332,5.936-4.332,13.987,0,19.923c4.569,6.257,113.557,153.206,252.748,153.206s248.174-146.95,252.748-153.201
                    C513.083,260.028,513.083,251.971,508.745,246.041z M255.997,385.406c-102.529,0-191.33-97.533-217.617-129.418
                    c26.253-31.913,114.868-129.395,217.617-129.395c102.524,0,191.319,97.516,217.617,129.418
                    C447.361,287.923,358.746,385.406,255.997,385.406z"
                                    />
                                    <path
                                      d="M255.997,154.725c-55.842,0-101.275,45.433-101.275,101.275s45.433,101.275,101.275,101.275
                    s101.275-45.433,101.275-101.275S311.839,154.725,255.997,154.725z M255.997,323.516c-37.23,0-67.516-30.287-67.516-67.516
                    s30.287-67.516,67.516-67.516s67.516,30.287,67.516,67.516S293.227,323.516,255.997,323.516z"
                                    ></path>
                                  </svg>
                                </span>
                              </div>
                              {/*ko if: has_variants == false && ((stock_control == true && stock > 0) || stock_control == false) */}
                              {/*/ko*/}
                              {/*ko if: has_variants && ((stock_control == true && stock > 0) || stock_control == false) */}
                              <div className="addtocart">
                                <a
                                  data-bind="attr:{href:url}"
                                  href="/product/uwell-caliburn-tenet-pod-system"
                                  tabIndex={0}
                                >
                                  <span className="text">View Details</span>
                                  <span className="icon">
                                    <i className="fa fa-long-arrow-right" />
                                  </span>
                                </a>
                              </div>
                              {/*/ko*/}
                              {/*ko if: (stock_control == true || stock_control == false ) && stock < 1 */}
                              {/*/ko*/}
                            </div>
                            <div className="content">
                              <div className="name">
                                <a
                                  data-bind="attr:{href:url},text:name"
                                  className="truncate"
                                  href="/product/uwell-caliburn-tenet-pod-system"
                                  tabIndex={0}
                                >
                                  UWELL CALIBURN TENET POD SYSTEM
                                </a>
                              </div>
                              <div className="price">
                                {/* ko if: call_for_price == true */}
                                {/*/ko*/}
                                {/* ko if: call_for_price == false || call_for_price == null */}
                                <s
                                  className="old-price"
                                  data-bind="visible: compare_at_price !=null,text: $root.currency + ' ' + compare_at_price"
                                  style={{ display: "none" }}
                                >
                                  BDT null
                                </s>
                                <span
                                  className="regular-price"
                                  data-bind='text: $root.currency + " " + price'
                                >
                                  BDT 3,000.00
                                </span>
                                {/*/ko*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="slick-slide"
                        data-slick-index={4}
                        aria-hidden="true"
                        style={{ width: 261 }}
                        tabIndex={-1}
                      >
                        <div>
                          <div
                            className="product-box"
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <div
                              data-bind="attr:{href:url}"
                              className="photo"
                              href="/product/voopoo-drag-4-177w-starter-kit"
                            >
                              {/* ko if: featured_image_urls != null && second_image_urls != null */}
                              <img
                                className="front-img"
                                width={1000}
                                height={1000}
                                data-bind="attr:{src: featured_image_urls.original},alt:name"
                                src="https://dtt1c9id3txwq.cloudfront.net/assets/images/001/496/483/original/Drag-4-Kit-New.jpg?1711368816"
                              />
                              <img
                                className="back-img"
                                width={1000}
                                height={1000}
                                data-bind="attr:{src: second_image_urls.original},alt:name"
                                src="https://dtt1c9id3txwq.cloudfront.net/assets/images/001/496/484/original/Drag-4-Kit.jpg?1711368821"
                              />
                              {/* /ko */}
                              {/* ko if: featured_image_urls != null && second_image_urls == null */}
                              {/* /ko */}
                              {/* ko if: featured_image_urls == null && second_image_urls != null */}
                              {/* /ko */}
                              {/* ko if: featured_image_urls == null && second_image_urls == null */}
                              {/* /ko */}
                              <a
                                className="photo-link"
                                data-bind="attr:{href:url}"
                                href="/product/voopoo-drag-4-177w-starter-kit"
                                tabIndex={-1}
                              />
                              <div className="tag">
                                <span
                                  className="new"
                                  data-bind="visible:is_new"
                                >
                                  new
                                </span>
                                <span
                                  className="sale"
                                  data-bind="visible: on_sale == true && sale_percentage != null,text: '-' + sale_percentage + '%'"
                                  style={{ display: "none" }}
                                >
                                  -null%
                                </span>
                              </div>
                              <div className="icon-group">
                                {/*ko if:$root.isWishlistPage == false  */}
                                <div
                                  className="wishlist"
                                  data-bind="attr:{id:'pid'+ id},click:$root.wishlistVM.addToWishList"
                                  id="pid391947"
                                >
                                  <span className="icon">
                                    <svg viewBox="0 0 512 512">
                                      <g>
                                        <g>
                                          <path
                                            d="M474.644,74.27C449.391,45.616,414.358,29.836,376,29.836c-53.948,0-88.103,32.22-107.255,59.25
                                c-4.969,7.014-9.196,14.047-12.745,20.665c-3.549-6.618-7.775-13.651-12.745-20.665c-19.152-27.03-53.307-59.25-107.255-59.25
                                c-38.358,0-73.391,15.781-98.645,44.435C13.267,101.605,0,138.213,0,177.351c0,42.603,16.633,82.228,52.345,124.7
                                c31.917,37.96,77.834,77.088,131.005,122.397c19.813,16.884,40.302,34.344,62.115,53.429l0.655,0.574
                                c2.828,2.476,6.354,3.713,9.88,3.713s7.052-1.238,9.88-3.713l0.655-0.574c21.813-19.085,42.302-36.544,62.118-53.431
                                c53.168-45.306,99.085-84.434,131.002-122.395C495.367,259.578,512,219.954,512,177.351
                                C512,138.213,498.733,101.605,474.644,74.27z M309.193,401.614c-17.08,14.554-34.658,29.533-53.193,45.646
                                c-18.534-16.111-36.113-31.091-53.196-45.648C98.745,312.939,30,254.358,30,177.351c0-31.83,10.605-61.394,29.862-83.245
                                C79.34,72.007,106.379,59.836,136,59.836c41.129,0,67.716,25.338,82.776,46.594c13.509,19.064,20.558,38.282,22.962,45.659
                                c2.011,6.175,7.768,10.354,14.262,10.354c6.494,0,12.251-4.179,14.262-10.354c2.404-7.377,9.453-26.595,22.962-45.66
                                c15.06-21.255,41.647-46.593,82.776-46.593c29.621,0,56.66,12.171,76.137,34.27C471.395,115.957,482,145.521,482,177.351
                                C482,254.358,413.255,312.939,309.193,401.614z"
                                          />
                                        </g>
                                      </g>
                                    </svg>
                                  </span>
                                </div>
                                {/* /ko */}
                                {/*ko if:$root.isWishlistPage == true  */}
                                {/* /ko */}
                              </div>
                            </div>
                            <div className="action-buttons">
                              <div
                                className="quickview"
                                data-bind="click:$root.quickViewClick"
                              >
                                <span className="text">Quick view</span>
                                <span className="icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 511.999 511.999"
                                  >
                                    <path
                                      d="M508.745,246.041c-4.574-6.257-113.557-153.206-252.748-153.206S7.818,239.784,3.249,246.035
                    c-4.332,5.936-4.332,13.987,0,19.923c4.569,6.257,113.557,153.206,252.748,153.206s248.174-146.95,252.748-153.201
                    C513.083,260.028,513.083,251.971,508.745,246.041z M255.997,385.406c-102.529,0-191.33-97.533-217.617-129.418
                    c26.253-31.913,114.868-129.395,217.617-129.395c102.524,0,191.319,97.516,217.617,129.418
                    C447.361,287.923,358.746,385.406,255.997,385.406z"
                                    />
                                    <path
                                      d="M255.997,154.725c-55.842,0-101.275,45.433-101.275,101.275s45.433,101.275,101.275,101.275
                    s101.275-45.433,101.275-101.275S311.839,154.725,255.997,154.725z M255.997,323.516c-37.23,0-67.516-30.287-67.516-67.516
                    s30.287-67.516,67.516-67.516s67.516,30.287,67.516,67.516S293.227,323.516,255.997,323.516z"
                                    ></path>
                                  </svg>
                                </span>
                              </div>
                              {/*ko if: has_variants == false && ((stock_control == true && stock > 0) || stock_control == false) */}
                              {/*/ko*/}
                              {/*ko if: has_variants && ((stock_control == true && stock > 0) || stock_control == false) */}
                              <div className="addtocart">
                                <a
                                  data-bind="attr:{href:url}"
                                  href="/product/voopoo-drag-4-177w-starter-kit"
                                  tabIndex={-1}
                                >
                                  <span className="text">View Details</span>
                                  <span className="icon">
                                    <i className="fa fa-long-arrow-right" />
                                  </span>
                                </a>
                              </div>
                              {/*/ko*/}
                              {/*ko if: (stock_control == true || stock_control == false ) && stock < 1 */}
                              {/*/ko*/}
                            </div>
                            <div className="content">
                              <div className="name">
                                <a
                                  data-bind="attr:{href:url},text:name"
                                  className="truncate"
                                  href="/product/voopoo-drag-4-177w-starter-kit"
                                  tabIndex={-1}
                                >
                                  VOOPOO DRAG 4 177W STARTER KIT
                                </a>
                              </div>
                              <div className="price">
                                {/* ko if: call_for_price == true */}
                                {/*/ko*/}
                                {/* ko if: call_for_price == false || call_for_price == null */}
                                <s
                                  className="old-price"
                                  data-bind="visible: compare_at_price !=null,text: $root.currency + ' ' + compare_at_price"
                                  style={{ display: "none" }}
                                >
                                  BDT null
                                </s>
                                <span
                                  className="regular-price"
                                  data-bind='text: $root.currency + " " + price'
                                >
                                  BDT 7,000.00
                                </span>
                                {/*/ko*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="slick-slide"
                        data-slick-index={5}
                        aria-hidden="true"
                        style={{ width: 261 }}
                        tabIndex={-1}
                      >
                        <div>
                          <div
                            className="product-box"
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <div
                              data-bind="attr:{href:url}"
                              className="photo"
                              href="/product/uwell-caliburn-g2-pod-system"
                            >
                              {/* ko if: featured_image_urls != null && second_image_urls != null */}
                              {/* /ko */}
                              {/* ko if: featured_image_urls != null && second_image_urls == null */}
                              <img
                                className="front-img"
                                width={1000}
                                height={1000}
                                data-bind="attr:{src: featured_image_urls.original},alt:name"
                                src="https://dtt1c9id3txwq.cloudfront.net/assets/images/001/496/461/original/Caliburn-G2-Pod.jpg?1711367768"
                              />
                              {/* /ko */}
                              {/* ko if: featured_image_urls == null && second_image_urls != null */}
                              {/* /ko */}
                              {/* ko if: featured_image_urls == null && second_image_urls == null */}
                              {/* /ko */}
                              <a
                                className="photo-link"
                                data-bind="attr:{href:url}"
                                href="/product/uwell-caliburn-g2-pod-system"
                                tabIndex={-1}
                              />
                              <div className="tag">
                                <span
                                  className="new"
                                  data-bind="visible:is_new"
                                  style={{ display: "none" }}
                                >
                                  new
                                </span>
                                <span
                                  className="sale"
                                  data-bind="visible: on_sale == true && sale_percentage != null,text: '-' + sale_percentage + '%'"
                                  style={{ display: "none" }}
                                >
                                  -null%
                                </span>
                              </div>
                              <div className="icon-group">
                                {/*ko if:$root.isWishlistPage == false  */}
                                <div
                                  className="wishlist"
                                  data-bind="attr:{id:'pid'+ id},click:$root.wishlistVM.addToWishList"
                                  id="pid319046"
                                >
                                  <span className="icon">
                                    <svg viewBox="0 0 512 512">
                                      <g>
                                        <g>
                                          <path
                                            d="M474.644,74.27C449.391,45.616,414.358,29.836,376,29.836c-53.948,0-88.103,32.22-107.255,59.25
                                c-4.969,7.014-9.196,14.047-12.745,20.665c-3.549-6.618-7.775-13.651-12.745-20.665c-19.152-27.03-53.307-59.25-107.255-59.25
                                c-38.358,0-73.391,15.781-98.645,44.435C13.267,101.605,0,138.213,0,177.351c0,42.603,16.633,82.228,52.345,124.7
                                c31.917,37.96,77.834,77.088,131.005,122.397c19.813,16.884,40.302,34.344,62.115,53.429l0.655,0.574
                                c2.828,2.476,6.354,3.713,9.88,3.713s7.052-1.238,9.88-3.713l0.655-0.574c21.813-19.085,42.302-36.544,62.118-53.431
                                c53.168-45.306,99.085-84.434,131.002-122.395C495.367,259.578,512,219.954,512,177.351
                                C512,138.213,498.733,101.605,474.644,74.27z M309.193,401.614c-17.08,14.554-34.658,29.533-53.193,45.646
                                c-18.534-16.111-36.113-31.091-53.196-45.648C98.745,312.939,30,254.358,30,177.351c0-31.83,10.605-61.394,29.862-83.245
                                C79.34,72.007,106.379,59.836,136,59.836c41.129,0,67.716,25.338,82.776,46.594c13.509,19.064,20.558,38.282,22.962,45.659
                                c2.011,6.175,7.768,10.354,14.262,10.354c6.494,0,12.251-4.179,14.262-10.354c2.404-7.377,9.453-26.595,22.962-45.66
                                c15.06-21.255,41.647-46.593,82.776-46.593c29.621,0,56.66,12.171,76.137,34.27C471.395,115.957,482,145.521,482,177.351
                                C482,254.358,413.255,312.939,309.193,401.614z"
                                          />
                                        </g>
                                      </g>
                                    </svg>
                                  </span>
                                </div>
                                {/* /ko */}
                                {/*ko if:$root.isWishlistPage == true  */}
                                {/* /ko */}
                              </div>
                            </div>
                            <div className="action-buttons">
                              <div
                                className="quickview"
                                data-bind="click:$root.quickViewClick"
                              >
                                <span className="text">Quick view</span>
                                <span className="icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 511.999 511.999"
                                  >
                                    <path
                                      d="M508.745,246.041c-4.574-6.257-113.557-153.206-252.748-153.206S7.818,239.784,3.249,246.035
                    c-4.332,5.936-4.332,13.987,0,19.923c4.569,6.257,113.557,153.206,252.748,153.206s248.174-146.95,252.748-153.201
                    C513.083,260.028,513.083,251.971,508.745,246.041z M255.997,385.406c-102.529,0-191.33-97.533-217.617-129.418
                    c26.253-31.913,114.868-129.395,217.617-129.395c102.524,0,191.319,97.516,217.617,129.418
                    C447.361,287.923,358.746,385.406,255.997,385.406z"
                                    />
                                    <path
                                      d="M255.997,154.725c-55.842,0-101.275,45.433-101.275,101.275s45.433,101.275,101.275,101.275
                    s101.275-45.433,101.275-101.275S311.839,154.725,255.997,154.725z M255.997,323.516c-37.23,0-67.516-30.287-67.516-67.516
                    s30.287-67.516,67.516-67.516s67.516,30.287,67.516,67.516S293.227,323.516,255.997,323.516z"
                                    ></path>
                                  </svg>
                                </span>
                              </div>
                              {/*ko if: has_variants == false && ((stock_control == true && stock > 0) || stock_control == false) */}
                              {/*/ko*/}
                              {/*ko if: has_variants && ((stock_control == true && stock > 0) || stock_control == false) */}
                              {/*/ko*/}
                              {/*ko if: (stock_control == true || stock_control == false ) && stock < 1 */}
                              <div
                                className="addtocart"
                                data-bind="click:$root.cartVM.addoneToCart"
                              >
                                <span className="text">Out of stock</span>
                                <span className="icon">
                                  <i className="fa fa-ban" />
                                </span>
                              </div>
                              {/*/ko*/}
                            </div>
                            <div className="content">
                              <div className="name">
                                <a
                                  data-bind="attr:{href:url},text:name"
                                  className="truncate"
                                  href="/product/uwell-caliburn-g2-pod-system"
                                  tabIndex={-1}
                                >
                                  UWELL CALIBURN G2 POD SYSTEM
                                </a>
                              </div>
                              <div className="price">
                                {/* ko if: call_for_price == true */}
                                <a
                                  href="/contact_us"
                                  className="hidden-xs"
                                  tabIndex={-1}
                                >
                                  Call for Price
                                </a>
                                <a
                                  href="tel:01788888947, 01788888948"
                                  className="visible-xs"
                                  tabIndex={-1}
                                >
                                  Call for Price
                                </a>
                                {/*/ko*/}
                                {/* ko if: call_for_price == false || call_for_price == null */}
                                {/*/ko*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="slick-slide"
                        data-slick-index={6}
                        aria-hidden="true"
                        style={{ width: 261 }}
                        tabIndex={-1}
                      >
                        <div>
                          <div
                            className="product-box"
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <div
                              data-bind="attr:{href:url}"
                              className="photo"
                              href="/product/yihi-sxmini-sl-class-v2-100w-box-mod-sx730"
                            >
                              {/* ko if: featured_image_urls != null && second_image_urls != null */}
                              {/* /ko */}
                              {/* ko if: featured_image_urls != null && second_image_urls == null */}
                              <img
                                className="front-img"
                                width={1000}
                                height={1000}
                                data-bind="attr:{src: featured_image_urls.original},alt:name"
                                src="https://dtt1c9id3txwq.cloudfront.net/assets/images/001/496/520/original/SL-CLass-V2.jpg?1711372294"
                              />
                              {/* /ko */}
                              {/* ko if: featured_image_urls == null && second_image_urls != null */}
                              {/* /ko */}
                              {/* ko if: featured_image_urls == null && second_image_urls == null */}
                              {/* /ko */}
                              <a
                                className="photo-link"
                                data-bind="attr:{href:url}"
                                href="/product/yihi-sxmini-sl-class-v2-100w-box-mod-sx730"
                                tabIndex={-1}
                              />
                              <div className="tag">
                                <span
                                  className="new"
                                  data-bind="visible:is_new"
                                >
                                  new
                                </span>
                                <span
                                  className="sale"
                                  data-bind="visible: on_sale == true && sale_percentage != null,text: '-' + sale_percentage + '%'"
                                  style={{ display: "none" }}
                                >
                                  -null%
                                </span>
                              </div>
                              <div className="icon-group">
                                {/*ko if:$root.isWishlistPage == false  */}
                                <div
                                  className="wishlist"
                                  data-bind="attr:{id:'pid'+ id},click:$root.wishlistVM.addToWishList"
                                  id="pid412121"
                                >
                                  <span className="icon">
                                    <svg viewBox="0 0 512 512">
                                      <g>
                                        <g>
                                          <path
                                            d="M474.644,74.27C449.391,45.616,414.358,29.836,376,29.836c-53.948,0-88.103,32.22-107.255,59.25
                                c-4.969,7.014-9.196,14.047-12.745,20.665c-3.549-6.618-7.775-13.651-12.745-20.665c-19.152-27.03-53.307-59.25-107.255-59.25
                                c-38.358,0-73.391,15.781-98.645,44.435C13.267,101.605,0,138.213,0,177.351c0,42.603,16.633,82.228,52.345,124.7
                                c31.917,37.96,77.834,77.088,131.005,122.397c19.813,16.884,40.302,34.344,62.115,53.429l0.655,0.574
                                c2.828,2.476,6.354,3.713,9.88,3.713s7.052-1.238,9.88-3.713l0.655-0.574c21.813-19.085,42.302-36.544,62.118-53.431
                                c53.168-45.306,99.085-84.434,131.002-122.395C495.367,259.578,512,219.954,512,177.351
                                C512,138.213,498.733,101.605,474.644,74.27z M309.193,401.614c-17.08,14.554-34.658,29.533-53.193,45.646
                                c-18.534-16.111-36.113-31.091-53.196-45.648C98.745,312.939,30,254.358,30,177.351c0-31.83,10.605-61.394,29.862-83.245
                                C79.34,72.007,106.379,59.836,136,59.836c41.129,0,67.716,25.338,82.776,46.594c13.509,19.064,20.558,38.282,22.962,45.659
                                c2.011,6.175,7.768,10.354,14.262,10.354c6.494,0,12.251-4.179,14.262-10.354c2.404-7.377,9.453-26.595,22.962-45.66
                                c15.06-21.255,41.647-46.593,82.776-46.593c29.621,0,56.66,12.171,76.137,34.27C471.395,115.957,482,145.521,482,177.351
                                C482,254.358,413.255,312.939,309.193,401.614z"
                                          />
                                        </g>
                                      </g>
                                    </svg>
                                  </span>
                                </div>
                                {/* /ko */}
                                {/*ko if:$root.isWishlistPage == true  */}
                                {/* /ko */}
                              </div>
                            </div>
                            <div className="action-buttons">
                              <div
                                className="quickview"
                                data-bind="click:$root.quickViewClick"
                              >
                                <span className="text">Quick view</span>
                                <span className="icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 511.999 511.999"
                                  >
                                    <path
                                      d="M508.745,246.041c-4.574-6.257-113.557-153.206-252.748-153.206S7.818,239.784,3.249,246.035
                    c-4.332,5.936-4.332,13.987,0,19.923c4.569,6.257,113.557,153.206,252.748,153.206s248.174-146.95,252.748-153.201
                    C513.083,260.028,513.083,251.971,508.745,246.041z M255.997,385.406c-102.529,0-191.33-97.533-217.617-129.418
                    c26.253-31.913,114.868-129.395,217.617-129.395c102.524,0,191.319,97.516,217.617,129.418
                    C447.361,287.923,358.746,385.406,255.997,385.406z"
                                    />
                                    <path
                                      d="M255.997,154.725c-55.842,0-101.275,45.433-101.275,101.275s45.433,101.275,101.275,101.275
                    s101.275-45.433,101.275-101.275S311.839,154.725,255.997,154.725z M255.997,323.516c-37.23,0-67.516-30.287-67.516-67.516
                    s30.287-67.516,67.516-67.516s67.516,30.287,67.516,67.516S293.227,323.516,255.997,323.516z"
                                    ></path>
                                  </svg>
                                </span>
                              </div>
                              {/*ko if: has_variants == false && ((stock_control == true && stock > 0) || stock_control == false) */}
                              {/*/ko*/}
                              {/*ko if: has_variants && ((stock_control == true && stock > 0) || stock_control == false) */}
                              {/*/ko*/}
                              {/*ko if: (stock_control == true || stock_control == false ) && stock < 1 */}
                              <div
                                className="addtocart"
                                data-bind="click:$root.cartVM.addoneToCart"
                              >
                                <span className="text">Out of stock</span>
                                <span className="icon">
                                  <i className="fa fa-ban" />
                                </span>
                              </div>
                              {/*/ko*/}
                            </div>
                            <div className="content">
                              <div className="name">
                                <a
                                  data-bind="attr:{href:url},text:name"
                                  className="truncate"
                                  href="/product/yihi-sxmini-sl-class-v2-100w-box-mod-sx730"
                                  tabIndex={-1}
                                >
                                  YIHI SXMINI SL CLASS V2 100W BOX MOD
                                </a>
                              </div>
                              <div className="price">
                                {/* ko if: call_for_price == true */}
                                {/*/ko*/}
                                {/* ko if: call_for_price == false || call_for_price == null */}
                                <s
                                  className="old-price"
                                  data-bind="visible: compare_at_price !=null,text: $root.currency + ' ' + compare_at_price"
                                  style={{ display: "none" }}
                                >
                                  BDT null
                                </s>
                                <span
                                  className="regular-price"
                                  data-bind='text: $root.currency + " " + price'
                                >
                                  BDT 9,000.00
                                </span>
                                {/*/ko*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="slick-slide"
                        data-slick-index={7}
                        aria-hidden="true"
                        style={{ width: 261 }}
                        tabIndex={-1}
                      >
                        <div>
                          <div
                            className="product-box"
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <div
                              data-bind="attr:{href:url}"
                              className="photo"
                              href="/product/yihi-sxmini-g-class-v2-200w-box-mod-sx750"
                            >
                              {/* ko if: featured_image_urls != null && second_image_urls != null */}
                              {/* /ko */}
                              {/* ko if: featured_image_urls != null && second_image_urls == null */}
                              <img
                                className="front-img"
                                width={1000}
                                height={1000}
                                data-bind="attr:{src: featured_image_urls.original},alt:name"
                                src="https://dtt1c9id3txwq.cloudfront.net/assets/images/001/496/507/original/G-Class-V2.jpg?1711371841"
                              />
                              {/* /ko */}
                              {/* ko if: featured_image_urls == null && second_image_urls != null */}
                              {/* /ko */}
                              {/* ko if: featured_image_urls == null && second_image_urls == null */}
                              {/* /ko */}
                              <a
                                className="photo-link"
                                data-bind="attr:{href:url}"
                                href="/product/yihi-sxmini-g-class-v2-200w-box-mod-sx750"
                                tabIndex={-1}
                              />
                              <div className="tag">
                                <span
                                  className="new"
                                  data-bind="visible:is_new"
                                >
                                  new
                                </span>
                                <span
                                  className="sale"
                                  data-bind="visible: on_sale == true && sale_percentage != null,text: '-' + sale_percentage + '%'"
                                  style={{ display: "none" }}
                                >
                                  -null%
                                </span>
                              </div>
                              <div className="icon-group">
                                {/*ko if:$root.isWishlistPage == false  */}
                                <div
                                  className="wishlist"
                                  data-bind="attr:{id:'pid'+ id},click:$root.wishlistVM.addToWishList"
                                  id="pid412122"
                                >
                                  <span className="icon">
                                    <svg viewBox="0 0 512 512">
                                      <g>
                                        <g>
                                          <path
                                            d="M474.644,74.27C449.391,45.616,414.358,29.836,376,29.836c-53.948,0-88.103,32.22-107.255,59.25
                                c-4.969,7.014-9.196,14.047-12.745,20.665c-3.549-6.618-7.775-13.651-12.745-20.665c-19.152-27.03-53.307-59.25-107.255-59.25
                                c-38.358,0-73.391,15.781-98.645,44.435C13.267,101.605,0,138.213,0,177.351c0,42.603,16.633,82.228,52.345,124.7
                                c31.917,37.96,77.834,77.088,131.005,122.397c19.813,16.884,40.302,34.344,62.115,53.429l0.655,0.574
                                c2.828,2.476,6.354,3.713,9.88,3.713s7.052-1.238,9.88-3.713l0.655-0.574c21.813-19.085,42.302-36.544,62.118-53.431
                                c53.168-45.306,99.085-84.434,131.002-122.395C495.367,259.578,512,219.954,512,177.351
                                C512,138.213,498.733,101.605,474.644,74.27z M309.193,401.614c-17.08,14.554-34.658,29.533-53.193,45.646
                                c-18.534-16.111-36.113-31.091-53.196-45.648C98.745,312.939,30,254.358,30,177.351c0-31.83,10.605-61.394,29.862-83.245
                                C79.34,72.007,106.379,59.836,136,59.836c41.129,0,67.716,25.338,82.776,46.594c13.509,19.064,20.558,38.282,22.962,45.659
                                c2.011,6.175,7.768,10.354,14.262,10.354c6.494,0,12.251-4.179,14.262-10.354c2.404-7.377,9.453-26.595,22.962-45.66
                                c15.06-21.255,41.647-46.593,82.776-46.593c29.621,0,56.66,12.171,76.137,34.27C471.395,115.957,482,145.521,482,177.351
                                C482,254.358,413.255,312.939,309.193,401.614z"
                                          />
                                        </g>
                                      </g>
                                    </svg>
                                  </span>
                                </div>
                                {/* /ko */}
                                {/*ko if:$root.isWishlistPage == true  */}
                                {/* /ko */}
                              </div>
                            </div>
                            <div className="action-buttons">
                              <div
                                className="quickview"
                                data-bind="click:$root.quickViewClick"
                              >
                                <span className="text">Quick view</span>
                                <span className="icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 511.999 511.999"
                                  >
                                    <path
                                      d="M508.745,246.041c-4.574-6.257-113.557-153.206-252.748-153.206S7.818,239.784,3.249,246.035
                    c-4.332,5.936-4.332,13.987,0,19.923c4.569,6.257,113.557,153.206,252.748,153.206s248.174-146.95,252.748-153.201
                    C513.083,260.028,513.083,251.971,508.745,246.041z M255.997,385.406c-102.529,0-191.33-97.533-217.617-129.418
                    c26.253-31.913,114.868-129.395,217.617-129.395c102.524,0,191.319,97.516,217.617,129.418
                    C447.361,287.923,358.746,385.406,255.997,385.406z"
                                    />
                                    <path
                                      d="M255.997,154.725c-55.842,0-101.275,45.433-101.275,101.275s45.433,101.275,101.275,101.275
                    s101.275-45.433,101.275-101.275S311.839,154.725,255.997,154.725z M255.997,323.516c-37.23,0-67.516-30.287-67.516-67.516
                    s30.287-67.516,67.516-67.516s67.516,30.287,67.516,67.516S293.227,323.516,255.997,323.516z"
                                    ></path>
                                  </svg>
                                </span>
                              </div>
                              {/*ko if: has_variants == false && ((stock_control == true && stock > 0) || stock_control == false) */}
                              {/*/ko*/}
                              {/*ko if: has_variants && ((stock_control == true && stock > 0) || stock_control == false) */}
                              <div className="addtocart">
                                <a
                                  data-bind="attr:{href:url}"
                                  href="/product/yihi-sxmini-g-class-v2-200w-box-mod-sx750"
                                  tabIndex={-1}
                                >
                                  <span className="text">View Details</span>
                                  <span className="icon">
                                    <i className="fa fa-long-arrow-right" />
                                  </span>
                                </a>
                              </div>
                              {/*/ko*/}
                              {/*ko if: (stock_control == true || stock_control == false ) && stock < 1 */}
                              {/*/ko*/}
                            </div>
                            <div className="content">
                              <div className="name">
                                <a
                                  data-bind="attr:{href:url},text:name"
                                  className="truncate"
                                  href="/product/yihi-sxmini-g-class-v2-200w-box-mod-sx750"
                                  tabIndex={-1}
                                >
                                  YIHI SXMINI G CLASS V2 200W BOX MOD
                                </a>
                              </div>
                              <div className="price">
                                {/* ko if: call_for_price == true */}
                                {/*/ko*/}
                                {/* ko if: call_for_price == false || call_for_price == null */}
                                <s
                                  className="old-price"
                                  data-bind="visible: compare_at_price !=null,text: $root.currency + ' ' + compare_at_price"
                                  style={{ display: "none" }}
                                >
                                  BDT null
                                </s>
                                <span
                                  className="regular-price"
                                  data-bind='text: $root.currency + " " + price'
                                >
                                  BDT 16,500.00
                                </span>
                                {/*/ko*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="slick-slide"
                        data-slick-index={8}
                        aria-hidden="true"
                        style={{ width: 261 }}
                        tabIndex={-1}
                      >
                        <div>
                          <div
                            className="product-box"
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <div
                              data-bind="attr:{href:url}"
                              className="photo"
                              href="/product/bp-mods-tomahawk-sbs-60w-box-mod"
                            >
                              {/* ko if: featured_image_urls != null && second_image_urls != null */}
                              {/* /ko */}
                              {/* ko if: featured_image_urls != null && second_image_urls == null */}
                              <img
                                className="front-img"
                                width={1000}
                                height={1000}
                                data-bind="attr:{src: featured_image_urls.original},alt:name"
                                src="https://dtt1c9id3txwq.cloudfront.net/assets/images/001/496/531/original/Tomahawk-SBS-Mod.jpg?1711372462"
                              />
                              {/* /ko */}
                              {/* ko if: featured_image_urls == null && second_image_urls != null */}
                              {/* /ko */}
                              {/* ko if: featured_image_urls == null && second_image_urls == null */}
                              {/* /ko */}
                              <a
                                className="photo-link"
                                data-bind="attr:{href:url}"
                                href="/product/bp-mods-tomahawk-sbs-60w-box-mod"
                                tabIndex={-1}
                              />
                              <div className="tag">
                                <span
                                  className="new"
                                  data-bind="visible:is_new"
                                >
                                  new
                                </span>
                                <span
                                  className="sale"
                                  data-bind="visible: on_sale == true && sale_percentage != null,text: '-' + sale_percentage + '%'"
                                  style={{ display: "none" }}
                                >
                                  -null%
                                </span>
                              </div>
                              <div className="icon-group">
                                {/*ko if:$root.isWishlistPage == false  */}
                                <div
                                  className="wishlist"
                                  data-bind="attr:{id:'pid'+ id},click:$root.wishlistVM.addToWishList"
                                  id="pid412881"
                                >
                                  <span className="icon">
                                    <svg viewBox="0 0 512 512">
                                      <g>
                                        <g>
                                          <path
                                            d="M474.644,74.27C449.391,45.616,414.358,29.836,376,29.836c-53.948,0-88.103,32.22-107.255,59.25
                                c-4.969,7.014-9.196,14.047-12.745,20.665c-3.549-6.618-7.775-13.651-12.745-20.665c-19.152-27.03-53.307-59.25-107.255-59.25
                                c-38.358,0-73.391,15.781-98.645,44.435C13.267,101.605,0,138.213,0,177.351c0,42.603,16.633,82.228,52.345,124.7
                                c31.917,37.96,77.834,77.088,131.005,122.397c19.813,16.884,40.302,34.344,62.115,53.429l0.655,0.574
                                c2.828,2.476,6.354,3.713,9.88,3.713s7.052-1.238,9.88-3.713l0.655-0.574c21.813-19.085,42.302-36.544,62.118-53.431
                                c53.168-45.306,99.085-84.434,131.002-122.395C495.367,259.578,512,219.954,512,177.351
                                C512,138.213,498.733,101.605,474.644,74.27z M309.193,401.614c-17.08,14.554-34.658,29.533-53.193,45.646
                                c-18.534-16.111-36.113-31.091-53.196-45.648C98.745,312.939,30,254.358,30,177.351c0-31.83,10.605-61.394,29.862-83.245
                                C79.34,72.007,106.379,59.836,136,59.836c41.129,0,67.716,25.338,82.776,46.594c13.509,19.064,20.558,38.282,22.962,45.659
                                c2.011,6.175,7.768,10.354,14.262,10.354c6.494,0,12.251-4.179,14.262-10.354c2.404-7.377,9.453-26.595,22.962-45.66
                                c15.06-21.255,41.647-46.593,82.776-46.593c29.621,0,56.66,12.171,76.137,34.27C471.395,115.957,482,145.521,482,177.351
                                C482,254.358,413.255,312.939,309.193,401.614z"
                                          />
                                        </g>
                                      </g>
                                    </svg>
                                  </span>
                                </div>
                                {/* /ko */}
                                {/*ko if:$root.isWishlistPage == true  */}
                                {/* /ko */}
                              </div>
                            </div>
                            <div className="action-buttons">
                              <div
                                className="quickview"
                                data-bind="click:$root.quickViewClick"
                              >
                                <span className="text">Quick view</span>
                                <span className="icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 511.999 511.999"
                                  >
                                    <path
                                      d="M508.745,246.041c-4.574-6.257-113.557-153.206-252.748-153.206S7.818,239.784,3.249,246.035
                    c-4.332,5.936-4.332,13.987,0,19.923c4.569,6.257,113.557,153.206,252.748,153.206s248.174-146.95,252.748-153.201
                    C513.083,260.028,513.083,251.971,508.745,246.041z M255.997,385.406c-102.529,0-191.33-97.533-217.617-129.418
                    c26.253-31.913,114.868-129.395,217.617-129.395c102.524,0,191.319,97.516,217.617,129.418
                    C447.361,287.923,358.746,385.406,255.997,385.406z"
                                    />
                                    <path
                                      d="M255.997,154.725c-55.842,0-101.275,45.433-101.275,101.275s45.433,101.275,101.275,101.275
                    s101.275-45.433,101.275-101.275S311.839,154.725,255.997,154.725z M255.997,323.516c-37.23,0-67.516-30.287-67.516-67.516
                    s30.287-67.516,67.516-67.516s67.516,30.287,67.516,67.516S293.227,323.516,255.997,323.516z"
                                    ></path>
                                  </svg>
                                </span>
                              </div>
                              {/*ko if: has_variants == false && ((stock_control == true && stock > 0) || stock_control == false) */}
                              {/*/ko*/}
                              {/*ko if: has_variants && ((stock_control == true && stock > 0) || stock_control == false) */}
                              <div className="addtocart">
                                <a
                                  data-bind="attr:{href:url}"
                                  href="/product/bp-mods-tomahawk-sbs-60w-box-mod"
                                  tabIndex={-1}
                                >
                                  <span className="text">View Details</span>
                                  <span className="icon">
                                    <i className="fa fa-long-arrow-right" />
                                  </span>
                                </a>
                              </div>
                              {/*/ko*/}
                              {/*ko if: (stock_control == true || stock_control == false ) && stock < 1 */}
                              {/*/ko*/}
                            </div>
                            <div className="content">
                              <div className="name">
                                <a
                                  data-bind="attr:{href:url},text:name"
                                  className="truncate"
                                  href="/product/bp-mods-tomahawk-sbs-60w-box-mod"
                                  tabIndex={-1}
                                >
                                  BP MODS TOMAHAWK SBS 60W BOX MOD
                                </a>
                              </div>
                              <div className="price">
                                {/* ko if: call_for_price == true */}
                                {/*/ko*/}
                                {/* ko if: call_for_price == false || call_for_price == null */}
                                <s
                                  className="old-price"
                                  data-bind="visible: compare_at_price !=null,text: $root.currency + ' ' + compare_at_price"
                                  style={{ display: "none" }}
                                >
                                  BDT null
                                </s>
                                <span
                                  className="regular-price"
                                  data-bind='text: $root.currency + " " + price'
                                >
                                  BDT 6,500.00
                                </span>
                                {/*/ko*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="slick-slide"
                        data-slick-index={9}
                        aria-hidden="true"
                        style={{ width: 261 }}
                        tabIndex={-1}
                      >
                        <div>
                          <div
                            className="product-box"
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <div
                              data-bind="attr:{href:url}"
                              className="photo"
                              href="/product/bp-mods-lightsaber-pod-mod-kit-model-x"
                            >
                              {/* ko if: featured_image_urls != null && second_image_urls != null */}
                              {/* /ko */}
                              {/* ko if: featured_image_urls != null && second_image_urls == null */}
                              <img
                                className="front-img"
                                width={1000}
                                height={1000}
                                data-bind="attr:{src: featured_image_urls.original},alt:name"
                                src="https://dtt1c9id3txwq.cloudfront.net/assets/images/001/496/496/original/Lightsaber-X.jpg?1711371662"
                              />
                              {/* /ko */}
                              {/* ko if: featured_image_urls == null && second_image_urls != null */}
                              {/* /ko */}
                              {/* ko if: featured_image_urls == null && second_image_urls == null */}
                              {/* /ko */}
                              <a
                                className="photo-link"
                                data-bind="attr:{href:url}"
                                href="/product/bp-mods-lightsaber-pod-mod-kit-model-x"
                                tabIndex={-1}
                              />
                              <div className="tag">
                                <span
                                  className="new"
                                  data-bind="visible:is_new"
                                >
                                  new
                                </span>
                                <span
                                  className="sale"
                                  data-bind="visible: on_sale == true && sale_percentage != null,text: '-' + sale_percentage + '%'"
                                  style={{ display: "none" }}
                                >
                                  -null%
                                </span>
                              </div>
                              <div className="icon-group">
                                {/*ko if:$root.isWishlistPage == false  */}
                                <div
                                  className="wishlist"
                                  data-bind="attr:{id:'pid'+ id},click:$root.wishlistVM.addToWishList"
                                  id="pid412884"
                                >
                                  <span className="icon">
                                    <svg viewBox="0 0 512 512">
                                      <g>
                                        <g>
                                          <path
                                            d="M474.644,74.27C449.391,45.616,414.358,29.836,376,29.836c-53.948,0-88.103,32.22-107.255,59.25
                                c-4.969,7.014-9.196,14.047-12.745,20.665c-3.549-6.618-7.775-13.651-12.745-20.665c-19.152-27.03-53.307-59.25-107.255-59.25
                                c-38.358,0-73.391,15.781-98.645,44.435C13.267,101.605,0,138.213,0,177.351c0,42.603,16.633,82.228,52.345,124.7
                                c31.917,37.96,77.834,77.088,131.005,122.397c19.813,16.884,40.302,34.344,62.115,53.429l0.655,0.574
                                c2.828,2.476,6.354,3.713,9.88,3.713s7.052-1.238,9.88-3.713l0.655-0.574c21.813-19.085,42.302-36.544,62.118-53.431
                                c53.168-45.306,99.085-84.434,131.002-122.395C495.367,259.578,512,219.954,512,177.351
                                C512,138.213,498.733,101.605,474.644,74.27z M309.193,401.614c-17.08,14.554-34.658,29.533-53.193,45.646
                                c-18.534-16.111-36.113-31.091-53.196-45.648C98.745,312.939,30,254.358,30,177.351c0-31.83,10.605-61.394,29.862-83.245
                                C79.34,72.007,106.379,59.836,136,59.836c41.129,0,67.716,25.338,82.776,46.594c13.509,19.064,20.558,38.282,22.962,45.659
                                c2.011,6.175,7.768,10.354,14.262,10.354c6.494,0,12.251-4.179,14.262-10.354c2.404-7.377,9.453-26.595,22.962-45.66
                                c15.06-21.255,41.647-46.593,82.776-46.593c29.621,0,56.66,12.171,76.137,34.27C471.395,115.957,482,145.521,482,177.351
                                C482,254.358,413.255,312.939,309.193,401.614z"
                                          />
                                        </g>
                                      </g>
                                    </svg>
                                  </span>
                                </div>
                                {/* /ko */}
                                {/*ko if:$root.isWishlistPage == true  */}
                                {/* /ko */}
                              </div>
                            </div>
                            <div className="action-buttons">
                              <div
                                className="quickview"
                                data-bind="click:$root.quickViewClick"
                              >
                                <span className="text">Quick view</span>
                                <span className="icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 511.999 511.999"
                                  >
                                    <path
                                      d="M508.745,246.041c-4.574-6.257-113.557-153.206-252.748-153.206S7.818,239.784,3.249,246.035
                    c-4.332,5.936-4.332,13.987,0,19.923c4.569,6.257,113.557,153.206,252.748,153.206s248.174-146.95,252.748-153.201
                    C513.083,260.028,513.083,251.971,508.745,246.041z M255.997,385.406c-102.529,0-191.33-97.533-217.617-129.418
                    c26.253-31.913,114.868-129.395,217.617-129.395c102.524,0,191.319,97.516,217.617,129.418
                    C447.361,287.923,358.746,385.406,255.997,385.406z"
                                    />
                                    <path
                                      d="M255.997,154.725c-55.842,0-101.275,45.433-101.275,101.275s45.433,101.275,101.275,101.275
                    s101.275-45.433,101.275-101.275S311.839,154.725,255.997,154.725z M255.997,323.516c-37.23,0-67.516-30.287-67.516-67.516
                    s30.287-67.516,67.516-67.516s67.516,30.287,67.516,67.516S293.227,323.516,255.997,323.516z"
                                    ></path>
                                  </svg>
                                </span>
                              </div>
                              {/*ko if: has_variants == false && ((stock_control == true && stock > 0) || stock_control == false) */}
                              {/*/ko*/}
                              {/*ko if: has_variants && ((stock_control == true && stock > 0) || stock_control == false) */}
                              <div className="addtocart">
                                <a
                                  data-bind="attr:{href:url}"
                                  href="/product/bp-mods-lightsaber-pod-mod-kit-model-x"
                                  tabIndex={-1}
                                >
                                  <span className="text">View Details</span>
                                  <span className="icon">
                                    <i className="fa fa-long-arrow-right" />
                                  </span>
                                </a>
                              </div>
                              {/*/ko*/}
                              {/*ko if: (stock_control == true || stock_control == false ) && stock < 1 */}
                              {/*/ko*/}
                            </div>
                            <div className="content">
                              <div className="name">
                                <a
                                  data-bind="attr:{href:url},text:name"
                                  className="truncate"
                                  href="/product/bp-mods-lightsaber-pod-mod-kit-model-x"
                                  tabIndex={-1}
                                >
                                  BP MODS LIGHTSABER POD MOD KIT - MODEL.X
                                </a>
                              </div>
                              <div className="price">
                                {/* ko if: call_for_price == true */}
                                {/*/ko*/}
                                {/* ko if: call_for_price == false || call_for_price == null */}
                                <s
                                  className="old-price"
                                  data-bind="visible: compare_at_price !=null,text: $root.currency + ' ' + compare_at_price"
                                  style={{ display: "none" }}
                                >
                                  BDT null
                                </s>
                                <span
                                  className="regular-price"
                                  data-bind='text: $root.currency + " " + price'
                                >
                                  BDT 6,500.00
                                </span>
                                {/*/ko*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="slick-next slick-arrow"
                    aria-label="Next"
                    type="button"
                    style={{}}
                    aria-disabled="false"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="pd-fixed-footer">
            <div className="fixed-content" data-bind="with: product">
              <div
                className="product-image hidden-xs"
                data-bind="with: $root.parentProduct"
              >
                {/* ko if: featured_image_urls != null */}
                <img
                  className="img-responsive"
                  data-bind="attr:{src: featured_image_urls.original},alt:name"
                  src="https://dtt1c9id3txwq.cloudfront.net/assets/images/001/496/447/original/Caliburn-GZ2.jpg?1711367230"
                />
                {/* /ko */}
              </div>
              <div className="product-info hidden-xs">
                <div className="name" data-bind="text: name">
                  UWELL CALIBURN GZ2 POD SYSTEM
                </div>
                {/* ko if: call_for_price == false || call_for_price == null */}
                <div className="price">
                  <span className="current-price" data-bind="text: price">
                    BDT 3,200.00
                  </span>
                  <s
                    className="old-price"
                    data-bind="visible: compare_at_price != null && compare_at_price != '',text: compare_at_price"
                    style={{ display: "none" }}
                  />
                </div>
                {/*/ko*/}
                {/* ko if: call_for_price == true */}
                {/*/ko*/}
              </div>
              <div className="quantity-box vt-middle-align">
                <button
                  type="button"
                  className="minus"
                  data-bind="click:$root.cartVM.decreaseQuantity"
                >
                  <svg
                    width={15}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={1}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <title>Minus</title>
                    <line x1={5} y1={12} x2={19} y2={12} />
                  </svg>
                </button>
                <input
                  type="text"
                  className="form-control quantity-input text-center"
                  id="pdfxqnt"
                  data-bind="value: $root.cartVM.quantity"
                />
                <button
                  type="button"
                  className="plus"
                  data-bind="disable:$root.cartVM.quantity() >= stock,click:$root.cartVM.increaseQuantity"
                >
                  <svg
                    width={15}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={1}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <title>Plus</title>
                    <line x1={12} y1={5} x2={12} y2={19} />
                    <line x1={5} y1={12} x2={19} y2={12} />
                  </svg>
                </button>
              </div>
              <div className="addtocart">
                <button
                  className="tbtn btn-solid"
                  data-bind="enable: (stock_control == true && stock > 0) || stock_control == false,click:$root.cartVM.addoneToCart"
                >
                  add to cart
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default DeatilsPage;
