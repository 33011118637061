import React from "react";

import "./footerDesign.css";
const FooterDesign = () => {
  return (
    <div className="home-mini-collection-box">
      <div className="content-wrapper">
        <div className="home-mini-collection-box-content">
          <div className="collection-box">
            <div className="image">
              <img
                className="img-responsive"
                src="https://dtt1c9id3txwq.cloudfront.net/themes/17494/assets/images/Pi9000_4a1287f5eff46fe1683c3c2324f4da7dc33d946b.jpg?1700847439"
                alt="Elf Bar Pi9000"
              />
            </div>
            <div className="text">
              <h5
                className="text-uppercase wow  fadeInRight animated"
                style={{ visibility: "visible", animationName: "fadeInRight" }}
              >
                9000 PUFFS DISPOSABLES
              </h5>
              <h3
                className="text-capitalize wow  fadeInRight animated"
                data-wow-delay=".5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInRight",
                }}
              >
                Elf Bar Pi9000
              </h3>
              <a
                href="#"
                className="tbtn btn-solid wow  fadeInRight animated"
                data-wow-delay="1s"
                style={{
                  visibility: "visible",
                  animationDelay: "1s",
                  animationName: "fadeInRight",
                }}
              >
                shop now
              </a>
            </div>
          </div>
          <div className="collection-box">
            <div className="image">
              <img
                className="img-responsive"
                src="https://dtt1c9id3txwq.cloudfront.net/themes/17494/assets/images/Revivala-D1-Kit_edab10a74b1156e754943747a39d1f788ea9eb7d.jpg?1705490768"
                alt="Revivala D1"
              />
            </div>
            <div className="text">
              <h5
                className="text-uppercase wow  fadeInRight animated"
                style={{ visibility: "visible", animationName: "fadeInRight" }}
              >
                DISPOSABLE MEETS REUSABILITY
              </h5>
              <h3
                className="text-capitalize wow  fadeInDown animated"
                data-wow-delay=".5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInDown",
                }}
              >
                Revivala D1
              </h3>
              <a
                href="#"
                className="tbtn btn-solid wow  fadeInDown animated"
                data-wow-delay="1s"
                style={{
                  visibility: "visible",
                  animationDelay: "1s",
                  animationName: "fadeInDown",
                }}
              >
                shop now
              </a>
            </div>
          </div>
          <div className="collection-box">
            <div className="image">
              <img
                className="img-responsive"
                src="https://www.thevapeloungebd.com/img/krus.png"
                alt="KRUS Cream"
              />
            </div>
            <div className="text">
              <h5
                className="text-uppercase wow  fadeInLeft animated"
                style={{ visibility: "visible", animationName: "fadeInLeft" }}
              >
                8000 PUFFS DISPOSABLES
              </h5>
              <h3
                className="text-capitalize wow  fadeInLeft animated"
                data-wow-delay=".5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInLeft",
                }}
              >
                KRUS Cream
              </h3>
              <a
                href="https://www.thevapeloungebd.com/img/krus.png"
                className="tbtn btn-solid wow  fadeInLeft animated"
                data-wow-delay="1s"
                style={{
                  visibility: "visible",
                  animationDelay: "1s",
                  animationName: "fadeInLeft",
                }}
              >
                shop now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterDesign;
