import React from "react";

import Navbar from "../AppBar/appbar";
import TopCategories from "../TopCategories/TopCategories";
import TabbedProduct from "../TabProduct/tabproduct";
import StyleProduct from "../styleProduct/stproduct";
import FooterDesign from "../FootDesign/footerDesign";
import Footer from "../Footer/footer";
import BTfooter from "../BottomFooter/btfooter";
import Adview from "../Adview/adview";
import Adview2 from "../Adview2/adview2";
import Header from "../Header/header";
import useMediaQuery from "@mui/material/useMediaQuery";

const Homepage = () => {
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <>
      <Navbar />
      <Header />
      {/* <TopCategories /> */}
      <Adview />
      {matches ? (
        <TabbedProduct />
      ) : (
        <div style={{ marginBottom: 1000 }}>
          <TabbedProduct />
        </div>
      )}

      <Adview2 />
      <StyleProduct />
      <FooterDesign />
      <Footer />
      {/* <BTfooter /> */}
    </>
  );
};

export default Homepage;
